import React from "react";
import MyCard from "../../../components/common/MyCard";
import {
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { MdModeEdit } from "react-icons/md";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SentenceCase } from "../../../utils/stringUtils";

const GroupView = ({ groupData, onEditGroup }) => {
  return (
    <>
      {groupData && groupData.length > 0 ? (
        groupData.map((group) => (
          <MyCard
            key={group.id}
            width="full"
            title={
              <div className="flex justify-between items-center mb-2">
                <Typography variant="h6">{group.group_name}</Typography>
                <IconButton onClick={() => onEditGroup(group)}>
                  <MdModeEdit />
                </IconButton>
              </div>
            }
          >
            {/* Criteria Section */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1" className="font-bold">
                  Criteria
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">
                  <ul className="list-disc pl-4">
                    {group.enable_office_checkin && (
                      <li>Enable Office Check-In</li>
                    )}
                    {group.static_time && (
                      <li>Static Time: {group.static_time} Hours</li>
                    )}
                    {group.block_outside_premises && (
                      <li>Block Employee If Outside Premises</li>
                    )}
                    {group.enable_field_checkin && (
                      <li>Enable Field Check-In</li>
                    )}
                    {group.tracking_time && (
                      <li>Track Frequency: {group.tracking_time} Minutes</li>
                    )}
                    {group.field_static_time && (
                      <li>
                        Track Device Static: {group.field_static_time} Hours
                      </li>
                    )}
                    {group.enable_face_validation && (
                      <li>Enable Face Validation</li>
                    )}
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            {/* Shifts Section */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1" className="font-bold">
                  Shifts
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {group.shifts_details && group.shifts_details.length > 0 ? (
                  <div className="space-y-4">
                    {group.shifts_details.map((shift) => (
                      <div key={shift.id}>
                        <Typography variant="subtitle2" className="font-medium">
                          {shift.shift_name}
                        </Typography>
                        <Typography variant="body2" className="text-gray-600">
                          Start Time: {shift.start_time} | End Time:{" "}
                          {shift.end_time}
                        </Typography>
                        <Typography variant="body2" className="text-gray-600">
                          Buffer Time: {shift.grace_time} minutes
                        </Typography>
                        {shift.break_start && (
                          <Typography variant="body2" className="text-gray-600">
                            Break: {shift.break_start} - {shift.break_end} (
                            {shift.break_purpose})
                          </Typography>
                        )}
                        {shift.overtime && (
                          <Typography variant="body2" className="text-gray-600">
                            Overtime: Yes | Pay: {shift.overtime_pay} per hour
                          </Typography>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <Typography variant="body2">No shifts assigned.</Typography>
                )}
              </AccordionDetails>
            </Accordion>

            {/* Week-Offs Section */}
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1" className="font-bold">
                  Week-Offs
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {group.week_offs_details &&
                group.week_offs_details.length > 0 ? (
                  <div className="space-y-4">
                    {group.week_offs_details.map((weekOff) => (
                      <div key={weekOff.id}>
                        <Typography variant="subtitle2" className="font-medium">
                          {weekOff.weekoffName}
                        </Typography>
                        <Typography variant="body2" className="text-gray-600">
                          Selected Weekdays: {SentenceCase(weekOff.days)}
                        </Typography>
                        <Typography variant="body2" className="text-gray-600">
                          Selected Weeks: {weekOff.selectedWeeks.join(", ")}
                        </Typography>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Typography variant="body2">
                    No week-offs assigned.
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          </MyCard>
        ))
      ) : (
        <Typography variant="body1">No Group assigned.</Typography>
      )}
    </>
  );
};

export default GroupView;
