// src/components/LeaveList.jsx
import React, { useState } from "react";
import MyCard from "../../../components/common/MyCard";
import { Edit, Delete } from "@mui/icons-material";
// import moment from "moment";
import Fieldset from "../../../components/common/Fieldset";
import InfoIcon from "@mui/icons-material/Info";
import { Stack, IconButton, Typography } from "@mui/material";
import RestrictionStatus from "./RestrictionStatus";
import { AddLeave } from "./create";

const LeaveList = ({ leaveData }) => {
  const [selectedLeave, setSelectedLeave] = useState({});
  const [showEditmodal, setShowEditModal] = useState(false);
  return (
    <div className="grid grid-cols-1 md:grid-cols-1 gap-1">
      {leaveData?.map((leave, index) => (
        <MyCard
          key={index}
          // width={550}
          title={
            <div className="flex flex-row justify-between">
              <div className="flex items-center justify-center">
                <h3 className="text-lg font-semibold">
                  {leave.leave_name} ({leave.leave_code})
                </h3>
              </div>
              <div>
                <Typography variant="subtitle1">
                  <strong>Type:</strong> {leave.leave_type}
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle1">
                  <strong>Unit:</strong> {leave.unit}
                </Typography>
              </div>
              <div>
                <IconButton
                  aria-label="edit"
                  color="primary"
                  onClick={() => {
                    setShowEditModal(true);
                    setSelectedLeave(leave);
                  }}
                >
                  <Edit />
                </IconButton>
                {/* <IconButton aria-label="delete" color="primary">
                  <Delete />
                </IconButton> */}
              </div>
            </div>
          }
        >
          <div className="columns-3">
            <div>
              <Typography variant="subtitle1">
                <strong>Effective After</strong>{" "}
                <strong>{leave.entitlement_effective_after}</strong>{" "}
                <strong>{leave.entitlement_period}</strong> from Date of joining
              </Typography>
              <Typography variant="subtitle1">
                <strong>Credit </strong>{" "}
                <strong>{leave.accrual_credit_days} </strong> Days
                <strong> {leave.accrual_on_period}</strong>
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle1">
                <strong>Reset</strong> <strong>{leave.reset_period}</strong> On{" "}
                <strong>{leave.reset_on_day}</strong> st of{" "}
                <strong>{leave.reset_of_month}</strong>
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle1">
                <strong>Carry forward</strong>{" "}
                <strong>{leave.carry_forward_days}</strong>{" "}
                <strong>{leave.carry_forward_unit}</strong>
              </Typography>
              <Typography variant="subtitle1">
                <strong>Encash Unused Leaves : </strong> {leave.encashment_days}
                {leave.encashment_unit}
              </Typography>
            </div>
          </div>
          <div>
            <Fieldset
              title={
                <Stack direction="row" alignItems="center" gap={1}>
                  <InfoIcon />
                  Restrictions
                </Stack>
              }
              borderWidth={2}
              borderRadius={1}
              sx={{
                padding: 2,
                "& legend": {
                  borderRadius: "4px",
                },
              }}
            >
              <div className="columns-2">
                <div>
                  <RestrictionStatus
                    isAllowed={
                      leave.leave_restriction.allow_exceeding_entitlement
                    }
                    title="Allow leave request that exceed entitled leaves"
                  />
                  <RestrictionStatus
                    isAllowed={leave.leave_restriction.clubbing_not_allowed}
                    title="clubbing_not_allowed"
                  />
                  <RestrictionStatus
                    isAllowed={
                      leave.leave_restriction.consider_weekends_holidays
                    }
                    title="Consider weekends and holidays between the leave period as leave"
                  />
                </div>
                <div>
                  <RestrictionStatus
                    isAllowed={leave.leave_restriction.allow_past_dates}
                    title="Allow past dates to apply for leave"
                  />
                  <RestrictionStatus
                    isAllowed={leave.leave_restriction.allow_future_dates}
                    title="Allow Future dates to apply for leave"
                  />
                </div>
              </div>
            </Fieldset>
          </div>
        </MyCard>
      ))}
      {showEditmodal && (
        <AddLeave
          setShowModal={setShowEditModal}
          showModal={showEditmodal}
          editVals={selectedLeave}
        />
      )}
    </div>
  );
};

export default LeaveList;
