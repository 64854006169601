import { Box, Typography } from "@mui/material";
const Fieldset = ({
  title,
  color = "inherit",
  titleSize = "1rem",
  borderWidth = 1,
  borderRadius = 2,
  marginTop = 2,
  children,
  sx = {},
  ...props
}) => {
  return (
    <Box
      component="fieldset"
      sx={{
        borderColor: color,
        borderWidth: borderWidth,
        borderRadius: borderRadius,
        marginTop: marginTop,
        ...sx,
      }}
      {...props}
    >
      {title && (
        <Typography
          component="legend"
          sx={{
            color: color,
            fontSize: titleSize,
            marginLeft: "1em",
          }}
        >
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default Fieldset;
