import React, { useState } from "react";
import {
  Button,
  MenuItem,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Chip,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";

const LeaveApplicable = () => {
  const [criteriaList, setCriteriaList] = useState([]);
  const [exceptionList, setExceptionList] = useState([]);
  const [showEmployeeInput, setShowEmployeeInput] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const criteriaOptions = [
    { value: "gender", label: "Gender" },
    { value: "maritalStatus", label: "Marital Status" },
    { value: "department", label: "Department" },
    { value: "designation", label: "Designation" },
    { value: "location", label: "Location" },
    { value: "roles", label: "Roles" },
    { value: "employeeCategory", label: "Employee Category" },
  ];

  const secondaryOptions = {
    gender: [
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
      { value: "other", label: "Other" },
    ],
    maritalStatus: [
      { value: "married", label: "Married" },
      { value: "unmarried", label: "Unmarried" },
    ],
    employeeCategory: [
      { value: "contract", label: "Contract" },
      { value: "permanent", label: "Permanent" },
      { value: "temporary", label: "Temporary" },
      { value: "trainee", label: "Trainee" },
    ],
  };

  const employeeData = [
    "John Doe",
    "Jane Smith",
    "Alice Johnson",
    "Bob Brown",
    "Charlie Davis",
  ];

  const handleAddCriteria = () => {
    if (criteriaList.length < criteriaOptions.length) {
      setCriteriaList((prev) => [...prev, { type: "", value: [] }]);
    }
  };

  const handleAddException = () => {
    if (exceptionList.length < criteriaOptions.length) {
      setExceptionList((prev) => [...prev, { type: "", value: [] }]);
    }
  };

  const handleCriteriaChange = (listSetter) => (index, field, value) => {
    listSetter((prev) => {
      const updatedList = [...prev];
      updatedList[index][field] = value;
      return updatedList;
    });
  };

  const handleDeleteItem = (listSetter) => (index) => {
    listSetter((prev) => prev.filter((_, i) => i !== index));
  };

  const getAvailableOptions = (list) => (currentType) => {
    const selectedTypes = list.map((item) => item.type);
    return criteriaOptions.filter(
      (option) =>
        !selectedTypes.includes(option.value) || option.value === currentType
    );
  };

  const handleEmployeeSelection = (value) => {
    if (value.includes("Select All")) {
      setSelectedEmployees(employeeData);
    } else {
      setSelectedEmployees(value);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      {/* Applicability Section */}
      <div className="flex justify-between border-b-2 border-gray-300 pb-2 mb-3">
        <h2 className="text-lg font-semibold">Applicability</h2>
        <Button variant="contained" color="primary" onClick={handleAddCriteria}>
          Add Criteria
        </Button>
      </div>
      {criteriaList.map((criteria, index) => (
        <div key={index} className="flex items-center gap-4 mt-2">
          <FormControl variant="outlined" size="small" className="w-1/4">
            <InputLabel>Criteria</InputLabel>
            <Select
              value={criteria.type}
              onChange={(e) =>
                handleCriteriaChange(setCriteriaList)(
                  index,
                  "type",
                  e.target.value
                )
              }
              label="Criteria"
            >
              {getAvailableOptions(criteriaList)(criteria.type).map(
                (option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className="w-1/3"
            disabled={!criteria.type || !secondaryOptions[criteria.type]}
          >
            <InputLabel>Value</InputLabel>
            <Select
              value={criteria.value}
              onChange={(e) =>
                handleCriteriaChange(setCriteriaList)(
                  index,
                  "value",
                  e.target.value
                )
              }
              label="Value"
            >
              {(secondaryOptions[criteria.type] || []).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton
            color="error"
            onClick={() => handleDeleteItem(setCriteriaList)(index)}
          >
            <Delete />
          </IconButton>
        </div>
      ))}

      {/* Employees Section */}
      <div className="flex justify-between border-b-2 border-gray-300 pb-2 mb-3">
        <h2 className="text-lg font-semibold">Employees</h2>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowEmployeeInput((prev) => !prev)}
        >
          {showEmployeeInput ? "Delete Employees" : "Add Employees"}
        </Button>
      </div>
      {showEmployeeInput && (
        <Autocomplete
          multiple
          options={["Select All", ...employeeData]}
          getOptionLabel={(option) => option}
          value={selectedEmployees}
          onChange={(event, value) => handleEmployeeSelection(value)}
          renderTags={(value, getTagProps) =>
            value.map((option, i) => (
              <Chip
                label={option}
                {...getTagProps({ index: i })}
                onDelete={() => {
                  const newValue = [...value];
                  newValue.splice(i, 1);
                  setSelectedEmployees(newValue);
                }}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Employees"
              variant="outlined"
              size="small"
            />
          )}
          className="w-1/2"
        />
      )}

      {/* Exceptions Section */}
      <div className="flex justify-between border-b-2 border-gray-300 pb-2 mb-3">
        <h2 className="text-lg font-semibold">Exceptions</h2>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddException}
        >
          Add Exception
        </Button>
      </div>
      {exceptionList.map((exception, index) => (
        <div key={index} className="flex items-center gap-4 mt-2">
          <FormControl variant="outlined" size="small" className="w-1/4">
            <InputLabel>Criteria</InputLabel>
            <Select
              value={exception.type}
              onChange={(e) =>
                handleCriteriaChange(setExceptionList)(
                  index,
                  "type",
                  e.target.value
                )
              }
              label="Criteria"
            >
              {getAvailableOptions(exceptionList)(exception.type).map(
                (option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className="w-1/3"
            disabled={!exception.type || !secondaryOptions[exception.type]}
          >
            <InputLabel>Value</InputLabel>
            <Select
              value={exception.value}
              onChange={(e) =>
                handleCriteriaChange(setExceptionList)(
                  index,
                  "value",
                  e.target.value
                )
              }
              label="Value"
            >
              {(secondaryOptions[exception.type] || []).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton
            color="error"
            onClick={() => handleDeleteItem(setExceptionList)(index)}
          >
            <Delete />
          </IconButton>
        </div>
      ))}
    </div>
  );
};

export default LeaveApplicable;
