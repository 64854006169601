import { useState, useEffect } from "react";
import axiosInstance from "../axios/axiosInstance";

const useFetch = (url, params = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(url, { params });
        setData(response.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, params]);

  return { data, loading, error };
};

// Use it in your component like below
// const { data, loading, error } = useFetch('/endpoint'); // Replace with your endpoint
//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;

export default useFetch;
