import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Swal from "sweetalert2";
import axiosInstance from "../../axios/axiosInstance";
import { getErrorMessage } from "../../utils/getErrorMessage";

const ResetRadius = ({ isModalOpen, branchData }) => {
  const [radius, setRadius] = useState(0);

  useEffect(() => {
    if (branchData && branchData.fence_radius) {
      setRadius(branchData.fence_radius);
    }
  }, [branchData]);

  const handleResetRadius = async () => {
    try {
      const response = await axiosInstance.put(`/company/branch/update`, {
        branch_id: branchData.id,
        fence_radius: parseInt(radius),
        location_latitude: branchData.location_latitude,
        location_longitude: branchData.location_longitude,
      });

      if (response.data.status === 200 || response.data.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Radius updated successfully!",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then(() => isModalOpen(false));
      }
    } catch (err) {
      const dash = getErrorMessage(err.response.data.error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: dash,
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  };

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth={true}
      onClose={() => isModalOpen(false)}
    >
      <DialogTitle>Reset Radius</DialogTitle>
      <div>
        <DialogContent>
          <div className="flex flex-col gap-4">
            <TextField
              label="Fence Radius (meters)"
              variant="outlined"
              fullWidth
              value={radius}
              onChange={(e) => setRadius(e.target.value)}
              type="number"
            />
            <div className="flex justify-end gap-4">
              <Button variant="outlined" onClick={() => isModalOpen(false)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleResetRadius}
              >
                Reset Radius
              </Button>
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default ResetRadius;
