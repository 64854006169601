import { isObject } from "@mui/x-data-grid/internals";

export function getErrorMessage(errors) {
  let errorMessage = "";

  if (isObject(errors)) {
    // Iterate through the error object keys
    Object.keys(errors).forEach((field) => {
      const fieldErrors = errors[field];

      // If the field has errors, append each one to the error message
      if (Array.isArray(fieldErrors)) {
        fieldErrors.forEach((error, index) => {
          errorMessage += `${
            field.charAt(0).toUpperCase() + field.slice(1)
          }: ${error}`;

          // Add a line break between errors for the same field
          if (index < fieldErrors.length - 1) {
            errorMessage += "\n";
          }
        });
      }
    });
  } else {
    errorMessage = errors;
  }
  return errorMessage;
}
