import React, { useState, useMemo, useEffect } from "react";
import GeoFencing from "./geofencing/GeoFencing";
import DeptDesn from "./department_designation/DeptDesn";
import RoleRight from "./roles_rights/RoleRight";
import AttnConfi from "./attendance_configuration/AttnConfi";
import LeaveConfiguration from "./leave_configuration/LeaveConfiguration";
import { Button } from "@mui/material";
import Branding from "./branding/Branding";
import axiosInstance from "../../axios/axiosInstance";
import Swal from "sweetalert2";

const Configuration = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    geoFencing: {
      branch_id: "",
      fence_radius: "",
      location_latitude: "",
      location_longitude: "",
    },
  });

  const geoFenceURL = useMemo(() => "/company/geo-fence/list", []);
  const localData = JSON.parse(localStorage.getItem("KlkmateAdmin"));
  const branchId = localData ? localData.userData.company_branch.id : null;
  // console.log("branchId:", branchId);
  const isGeoFencingCompleted = () => {
    const { location_latitude, location_longitude, fence_radius } =
      formData.geoFencing;
    return (
      location_latitude !== "" &&
      location_latitude !== null &&
      location_longitude !== "" &&
      location_longitude !== null &&
      fence_radius !== "" &&
      fence_radius !== null
    );
  };

  useEffect(() => {
    const fetchGeoFencingData = async () => {
      try {
        const response = await axiosInstance.get(geoFenceURL, {
          params: { branch_id: branchId }, // Replace with dynamic branch_id if needed
        });

        // Access the nested data correctly
        const geoFenceData = response.data.data;

        if (geoFenceData) {
          setFormData((prev) => ({
            ...prev,
            geoFencing: {
              branch_id: geoFenceData.branch_id,
              fence_radius: geoFenceData.fence_radius,
              location_latitude: geoFenceData.location_latitude,
              location_longitude: geoFenceData.location_longitude,
            },
          }));
        } else {
          console.error("No geo-fencing data found.");
        }
      } catch (error) {
        console.error("Error fetching geo-fencing data:", error);
      }
    };

    fetchGeoFencingData();
  }, [branchId]);

  // Handler for navigating between steps
  const goToStep = (stepIndex) => {
    if (stepIndex > 0 && !isGeoFencingCompleted()) {
      Swal.fire({
        icon: "warning",
        title: "GeoFencing Incomplete",
        text: "Please complete the GeoFencing settings to proceed.",
      });
      return; // Prevent navigating to next step
    }

    setCurrentStep(stepIndex);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return <GeoFencing formData={formData} />;
      case 1:
        return <DeptDesn />;
      case 2:
        return <RoleRight />;
      case 3:
        return <AttnConfi />;
      case 4:
        return <LeaveConfiguration />;
      case 5:
        return <Branding />;
      default:
        return null;
    }
  };

  return (
    <div className="w-full p-6">
      <h1 className="text-2xl font-semibold mb-4">Configuration</h1>

      {/* Tab Navigation */}
      <div className="flex gap-x-5 border-b mb-4">
        {[
          "GeoFencing",
          "Department & Designation",
          "Roles & Responsibilities",
          "General Configuration",
          "Leave Configuration",
          "Branding",
        ].map((step, index) => (
          <button
            key={index}
            className={`px-4 py-2 ${
              index === currentStep
                ? "border-b-2 border-color1 font-semibold"
                : ""
            }`}
            onClick={() => goToStep(index)}
          >
            {step}
          </button>
        ))}
      </div>

      {/* Render Current Step */}
      <div className="mt-6">{renderStep()}</div>

      {/* Navigation buttons */}
      <div className="flex justify-between mt-6">
        <div className="flex justify-start">
          {currentStep > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => goToStep(currentStep - 1)}
            >
              Previous
            </Button>
          )}
        </div>

        <div className="flex justify-end w-full">
          {currentStep === 5 && (
            <Button
              onClick={() => setShowPopup(true)}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          )}
        </div>
        <div className="flex justify-end">
          {currentStep < 5 && (
            <Button
              onClick={() => {
                if (isGeoFencingCompleted() || currentStep > 0) {
                  goToStep(currentStep + 1);
                } else if (currentStep !== 0) {
                  goToStep(currentStep + 1);
                } else {
                  Swal.fire({
                    icon: "warning",
                    title: "GeoFencing Incomplete",
                    text: "Please complete the GeoFencing settings to proceed.",
                  });
                }
              }}
              variant="contained"
              color="primary"
              sx={{ whiteSpace: "nowrap" }}
            >
              Save & Continue
            </Button>
          )}
        </div>
      </div>

      {/* Success Popup */}
      {showPopup && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
          role="dialog"
          aria-modal="true"
        >
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full">
            <h2 className="text-lg font-semibold text-center">
              Configuration Successfully Completed
            </h2>
            <div className="mt-4 text-center">
              <Button
                onClick={() => setShowPopup(false)}
                variant="outlined"
                color="primary"
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Configuration;
