import * as React from "react";
import { Box, Stack } from "@mui/material";
import MyCard from "../../../../components/common/MyCard";
import Grid from "@mui/material/Grid2";
import { FaPlusCircle } from "react-icons/fa";
import {
  gender,
  maritalStatus,
  employmentType,
  applicableCriteria,
} from "../../../../utils/constants/dropdown";
import { Field } from "formik";
import useFetch from "../../../../hooks/useFetch";
import MultiSelectAutocomplete from "../../../../components/common/MultiSelectAutocomplete";

const Applicability = ({ values, setFieldValue }) => {
  const [selectedGenderIds, setSelectedGenderIds] = React.useState(
    values.leave_applicability?.applicable_gender || []
  );
  const [selectedMaritalIds, setSelectedMaritalIds] = React.useState(
    values.leave_applicability?.marital_status || []
  );
  const [selectedEmploymentIds, setSelectedEmploymentIds] = React.useState(
    values.leave_applicability?.applicable_employee_category || []
  );

  const selectedGenderOptions = gender.filter((option) =>
    selectedGenderIds.includes(option.id)
  );
  const selectedMaritalOptions = maritalStatus.filter((option) =>
    selectedMaritalIds.includes(option.id)
  );
  const selectedEmploymentOptions = employmentType.filter((option) =>
    selectedEmploymentIds.includes(option.id)
  );

  React.useEffect(() => {
    // const ids = selectedGender.map((item) => item.id);
    setFieldValue("leave_applicability", {
      ...values.leave_applicability,
      applicable_gender: selectedGenderIds,
    });
  }, [selectedGenderIds]);

  React.useEffect(() => {
    // const ids = selectedMaritalStatus.map((item) => item.id);
    setFieldValue("leave_applicability", {
      ...values.leave_applicability,
      applicable_marital_status: selectedMaritalIds,
    });
  }, [selectedMaritalIds]);

  React.useEffect(() => {
    // const ids = selectedEmploymentType.map((item) => item.id);
    setFieldValue("leave_applicability", {
      ...values.leave_applicability,
      applicable_employee_category: selectedEmploymentIds,
    });
  }, [selectedEmploymentIds]);

  return (
    <MyCard title="Applicability" width="full">
      <Box>
        {/* {values.leave_applicabilities?.map((item, index) => ( */}
        <Grid container spacing={2} row className="mt-2">
          <Grid item size={12} className="mt-2">
            <MultiSelectAutocomplete
              options={gender}
              label="Gender"
              selectedOptions={selectedGenderOptions}
              setSelectedOptions={setSelectedGenderIds}
            />
          </Grid>
          <Grid item size={12} className="mt-2">
            <MultiSelectAutocomplete
              options={maritalStatus}
              label="Marital Status"
              selectedOptions={selectedMaritalOptions}
              setSelectedOptions={setSelectedMaritalIds}
            />
          </Grid>
          <Grid item size={12} className="mt-2">
            <MultiSelectAutocomplete
              options={employmentType}
              label="Employment Type"
              selectedOptions={selectedEmploymentOptions}
              setSelectedOptions={setSelectedEmploymentIds}
            />
          </Grid>
        </Grid>
        {/* ))} */}
      </Box>
    </MyCard>
  );
};

export default Applicability;
