import React, { useEffect, useState, useMemo } from "react";
import MyCard from "../../components/common/MyCard";
import {
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
  TextField,
  Grid2,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  Avatar,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import employeeData from "./employeeData";
import useFetch from "../../hooks/useFetch";
import AddEmployee from "./AddEmployee";

const EmployeeListing = () => {
  const [employeeName, setEmployeeName] = useState("");
  const [branch, setBranch] = useState("");
  const [includeSubBranches, setIncludeSubBranches] = useState(false);
  const [department, setDepartment] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isAddEmployeeOpen, setAddEmployeeOpen] = useState(false);

  // uncomment these two line to get the data from the API
  const getEmployeeUrl = useMemo(() => "/employee/company-employees", []);
  const params = useMemo(() => ({}), []);
  const {
    data: employeeData,
    loading,
    error,
  } = useFetch(getEmployeeUrl, params);

  const navigate = useNavigate();

  // Placeholder for dropdown data
  const employees = [];
  const branches = [];
  const departments = [];

  const handleSearch = () => {
    // Search functionality to filter employees
    console.log("Searching for:", {
      employeeName,
      branch,
      includeSubBranches,
      department,
      searchQuery,
    });
  };

  const handleMenuOpen = (event, employee) => {
    setAnchorEl(event.currentTarget);
    setSelectedEmployee(employee);
  };

  // const handleEditEmployee = (employee) => {
  //   navigate("/app/employee/addemployee", { state: { employee } });
  // };

  const handleAction = (action) => {
    switch (action) {
      case "Edit":
        setAddEmployeeOpen(true);
        break;

      default:
        console.warn("Unhandled action:", action);
    }
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="p-4 space-y-10">
      <MyCard
        width="full"
        title={
          <div className="flex flex-row justify-between">
            <div className="flex font-normal ">
              <h2 className="tracking-wide">Employee filter</h2>
            </div>
            <div className="flex justify-end items-center gap-x-5">
              <Button variant="contained" color="primary">
                Bulk Upload
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setAddEmployeeOpen(true);
                  setSelectedEmployee(null);
                }}
              >
                Add New Employee
              </Button>
            </div>
          </div>
        }
      >
        <div className="flex flex-row item-center justify-center space-x-4 p-4">
          <div className="flex-1 ">
            {/* Employee Name Dropdown */}
            <FormControl fullWidth>
              <InputLabel>Employee Name</InputLabel>
              <Select
                value={employeeName}
                onChange={(e) => setEmployeeName(e.target.value)}
                label="Employee Name"
              >
                {employees.length > 0 ? (
                  employees.map((employee) => (
                    <MenuItem key={employee.id} value={employee.name}>
                      {employee.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    No Employees Available
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>

          <div className="flex-1">
            {/* Branch Dropdown */}
            <FormControl fullWidth>
              <InputLabel>Branch</InputLabel>
              <Select
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
                label="Branch"
              >
                {branches.length > 0 ? (
                  branches.map((branchItem) => (
                    <MenuItem key={branchItem.id} value={branchItem.name}>
                      {branchItem.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    No Branches Available
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <span>
              {/* Checkbox for Include Sub Branches */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={includeSubBranches}
                    onChange={(e) => setIncludeSubBranches(e.target.checked)}
                    name="includeSubBranches"
                  />
                }
                label="Include Sub Branches"
              />
            </span>
          </div>

          <div className="flex-1">
            {/* Department Dropdown */}
            <FormControl fullWidth>
              <InputLabel className="item-center">Department</InputLabel>
              <Select
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                label="Department"
              >
                {departments.length > 0 ? (
                  departments.map((departmentItem) => (
                    <MenuItem
                      key={departmentItem.id}
                      value={departmentItem.name}
                    >
                      {departmentItem.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    No Departments Available
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>

          <div className="pt-2">
            {/* Search Button */}
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Search
            </Button>
          </div>
        </div>
      </MyCard>

      <MyCard title="Employee List" width="full">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Picture</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Mobile Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeData?.map((employee, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Avatar src={employee.full_name} alt={employee.full_name} />
                  </TableCell>
                  <TableCell>{employee.full_name}</TableCell>
                  <TableCell>{employee.employee_number}</TableCell>
                  <TableCell>{employee.contact_number}</TableCell>
                  <TableCell>{employee.email}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleMenuOpen(event, employee)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </MyCard>

      {/* Menu for actions */}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleAction("Edit");
          }}
        >
          Edit
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>View</MenuItem>
        <MenuItem onClick={handleMenuClose}>Face Device</MenuItem>
        <MenuItem onClick={handleMenuClose}>Device</MenuItem>
        <MenuItem onClick={handleMenuClose}>Last Active Location</MenuItem>
        <MenuItem onClick={handleMenuClose}>Suspend</MenuItem>
        <MenuItem onClick={handleMenuClose}>Assign Location</MenuItem>
      </Menu>

      {isAddEmployeeOpen && (
        <AddEmployee
          isModalOpen={setAddEmployeeOpen}
          employeeData={selectedEmployee}
        />
      )}
    </div>
  );
};

export default EmployeeListing;
