import * as React from "react";
import { Box } from "@mui/material";
import MyCard from "../../../../components/common/MyCard";
import Grid from "@mui/material/Grid2";
// import { FaPlusCircle } from "react-icons/fa";
import {
  gender,
  maritalStatus,
  employmentType,
  // applicableCriteria,
} from "../../../../utils/constants/dropdown";
import { Field } from "formik";
import useFetch from "../../../../hooks/useFetch";
import MultiSelectAutocomplete from "../../../../components/common/MultiSelectAutocomplete";

const Exceptions = ({ values, setFieldValue }) => {
  const [selectedGenderIds, setSelectedGenderIds] = React.useState(
    values.leave_exception?.exception_gender || []
  );
  const [selectedMaritalIds, setSelectedMaritalIds] = React.useState(
    values.leave_exception?.exception_marital_status || []
  );
  const [selectedEmploymentIds, setSelectedEmploymentIds] = React.useState(
    values.leave_exception?.exception_employee_category || []
  );

  const selectedGenderOptions = gender.filter((option) =>
    selectedGenderIds.includes(option.id)
  );
  const selectedMaritalOptions = maritalStatus.filter((option) =>
    selectedMaritalIds.includes(option.id)
  );
  const selectedEmploymentOptions = employmentType.filter((option) =>
    selectedEmploymentIds.includes(option.id)
  );

  React.useEffect(() => {
    // const ids = selectedGender.map((item) => item.id);
    setFieldValue("leave_exception", {
      ...values.leave_exception,
      exception_gender: selectedGenderIds,
    });
  }, [selectedGenderIds]);

  React.useEffect(() => {
    // const ids = selectedMaritalStatus.map((item) => item.id);
    setFieldValue("leave_exception", {
      ...values.leave_exception,
      exception_marital_status: selectedMaritalIds,
    });
  }, [selectedMaritalIds]);

  React.useEffect(() => {
    // const ids = selectedEmploymentType.map((item) => item.id);
    setFieldValue("leave_exception", {
      ...values.leave_exception,
      exception_employee_category: selectedEmploymentIds,
    });
  }, [selectedEmploymentIds]);

  //   const deptUrl = React.useMemo(() => "/company/departments/list", []);
  //   const params = React.useMemo(() => ({}), []);
  //   const { data: departmentsList } = useFetch(deptUrl, params);

  //   const [criteriaList, setCriteriaList] = React.useState([]);
  //   const [exceptionList, setExceptionList] = React.useState([]);

  //   const secondaryOptions = {
  //     applicable_gender: gender,
  //     marital_status: maritalStatus,
  //     departments: departmentsList,
  //   };

  //   const handleAddCriteria = () => {
  //     if (values.leave_applicabilities.length < applicableCriteria.length) {
  //       //   setCriteriaList((prev) => [...prev, { type: "", value: [] }]);
  //       const newCriteria = { type: "", value: [] };
  //       setFieldValue("leave_applicabilities", [
  //         ...values.leave_applicabilities,
  //         newCriteria,
  //       ]);
  //     }
  //   };

  //   const handleCriteriaChange = (listSetter) => (index, field, value) => {
  //     // listSetter((prev) => {
  //     //   const updatedList = [...prev];
  //     //   updatedList[index][field] = value;
  //     //   return updatedList;
  //     // });
  //     const updatedList = [...values.leave_applicabilities]; // Create a copy of the existing array
  //     updatedList[index] = { ...updatedList[index], [field]: value }; // Update the specific field in the object

  //     listSetter("leave_applicabilities", updatedList);
  //   };

  return (
    <MyCard
      title="Exceptions"
      width="full"
      //   actions={
      //     <IconButton
      //       color="primary"
      //       onClick={() => handleAddCriteria()}
      //       sx={{ marginRight: 1, fontSize: 15 }}
      //     >
      //       <FaPlusCircle /> &nbsp; Add New
      //     </IconButton>
      //   }
    >
      <Box>
        {/* {values.leave_applicabilities?.map((item, index) => ( */}
        <Grid container spacing={2} row className="mt-2">
          <Grid item size={12} className="mt-2">
            <MultiSelectAutocomplete
              options={gender}
              label="Gender"
              selectedOptions={selectedGenderOptions}
              setSelectedOptions={setSelectedGenderIds}
            />
          </Grid>
          <Grid item size={12} className="mt-2">
            <MultiSelectAutocomplete
              options={maritalStatus}
              label="Marital Status"
              selectedOptions={selectedMaritalOptions}
              setSelectedOptions={setSelectedMaritalIds}
            />
          </Grid>
          <Grid item size={12} className="mt-2">
            <MultiSelectAutocomplete
              options={employmentType}
              label="Employment Type"
              selectedOptions={selectedEmploymentOptions}
              setSelectedOptions={setSelectedEmploymentIds}
            />
          </Grid>
        </Grid>
        {/* ))} */}
      </Box>
    </MyCard>
  );
};

export default Exceptions;
