import { IoIosArrowForward } from "react-icons/io";

export function flattenBranches(branches, parent = "") {
  let flatList = [];
  branches?.forEach((branch) => {
    // Create a label for the current branch (you can customize this)
    const label = parent ? (
      <>
        <div className="flex items-center gap-x-1">
          {parent}
          <IoIosArrowForward style={{ fontSize: "16px" }} />
          {branch.branch_name}
        </div>
      </>
    ) : (
      branch.branch_name
    );

    // Add the current branch to the flat list
    flatList.push({
      id: branch.id,
      display_name: branch.branch_name,
      label: label,
      parent: parent, // Optional: store parent for later reference
      sub_branches: branch.sub_branches,
    });

    // If there are sub-branches, recurse and flatten them as well
    if (branch.sub_branches && branch.sub_branches.length > 0) {
      flatList = flatList.concat(flattenBranches(branch.sub_branches, label));
    }
  });

  return flatList;
}
