import React from "react";
import { ThemeProvider } from "@mui/material";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import { store } from "./redux/store";
import { saveState } from "./local-storage";
import { Provider } from "react-redux";

store.subscribe(() => {
  saveState(store.getState());
  // console.log('getstore', store.getState());
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <ThemeProvider theme={theme}> */}
      <App />
      {/* </ThemeProvider> */}
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
