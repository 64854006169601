import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import MyCard from "../../components/common/MyCard";

import Swal from "sweetalert2";
import axiosInstance from "../../axios/axiosInstance";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { MdModeEdit } from "react-icons/md";

const HolidayManager = ({ isModalOpen }) => {
  const holidayListUrl = useMemo(() => "/company/holiday-group-day", []);
  const params = useMemo(() => ({}), []);
  const formRef = useRef(null);
  const [holidayData, setHolidayData] = useState([]);
  const [selectedHoliday, setSelectedHoliday] = useState(null); // Track selected holiday for edit

  // Function to fetch holidays
  const fetchHoliday = async () => {
    try {
      const response = await axiosInstance.get(holidayListUrl, params); // Fetch holiday data
      setHolidayData(response.data.data || []); // Update holiday data
    } catch (error) {
      console.error("Failed to fetch Holidays:", error);
    }
  };

  useEffect(() => {
    fetchHoliday(); // Fetch holidays on component mount
  }, []);

  const formik = useFormik({
    initialValues: {
      holidaytitle: "",
      holidaydate: null,
      holidaydescription: "",
    },
    validationSchema: Yup.object({
      holidaytitle: Yup.string().required("Holiday title is required"),
      holidaydate: Yup.date().required("Holiday date is required"),
      holidaydescription: Yup.string()
        .required("Holiday description is required")
        .max(200, "Description cannot exceed 200 characters"),
    }),
    onSubmit: async (values) => {
      try {
        let response;
        const holidayPayload = {
          display_name: values.holidaytitle,
          holiday_date: moment(values.holidaydate).format("YYYY-MM-DD"),
          description: values.holidaydescription,
        };

        if (selectedHoliday) {
          // If editing an existing holiday, send a PUT request
          response = await axiosInstance.put(`/company/holiday-group-day`, {
            ...holidayPayload,
            id: selectedHoliday.id, // Use selectedHoliday's id for updating
          });
          if (response.data.status === 200 || response.data.status === 201) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Holiday updated successfully!",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            });
            // .then(() => {
            //   isModalOpen(false); // Close the modal after confirmation
            // });
          }
        } else {
          // If adding a new holiday, send a POST request
          response = await axiosInstance.post(
            "/company/holiday-group-day",
            holidayPayload
          );
          if (response.data.status === 200 || response.data.status === 201) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Holiday added successfully!",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            });
            // .then(() => {
            //   isModalOpen(false); // Close the modal after confirmation
            // });
          }
        }

        formik.resetForm();
        setSelectedHoliday(null); // Clear selected holiday after submission
        fetchHoliday(); // Re-fetch holidays to update the list
      } catch (err) {
        const dash = getErrorMessage(err.response.data.error);
        Swal.fire({
          icon: "error",
          text: dash,
          title: "Error!",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }
    },
  });

  const handleEditClick = (holiday) => {
    setSelectedHoliday(holiday); // Set the shift to be edited
    formik.setValues({
      id: holiday.id,

      holidaytitle: holiday.display_name,
      holidaydate: holiday.holiday_date
        ? moment(holiday.holiday_date, "YYYY-MM-DD")
        : null,
      holidaydescription: holiday.description,
    });

    // Scroll to the top of the form
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Dialog open={true} maxWidth="md" onClose={() => isModalOpen(false)}>
      <DialogTitle>
        {selectedHoliday ? "Update Holiday" : "Add Holiday"}
        <IconButton
          aria-label="close"
          onClick={() => isModalOpen(false)}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <form ref={formRef} onSubmit={formik.handleSubmit}>
          {/* Form Fields */}
          <div className="flex flex-row gap-4 mb-4">
            <div className="flex-1">
              <TextField
                label="Holiday Title"
                name="holidaytitle"
                value={formik.values.holidaytitle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                variant="outlined"
                size="small"
                error={
                  formik.touched.holidaytitle &&
                  Boolean(formik.errors.holidaytitle)
                }
                helperText={
                  formik.touched.holidaytitle && formik.errors.holidaytitle
                }
              />
            </div>
            <div className="flex-1">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  name="holidaydate"
                  label="Holiday Date"
                  inputFormat="MM/DD/YYYY"
                  value={formik.values.holidaydate}
                  onChange={(newValue) =>
                    formik.setFieldValue("holidaydate", newValue)
                  }
                  slotProps={{
                    textField: {
                      error:
                        formik.touched.holidaydate &&
                        Boolean(formik.errors.holidaydate),
                      helperText:
                        formik.touched.holidaydate && formik.errors.holidaydate,
                      fullWidth: true,
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="flex-1">
              <TextField
                label="Holiday Description"
                name="holidaydescription"
                value={formik.values.holidaydescription}
                onChange={formik.handleChange}
                size="small"
                fullWidth
                variant="outlined"
                error={
                  formik.touched.holidaydescription &&
                  Boolean(formik.errors.holidaydescription)
                }
                helperText={
                  formik.touched.holidaydescription &&
                  formik.errors.holidaydescription
                }
              />
            </div>
          </div>

          <div className="mb-4 flex justify-end">
            <Button variant="contained" color="primary" type="submit">
              {selectedHoliday ? "Update Holiday" : "Add Holiday"}
            </Button>
          </div>

          <div>
            {/* Section Title */}
            <div className="border-b-2 pb-2 mb-4">
              <Typography variant="h5" className="font-semibold">
                Added Holidays
              </Typography>
            </div>

            {/* Display Holiday Details */}
            <div className="grid grid-cols-1 gap-2">
              {holidayData && holidayData.length > 0 ? (
                holidayData.map((holiday) => (
                  <MyCard
                    key={holiday.id}
                    title={
                      <div className="flex items-center justify-between">
                        <Typography variant="h6" className="font-semibold">
                          {holiday.display_name}
                        </Typography>
                        <MdModeEdit
                          className="text-xl cursor-pointer"
                          onClick={() => handleEditClick(holiday)}
                        />
                      </div>
                    }
                    width="full"
                  >
                    <div className="flex flex-row gap-x-20">
                      {/* Holiday Details */}
                      <div className="flex flex-col">
                        <Typography
                          variant="subtitle1"
                          className="font-semibold"
                        >
                          Holiday Details
                        </Typography>
                        <Typography variant="body2">
                          <strong>Date:</strong>{" "}
                          {holiday.holiday_date || "Not set"}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Description:</strong>{" "}
                          {holiday.description || "Not set"}
                        </Typography>
                      </div>
                    </div>
                  </MyCard>
                ))
              ) : (
                <Typography variant="body2">No holidays available</Typography>
              )}
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default HolidayManager;
