import * as actionTypes from "./actions";

import {
  SET_ACTIVE_MENU,
  SET_ACTIVE_SUBMENU,
  SET_ACTIVE_INDEX,
  SET_ACTIVE_TAB,
  SET_BULK_ACTIVE_TAB,
  SET_THEME_COLOR,
} from "../redux/actions";

const initialState = {
  isAuthorised: false,
  adminMasterLoader: false,
  userData: {
    userProfile: "",
    userId: null,
    username: "",
    userEmail: "",
    userFullName: "",
    userType: "",
    userRoleId: "",
    userNumber: "",
    profileImg: "",
    userAccessUrls: [],
    tokenExpirationDate: null,
    userTheme: "",
    // menuIemsFromApi: []
  },
  theme: "light",
  sidebarDataFromApi: [],
  activeMenu: null,
  activeSubMenu: null,
  activeIndex: {
    type: "",
    index: null,
  },
  initialState: {
    activeTabIndex: 0,
  },
};

const reducer = (state = initialState, action) => {
  console.log(action.type);
  switch (action.type) {
    case actionTypes.CLEAR_REDUX_STATE:
      return initialState; // Reset to the initial state

    case actionTypes.ADMIN_AUTHORIZED:
      return {
        ...state,
        isAuthorised: action.payload.authState,
        userData: {
          ...action.payload.userData,
        },
      };
    case actionTypes.SET_SIDEBAR_DATA:
      return {
        ...state,
        sidebarDataFromApi: action.payload,
      };
    case actionTypes.SET_THEME:
      console.log("hii");
      return {
        ...state,
        theme: action.payload,
      };
    case actionTypes.ADMIN_MASTER_LOADER:
      return {
        ...state,
        adminMasterLoader: action.payload.adminMasterLoader,
      };
    case SET_ACTIVE_MENU:
      return {
        ...state,
        activeMenu: action.payload,
        activeSubMenu: null,
      };
    case SET_BULK_ACTIVE_TAB:
      return {
        ...state,
        activeMenu: action.payload,
        activeSubMenu: null,
      };
    case SET_ACTIVE_SUBMENU:
      return {
        ...state,
        activeSubMenu: action.payload,
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTabIndex: action.payload,
      };
    case SET_ACTIVE_INDEX:
      return {
        ...state,
        activeIndex: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
