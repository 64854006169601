export const gender = [
  { id: "MALE", label: "Male" },
  { id: "FEMALE", label: "Female" },
  { id: "THIRD_GENDER", label: "No to  specify" },
];

export const bloodGroup = [
  { id: "A+", label: "A+ (A Positive)" },
  { id: "A-", label: "A- (A Negative)" },
  { id: "B+", label: "B+ (B Positive)" },
  { id: "B-", label: "B- (B Negative)" },
  { id: "AB+", label: "AB+ (AB Positive)" },
  { id: "AB-", label: "AB- (AB Negative)" },
  { id: "O+", label: "O+ (O Positive)" },
  { id: "O-", label: "O- (O Negative)" },
];

export const employmentType = [
  { id: "REGULAR", label: "REGULAR" },
  { id: "OUT_SOURCED", label: "OUT_SOURCED" },
  { id: "CONTRACT", label: "CONTRACT" },
];

export const maritalStatus = [
  { id: "UNMARRIED", label: "Unmarried" },
  { id: "MARRIED", label: "Married" },
  { id: "DIVORCED", label: "Divorced" },
  { id: "WIDOWED", label: "Widowed" },
  { id: "SEPARATED", label: "Seperated" },
];

export const applicableCriteria = [
  { id: "applicable_gender", label: "Gender" },
  { id: "marital_status", label: "Marital Status" },
  //   { id: "departments", label: "Departments" },
  { id: "designation", label: "Designation" },
  //   { id: "location", label: "Location" },
  // { id: "roles", label: "Roles" },
  { id: "employmentType", label: "Employee Category" },
];

export const PERIOD_CHOICES = [
  { id: "yearly", display_name: "Yearly" },
  { id: "monthly", display_name: "Monthly" },
  { id: "half_yearly", display_name: "Half Yearly" },
  { id: "quarterly", display_name: "Quarterly" },
  { id: "weekly", display_name: "Weekly" },
  { id: "one_time", display_name: "One Time" },
];
// DAY_CHOICES = [(str(i), str(i)) for i in range(1, 32)]
export const MONTH_CHOICES = [
  { id: "january", display_name: "January" },
  { id: "february", display_name: "February" },
  { id: "march", display_name: "March" },
  { id: "april", display_name: "April" },
  { id: "may", display_name: "May" },
  { id: "june", display_name: "June" },
  { id: "july", display_name: "July" },
  { id: "august", display_name: "August" },
  { id: "september", display_name: "September" },
  { id: "october", display_name: "October" },
  { id: "november", display_name: "November" },
  { id: "december", display_name: "December" },
  ,
];
export const LEAVE_CHOICES = [
  { id: "CL", display_name: "Casual Leave" },
  { id: "SL", display_name: "Sick Leave" },
  { id: "EL", display_name: "Earned Leave" },
];
export const TYPE_CHOICES = [
  { id: "paid", display_name: "Paid" },
  { id: "unpaid", display_name: "Unpaid" },
];
export const UNIT_CHOICES = [
  { id: "half_day", display_name: "Half Day" },
  { id: "full_day", display_name: "Full Day" },
];
export const CFE_UNIT_CHOICES = [
  { id: "days", display_name: "days" },
  { id: "percentage", display_name: "percentage" },
];
export const EFFECTIVE_PERIOD = [
  { id: "days", display_name: "Days" },
  { id: "months", display_name: "Months" },
  { id: "years", display_name: "Years" },
];

export const ANUALTURNOVER = [
  { id: "_10L", display_name: "Less than 10L" },
  { id: "10L_50L", display_name: "10L to 50L" },
  { id: "50L_1CR", display_name: "50L to 1CR" },
  { id: "1CR_100CR", display_name: "1CR to 100CR" },
  { id: "100CR_", display_name: "Above 100CR" },
];
