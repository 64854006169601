// menuItems.js
import { AiOutlineDashboard } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import { FaUsers, FaBuildingColumns } from "react-icons/fa6";
import { BsBuildingCheck } from "react-icons/bs";
import { GiPriceTag } from "react-icons/gi";

const menuItems = [
  {
    id: 1,
    title: "Dashboard",
    icon: <AiOutlineDashboard color="#fff" size={20} />,
    route: "/app/dashboard",
    access_roles: [2], // Example roles
  },
  {
    id: 2,
    title: "Statistics",
    icon: <MdOutlineDashboard color="#fff" size={20} />,
    route: "/app/stats",
    access_roles: [2],
  },
  {
    id: 3,
    title: "Employees",
    icon: <FaUsers color="#fff" size={20} />,
    route: "/app/employee",
    access_roles: [2],
  },
  {
    id: 4,
    title: "Organization Settings",
    icon: <FaBuildingColumns color="#fff" size={20} />,
    access_roles: [2],
    submenu: [
      {
        id: 5,
        title: "Branches",
        route: "/app/organization/branches",
        access_roles: [2],
      },
      {
        id: 6,
        title: "Configuration",
        route: "/app/organization/configuration",
        access_roles: [2],
      },
      {
        id: 7,
        title: "Holidays",
        route: "/app/organization/holidays",
        access_roles: [2],
      },
    ],
  },
  {
    id: 8,
    title: "Registered Companies",
    icon: <BsBuildingCheck color="#fff" size={20} />,
    route: "/app/reg-companies",
    access_roles: [1],
  },
  {
    id: 9,
    title: "Billing",
    icon: <GiPriceTag color="#fff" size={20} />,
    route: "/app/pricing",
    access_roles: [1, 2],
  },
];

export default menuItems;
