import React, { useState, useEffect } from "react";
import { TextField, Button, IconButton, Select, MenuItem } from "@mui/material";
import { MdModeEdit, MdDelete } from "react-icons/md";
import MyCard from "../../../components/common/MyCard";
import axiosInstance from "../../../axios/axiosInstance";
import Swal from "sweetalert2";

const DeptDesn = () => {
  const [departments, setDepartments] = useState([]);
  const [departmentName, setDepartmentName] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [designationName, setDesignationName] = useState("");
  const [designations, setDesignations] = useState([]);
  const [isEditingDept, setIsEditingDept] = useState(false);
  const [editingDeptId, setEditingDeptId] = useState(null);
  const [isEditingDesg, setIsEditingDesg] = useState(false);
  const [editingDesgId, setEditingDesgId] = useState(null);

  // Fetch departments and designations on mount
  useEffect(() => {
    fetchDepartments();
    fetchDesignations();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await axiosInstance.get("/company/departments/list");
      setDepartments(response.data?.data || []);
    } catch (error) {
      console.error("Error fetching departments:", error.message);
    }
  };

  const fetchDesignations = async () => {
    try {
      const response = await axiosInstance.get("/company/designation/list");
      setDesignations(response.data?.data || []);
    } catch (error) {
      console.error("Error fetching designations:", error.message);
    }
  };

  // Add or update department
  const handleSubmitDepartment = async () => {
    try {
      if (isEditingDept) {
        const response = await axiosInstance.put(
          "/company/departments/update",
          {
            id: editingDeptId,
            display_name: departmentName,
          }
        );

        const successMessage =
          response?.data?.message || "Department updated successfully.";
        Swal.fire("Updated!", successMessage, "success");
      } else {
        const response = await axiosInstance.post("/company/departments/add", {
          display_name: departmentName,
        });

        const successMessage =
          response?.data?.message || "Department added successfully.";
        Swal.fire("Added!", successMessage, "success");
      }
      setDepartmentName("");
      setIsEditingDept(false);
      fetchDepartments();
    } catch (error) {
      console.error("Error in handleSubmitDepartment:", error);
      Swal.fire("Error!", "Unable to save department.", "error");
    }
  };

  // Add or update designation
  const handleSubmitDesignation = async () => {
    if (!designationName || !selectedDepartment) {
      Swal.fire(
        "Error",
        "Please fill Designation name or choose department",
        "error"
      );
      return;
    }

    try {
      if (isEditingDesg) {
        // Update designation
        await axiosInstance.put(`/company/designation/update`, {
          id: editingDesgId,
          display_name: designationName,
          company_department_id: selectedDepartment,
        });
        Swal.fire("Updated!", "Designation updated successfully.", "success");
      } else {
        // Add new designation
        await axiosInstance.post(`/company/designation/add`, {
          display_name: designationName,
          company_department_id: selectedDepartment,
        });
        Swal.fire("Added!", "Designation added successfully.", "success");
      }

      setDesignationName("");
      setSelectedDepartment("");
      setIsEditingDesg(false);
      fetchDesignations(); // Refresh data
    } catch (error) {
      Swal.fire(
        "Error",
        "Unable to save designation. Try again later.",
        "error"
      );
    }
  };

  // // Delete department
  // const handleDeleteDepartment = async (deptId) => {
  //   try {
  //     await axiosInstance.delete("/company/departments/delete", {
  //       data: { id: deptId },
  //     });
  //     Swal.fire("Deleted!", "Department deleted successfully.", "success");
  //     fetchDepartments();
  //   } catch (error) {
  //     Swal.fire("Error!", "Unable to delete department.", "error");
  //   }
  // };

  // // Delete designation
  // const handleDeleteDesignation = async (desgId) => {
  //   try {
  //     await axiosInstance.delete("/company/designation/delete", {
  //       data: { id: desgId },
  //     });
  //     Swal.fire("Deleted!", "Designation deleted successfully.", "success");
  //     fetchDesignations();
  //   } catch (error) {
  //     Swal.fire("Error!", "Unable to delete designation.", "error");
  //   }
  // };

  return (
    <div className="space-y-6">
      <div className="flex space-x-4">
        {/* Department Management */}
        <MyCard width="40%" title="Manage Departments">
          <div className="flex gap-3">
            <TextField
              label="Department Name"
              value={departmentName}
              onChange={(e) => setDepartmentName(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitDepartment}
            >
              {isEditingDept ? "Update" : "Add"}
            </Button>
          </div>
          <table className="w-full mt-4 border border-gray-300">
            <thead>
              <tr className="border-b border-gray-300">
                <th className="p-2 border-r border-gray-300">Department</th>
                <th className="p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {departments.map((dept) => (
                <tr key={dept.id} className="border-b border-gray-300">
                  <td className="p-2 border-r border-gray-300">
                    {dept.display_name}
                  </td>
                  <td className="p-2">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setIsEditingDept(true);
                        setEditingDeptId(dept.id);
                        setDepartmentName(dept.display_name);
                      }}
                    >
                      <MdModeEdit />
                    </IconButton>
                    {/* <IconButton
                      color="error"
                      onClick={() => handleDeleteDepartment(dept.id)}
                    >
                      <MdDelete />
                    </IconButton> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </MyCard>

        {/* Designation Management */}
        <MyCard width="60%" title="Manage Designations">
          <div className="flex gap-3">
            <Select
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
              displayEmpty
              size="small"
              fullWidth
            >
              <MenuItem value="" disabled>
                Select Department
              </MenuItem>
              <MenuItem value="">All Departments</MenuItem>
              {departments.map((dept) => (
                <MenuItem key={dept.id} value={dept.id}>
                  {dept.display_name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label="Designation Name"
              value={designationName}
              onChange={(e) => setDesignationName(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitDesignation}
            >
              {isEditingDesg ? "Update" : "Add"}
            </Button>
          </div>
          <table className="w-full mt-4 border border-gray-300">
            <thead>
              <tr className="border-b border-gray-300">
                <th className="p-2 border-r border-gray-300">Department</th>
                <th className="p-2 border-r border-gray-300">Designation</th>
                <th className="p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {designations
                .filter(
                  (desg) =>
                    !selectedDepartment ||
                    desg.company_department_id === selectedDepartment
                )
                .map((desg) => {
                  const department = departments.find(
                    (dept) => dept.id === desg.company_department_id
                  );

                  return (
                    <tr key={desg.id} className="border-b border-gray-300">
                      {/* Department Name */}
                      <td className="p-2 border-r border-gray-300">
                        {department?.display_name || "Unknown"}
                      </td>
                      {/* Designation Name */}
                      <td className="p-2 border-r border-gray-300">
                        {desg.display_name || "Unknown"}
                      </td>
                      {/* Actions */}
                      <td className="p-2">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setIsEditingDesg(true);
                            setEditingDesgId(desg.id);
                            setDesignationName(desg.display_name);
                            setSelectedDepartment(desg.company_department_id);
                          }}
                        >
                          <MdModeEdit />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </MyCard>
      </div>
    </div>
  );
};

export default DeptDesn;
