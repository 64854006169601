import React, { useState, useMemo } from "react";
import MyCard from "../../components/common/MyCard";
import { DataTable } from "../../components/DataTable";
import { IconButton, Box } from "@mui/material";
import { CheckCircle, Delete, Visibility } from "@mui/icons-material";
import Swal from "sweetalert2";
import Toast from "../../components/common/Toast";
import useFetch from "../../hooks/useFetch";
import ViewCompanyDetails from "./ViewCompanyDetails";
import axiosInstance from "../../axios/axiosInstance";

const RegisteredUsers = () => {
  const dataUrl = useMemo(() => "/company/pending-approval", []);
  const params = useMemo(() => ({}), []);
  const [showModal, setShowModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const { data } = useFetch(dataUrl, params);

  const columns = [
    { field: "id", headerName: "ID", width: 60 },
    { field: "registered_name", headerName: "Company Name", width: 150 },
    {
      field: "establishment_year",
      headerName: "Est. Year",
      // type: "number",
      width: 80,
    },
    {
      field: "company_contact_number",
      headerName: "Contact Number",
      width: 150,
    },
    { field: "company_email", headerName: "Email", width: 200 },
    { field: "annual_turnover", headerName: "Anual Turnover", width: 100 },
    { field: "number_of_branches", headerName: "Branches", width: 100 },
    { field: "number_of_employees", headerName: "Employees", width: 100 },
    { field: "city", headerName: "City", width: 100 },
  ];

  // Custom Actions Component
  const renderActions = (params) => {
    const { id } = params.row;
    return (
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton
          color="primary"
          onClick={() => handleView(params.row)}
          // sx={{ marginRight: 1 }}
        >
          <Visibility />
        </IconButton>
        <IconButton
          color="success"
          onClick={() => handleApprove(id)}
          // sx={{ marginRight: 1 }}
        >
          <CheckCircle />
        </IconButton>
        <IconButton color="error" onClick={() => handleReject(id)}>
          <Delete />
        </IconButton>
      </Box>
    );
  };

  const handleView = (company) => {
    setSelectedCompany(company);
    setShowModal(true);
  };

  const handleApprove = async (companyId) => {
    Swal.fire({
      text: "Are you sure to approve the company ?",
      confirmButtonText: "Confirm Approve",
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axiosInstance.post("/company/approve", {
            id: companyId,
            status_state: "APPROVED",
            status_state_remarks: "approved",
            status_state_changed_by: 1,
          });
          if (response.data.status === 200) {
            Swal.fire({
              title: "success",
              text: "Company Approved successfully.!",
              icon: "success",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            });
          }
        } catch (err) {
          console.log(err);
          Toast.fire({ title: err.message, icon: "error" });
        }
      }
    });
  };
  const handleReject = async (companyId) => {
    const { value: rejectReason } = await Swal.fire({
      title: "Please enter the reason",
      input: "text",
      showCancelButton: true,
      confirmButtonText: "Reject",
      confirmButtonColor: "red",
      inputPlaceholder: "Enter rejection reason",
      allowOutsideClick: false,
      inputValidator: (value) => {
        if (!value) {
          return "Please write something!";
        }
      },
    });

    if (rejectReason) {
      try {
        const response = await axiosInstance.post("/company/approve", {
          id: companyId,
          status_state: "REJECTED",
          status_state_remarks: rejectReason,
          status_state_changed_by: 1,
        });
        if (response.data.status === 200) {
          Swal.fire({
            title: "success",
            text: "Company Rejected successfully.!",
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
      } catch (err) {
        console.log(err);
        Toast.fire({ title: err.message, icon: "error" });
      }
    }
  };

  return (
    <>
      <div className="mt-10">
        <MyCard title="Registered Companies" width={"96%"}>
          <DataTable
            data={data}
            columns={columns}
            renderActions={renderActions}
            pageSize={10}
          />
        </MyCard>
      </div>

      {showModal && selectedCompany && (
        <ViewCompanyDetails
          company={selectedCompany}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      )}
    </>
  );
};

export default RegisteredUsers;
