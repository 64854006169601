import React, { useState } from "react";
import { FaBell } from "react-icons/fa";
import theme from "../../theme";
import {
  Box,
  MenuItem,
  Typography,
  IconButton,
  Menu,
  Tooltip,
  Avatar,
  FormControlLabel,
  Switch,
  CssBaseline,
} from "@mui/material";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../redux/actions";
import { useNavigate } from "react-router-dom";
const settings = ["Profile", "Logout"];

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  const handleCloseUserMenu = (logout) => {
    setAnchorElUser(null);
    if (logout) {
      dispatch({ type: actionTypes.CLEAR_REDUX_STATE });
      navigate("/signin");
    }
  };
  // const handleToggle = (event) => {
  //   console.log(event.target.checked ? "dark" : "light");
  //   dispatch(actionTypes.setTheme(event.target.checked ? "dark" : "light"));
  //   setIsDarkMode(event.target.checked);
  // };

  return (
    <Box>
      <div className="fixed top-0 right-0 py-1 z-[100] flex item-center justify-content-between">
        <div className="ml-auto flex justify-between p-4">
          <button>
            <FaBell style={{ color: theme.palette.primary.main }} />
          </button>
          {/* <div className="mode-container">
            <label className="switch">
              <input
                type="checkbox"
                checked={isDarkMode}
                onChange={handleToggle}
              />
              <span className="slider"></span>
            </label>
            {/* <span className="slider-text">
              {isDarkMode ? "Dark Mode" : "Light Mode"}
            </span> */}
          {/* {isDarkMode ? (
              <span className={"dark"}>Dark Mode</span>
            ) : (
              <span className={"slider-text"}>Light Mode</span>
            )} */}
          {/* </div> */}
        </div>
        {/* <FormControlLabel
          control={
            <Switch
              checked={isDarkMode}
              onChange={handleToggle}
              color="primary"
              sx={{
                "& .MuiSwitch-thumb": {
                  backgroundColor: isDarkMode ? "#fff" : "#000",
                },
                "& .MuiSwitch-track": {
                  backgroundColor: isDarkMode ? "#444" : "#bbb",
                },
              }}
            />
          }
          label={isDarkMode ? "Dark Mode" : "Light Mode"}
          labelPlacement="bottom"
          sx={{
            "& .MuiFormControlLabel-label": {
              color: isDarkMode ? "#fff" : "#000",
              fontWeight: 500,
            },
          }}
        /> */}
        <div className="ml-auto flex justify-between p-2">
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt="Remy Sharp"
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cHJvZmlsZXxlbnwwfHwwfHx8MA%3D%3D"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={() => handleCloseUserMenu(false)}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleCloseUserMenu(setting == "Logout")}
                >
                  <Typography sx={{ textAlign: "center" }}>
                    {setting}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default Header;
