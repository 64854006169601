import React, { useMemo } from "react";
import { useFormik } from "formik";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Autocomplete,
  Chip,
  FormHelperText,
} from "@mui/material";
import MyCard from "../../../components/common/MyCard";
import * as Yup from "yup";
import useFetch from "../../../hooks/useFetch";
import axiosInstance from "../../../axios/axiosInstance";
import Swal from "sweetalert2";

const GroupForm = ({ handleClosePopup, initialData }) => {
  const groupUrl = useMemo(() => "/general-config/group", []);
  const weekoffUrl = useMemo(() => "/general-config/weekoff", []);
  const shiftUrl = useMemo(() => "/general-config/shift", []);
  const params = useMemo(() => ({}), []);

  const { data: shiftData } = useFetch(shiftUrl, params);
  const { data: weekoffData } = useFetch(weekoffUrl, params);

  // Map `shiftData` to options for Autocomplete
  const shiftOptions = shiftData || [];
  const weekoffOptions = weekoffData || [];
  console.log(initialData?.shifts_details.map((shift) => shift.id));
  const formik = useFormik({
    initialValues: {
      id: initialData?.id || null,
      group_name: initialData?.group_name || "",
      enable_office_checkin: initialData?.enable_office_checkin || false,
      enable_field_checkin: initialData?.enable_field_checkin || false,
      enable_face_validation: initialData?.enable_face_validation || false,
      static_time: initialData?.static_time || "",
      block_outside_premises: initialData?.block_outside_premises || false,
      tracking_time: initialData?.tracking_time || "",
      field_static_time: initialData?.field_static_time || "",
      shifts: initialData?.shifts_details.map((shift) => shift.id) || [],
      week_offs:
        initialData?.week_offs_details.map((weekoff) => weekoff.id) || [],
      // shifts: Array.isArray(initialData?.shifts)
      //   ? initialData.shifts.map((shift) => shift.id)
      //   : [],
      // week_offs: Array.isArray(initialData?.week_offs)
      //   ? initialData.week_offs.map((weekoff) => weekoff.id)
      //   : [],
    },

    validationSchema: Yup.object({
      group_name: Yup.string().required("Group name is required"),
      shifts: Yup.array().min(1, "At least one shift is required"),
    }),

    onSubmit: async (values, { setErrors }) => {
      const transformedValues = {
        ...values,
        static_time: values.static_time || null, // Set to null if empty
        tracking_time: values.tracking_time || null,
        field_static_time: values.field_static_time || null,
      };
      // Validation: Ensure at least one check-in is enabled
      if (!values.enable_office_checkin && !values.enable_field_checkin) {
        setErrors({
          enable_field_checkin:
            "Either office or field check-in must be enabled",
        });
        return; // Prevent form submission if validation fails
      }

      try {
        if (initialData) {
          // Update existing group
          console.log("data", initialData);
          await axiosInstance.put(groupUrl, transformedValues);
          Swal.fire("Success", "Group updated successfully!", "success");
        } else {
          // Create new group
          await axiosInstance.post(groupUrl, transformedValues);
          Swal.fire("Success", "Group added successfully!", "success");
        }

        handleClosePopup(); // Close the popup after success
      } catch (error) {
        console.error("Submission error:", error.response);
        Swal.fire(
          "Error",
          `Failed to ${
            initialData ? "update" : "add"
          } Group. Please try again!`,
          "error"
        );
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <MyCard title="Create Group">
        <div className="mb-5">
          <TextField
            label="Group Name"
            name="group_name"
            value={formik.values.group_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="outlined"
            size="small"
            fullWidth
            error={
              formik.touched.group_name && Boolean(formik.errors.group_name)
            }
            helperText={formik.touched.group_name && formik.errors.group_name}
          />
        </div>
        <div className="border-b border-gray-300 font-semibold mb-2">
          Check In Criteria
        </div>

        <FormControlLabel
          control={
            <Checkbox
              name="enable_office_checkin"
              checked={formik.values.enable_office_checkin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          }
          label="Enable Office Check-In"
        />

        {formik.values.enable_office_checkin && (
          <div className="grid grid-cols-2 gap-4 mt-2">
            {/* Static Time Select */}
            <FormControl fullWidth>
              <InputLabel size="small">Static Time</InputLabel>
              <Select
                name="static_time"
                size="small"
                value={formik.values.static_time}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="">Not Required</MenuItem>
                {[1, 2, 3, 4, 5].map((hour) => (
                  <MenuItem key={hour} value={hour}>
                    {hour} Hours
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Block Outside Premises Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  name="block_outside_premises"
                  checked={formik.values.block_outside_premises}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              }
              label="Block Employee If Outside Premises"
            />
          </div>
        )}

        <FormControlLabel
          control={
            <Checkbox
              name="enable_field_checkin"
              checked={formik.values.enable_field_checkin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          }
          label="Enable Field Check-In"
        />
        {formik.values.enable_field_checkin && (
          <div className="grid grid-cols-2 gap-4 mt-2">
            <FormControl fullWidth>
              <InputLabel size="small">Tracking Frequency</InputLabel>
              <Select
                name="tracking_time"
                size="small"
                value={formik.values.tracking_time}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="">Not Required</MenuItem>
                {[15, 30, 45, 60].map((min) => (
                  <MenuItem key={min} value={min}>
                    {min} Minutes
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel size="small">Track Device Static</InputLabel>
              <Select
                name="field_static_time"
                size="small"
                value={formik.values.field_static_time}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="">Not Required</MenuItem>
                {[1, 2, 3, 4, 5].map((hour) => (
                  <MenuItem key={hour} value={hour}>
                    {hour} Hours
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}

        <FormControlLabel
          control={
            <Checkbox
              name="enable_face_validation"
              checked={formik.values.enable_face_validation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          }
          label="Enable Face Validation"
        />
        {formik.errors.enable_field_checkin &&
          formik.touched.enable_field_checkin && (
            <FormHelperText error>
              {formik.errors.enable_field_checkin}
            </FormHelperText>
          )}
      </MyCard>
      <MyCard title="Attendance Mapping">
        <div className="my-5">
          <Autocomplete
            multiple
            options={shiftOptions}
            getOptionLabel={(option) => {
              // Check if option is an object or ID and find the shift name
              if (typeof option === "object") {
                return option.shift_name; // Option is a shift object
              } else {
                const shift = shiftOptions.find((s) => s.id === option);
                return shift ? shift.shift_name : ""; // If option is an ID
              }
            }}
            value={
              // Map Formik's array of IDs to shift objects
              shiftOptions.filter((shift) =>
                formik.values.shifts.includes(shift.id)
              )
            }
            onChange={(event, newValue) => {
              // Extract and store only the IDs in Formik state
              const selectedIds = newValue.map((shift) => shift.id);
              formik.setFieldValue("shifts", selectedIds);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.id}
                  label={option.shift_name} // Ensure shift name is displayed on the chip
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add Shifts"
                variant="outlined"
                size="small"
                error={formik.touched.shifts && !!formik.errors.shifts}
                helperText={formik.touched.shifts && formik.errors.shifts}
              />
            )}
          />
        </div>

        <div className="my-5">
          <Autocomplete
            multiple
            options={weekoffOptions || []}
            getOptionLabel={(option) => option.weekoffName || ""}
            value={weekoffOptions.filter((weekoff) =>
              (formik.values.week_offs || []).includes(weekoff.id)
            )}
            onChange={(event, newValue) => {
              // Extract and store only the IDs in Formik state
              const selectedIds = newValue.map((weekoff) => weekoff.id);
              formik.setFieldValue("week_offs", selectedIds);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.id} // Make sure to use a unique key here
                  label={option.weekoffName} // Display weekoff name in the chip
                  {...getTagProps({ index })}
                  onDelete={() => {
                    // Remove item from selected values when deleted
                    const newValue = [...value];
                    newValue.splice(index, 1);
                    formik.setFieldValue(
                      "weekoffs",
                      newValue.map((item) => item.id)
                    );
                  }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add Weekoffs"
                variant="outlined"
                size="small"
                error={formik.touched.week_offs && !!formik.errors.week_offs}
                helperText={formik.touched.week_offs && formik.errors.week_offs}
              />
            )}
          />
        </div>

        {/* <div className="my-5">
          <TextField
            label="Add Holiday"
            name="holidayname"
            value={formik.values.holidayname}
            onChange={formik.handleChange}
            size="small"
            fullWidth
          />
        </div> */}
      </MyCard>

      {/* Submit and Cancel Buttons */}
      <div className="flex justify-end gap-4 mt-4">
        <Button onClick={handleClosePopup} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          {initialData ? "Update Group" : "Add Group"}
        </Button>
      </div>
    </form>
  );
};

export default GroupForm;
