import React, { useState, useEffect, useMemo } from "react";
import { MdAdd, MdClose, MdModeEdit, MdCheckCircle } from "react-icons/md";
import MyCard from "../../../components/common/MyCard";
import {
  Button,
  Modal,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import useFetch from "../../../hooks/useFetch";
import axiosInstance from "../../../axios/axiosInstance";

const RoleRight = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [selectedRights, setSelectedRights] = useState({});
  const [roles, setRoles] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRole, setEditingRole] = useState(null);

  const grouprightUrl = useMemo(() => "/meta/group-right", []);
  // const roleRightListUrl = useMemo(() => "/authentication/role-right/list", []);
  const params = useMemo(() => ({}), []);

  const { data: rightsData } = useFetch(grouprightUrl, params);
  // const { data: rolesData } = useFetch(roleRightListUrl, params);

  useEffect(() => {
    fetchRoles(); // Load roles on component mount
  }, []);
  const fetchRoles = async () => {
    try {
      const response = await axiosInstance.get(
        "/authentication/role-right/list"
      );

      const formattedRoles = response.data.data.map((role) => ({
        id: role.id,
        name: role.display_name,
        role_rights: role.role_rights.map((rightCategory) => ({
          display_name: rightCategory.display_name,
          rights: rightCategory.rights.map((right) => ({
            id: right.id,
            right_id: right.right_id,
            display_name: right.display_name,
          })),
        })),
      }));
      setRoles(formattedRoles);
      console.log("formattedRoles", formattedRoles);
    } catch (error) {
      console.error("Failed to fetch roles:", error);
    }
  };

  const handleOpenPopup = (role = null) => {
    if (role) {
      setRoleName(role.name);

      const rightsMap = {};
      role.role_rights.forEach((rightCategory) => {
        rightCategory.rights.forEach((right) => {
          rightsMap[right.right_id] = true;
        });
      });

      setSelectedRights((prev) => ({
        ...prev,
        ...rightsMap,
      }));
      setIsEditing(true);
      setEditingRole(role);
    } else {
      setRoleName("");
      setSelectedRights({});
      setIsEditing(false);
      setEditingRole(null);
    }
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleRightChange = (rightId) => {
    setSelectedRights((prev) => {
      const newState = {
        ...prev,
        [rightId]: !prev[rightId],
      };

      return newState;
    });
  };

  const handleConfirm = async () => {
    const selectedRightsList = Object.entries(selectedRights)
      .filter(([_, isSelected]) => isSelected)
      .map(([rightId]) => parseInt(rightId, 10));

    if (!roleName.trim()) {
      Swal.fire("Error", "Role name is required.", "error");
      return;
    }
    if (selectedRightsList.length === 0) {
      Swal.fire("Error", "Please select at least one right.", "error");
      return;
    }

    try {
      const payload = {
        display_name: roleName,
        rights: selectedRightsList,
      };

      if (isEditing) {
        await axiosInstance.put(`/authentication/role-right/update`, {
          ...payload,
          id: editingRole.id,
        });
        Swal.fire("Updated!", "Role updated successfully.", "success");
      } else {
        await axiosInstance.post(`/authentication/role-right/add`, payload);
        Swal.fire("Added!", "Role added successfully.", "success");
      }

      handleClosePopup();
      await fetchRoles(); // Refresh roles list after adding/editing
    } catch (error) {
      if (error.response) {
        Swal.fire(
          "Error",
          error.response.data?.message || "Something went wrong.",
          "error"
        );
      } else if (error.request) {
        Swal.fire(
          "Error",
          "No response from the server. Please try again.",
          "error"
        );
      } else {
        Swal.fire(
          "Error",
          "An unexpected error occurred. Please try again.",
          "error"
        );
      }
    }
  };

  return (
    <div className="space-y-6">
      {/* Add Role Button */}
      <div className="flex justify-end px-7">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenPopup()}
        >
          <MdAdd className="mr-2" />
          Add Role
        </Button>
      </div>

      {/* Roles Card View */}
      <div className="space-y-4 flex flex-col p-2">
        {roles.map((role) => (
          <MyCard
            key={role.id}
            title={
              <div className="flex items-center justify-between mb-2">
                <h3 className="text-lg font-bold">{role.name}</h3>
                <MdModeEdit
                  className="text-xl cursor-pointer"
                  onClick={() => handleOpenPopup(role)}
                />
              </div>
            }
          >
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-2">
              {role.role_rights.length > 0 &&
                role.role_rights.map((rightCategory) => (
                  <div key={rightCategory.display_name} className="mb-4">
                    <h4 className="font-semibold mb-2">
                      {rightCategory.display_name}
                    </h4>
                    <ul className="pl-4 list-disc">
                      {rightCategory.rights.map((rightItem) => (
                        <li
                          key={rightItem.id}
                          className="flex items-center gap-2 text-green-500"
                        >
                          <MdCheckCircle />
                          <span>{rightItem.display_name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </MyCard>
        ))}
      </div>

      {/* Popup for Role Management */}

      <Dialog
        open={isPopupOpen}
        onClose={handleClosePopup}
        aria-labelledby="modal-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {isEditing ? "Edit Role" : "Add New Role"}
          <IconButton
            aria-label="close"
            onClick={handleClosePopup}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div>
            {" "}
            <input
              type="text"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              placeholder="Enter role name"
              className="w-full p-2 border rounded mb-4"
            />
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
              {rightsData &&
                rightsData.map((category) => (
                  <div key={category.id} className="mb-4">
                    <h3 className="font-semibold mb-2">
                      {category.display_name}
                    </h3>
                    {category.rights.map((right) => (
                      <label
                        key={right.id}
                        className="flex items-center space-x-2"
                      >
                        <input
                          type="checkbox"
                          checked={selectedRights[right.id] || false}
                          onChange={() => handleRightChange(right.id)}
                        />
                        <span>{right.display_name}</span>
                      </label>
                    ))}
                  </div>
                ))}
            </div>
            <div className="flex justify-end mt-6">
              <Button
                onClick={handleConfirm}
                variant="contained"
                color="primary"
              >
                Confirm
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RoleRight;
