import React, { useEffect, useMemo } from "react";
import { TextField, MenuItem, Link, Box, Autocomplete } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import useFetch from "../../hooks/useFetch";
import { ANUALTURNOVER } from "../../utils/constants/dropdown";

const StepTwo = ({ validate, initVals, contactNumber, companyEmail }) => {
  const [stateId, setStateId] = React.useState(null);
  const ownTypeUrl = useMemo(() => "/meta/company-ownership-type", []);
  const stateUrl = useMemo(() => "/meta/state", []);
  const bNatureUrl = useMemo(() => "/meta/company-nature-of-business", []);
  const cityUrl = useMemo(() => "/meta/city", []);
  const params = useMemo(() => ({}), []);
  const params1 = useMemo(() => ({ state: stateId }), [stateId]);
  const { data: ownershiptype } = useFetch(ownTypeUrl, params);
  const { data: state } = useFetch(stateUrl, params);
  const { data: businessNature } = useFetch(bNatureUrl, params);
  const { data: cityList } = useFetch(cityUrl, params1);

  useEffect(() => {
    if (cityList) {
      console.log("dash changes", stateId);
    }
  }, [cityList, stateId]);

  const formik = useFormik({
    initialValues: {
      registered_name: initVals ? initVals["registered_name"] : "",
      establishment_year: initVals ? initVals["establishment_year"] : "",
      ownership_type_id: initVals ? initVals["ownership_type_id"] : "",
      annual_turnover: initVals ? initVals["annual_turnover"] : "",
      company_contact_number: initVals
        ? initVals["company_contact_number"]
        : contactNumber
        ? contactNumber
        : "",
      nature_of_business_id: initVals ? initVals["nature_of_business_id"] : "",
      number_of_branches: initVals ? initVals["number_of_branches"] : "",
      number_of_employees: initVals ? initVals["number_of_employees"] : 0,
      pan: initVals ? initVals["pan"] : "",
      gst: initVals ? initVals["gst"] : "",
      state_id: initVals ? initVals["state_id"] : "",
      city: initVals ? initVals["city"] : "",
      pincode: initVals ? initVals["pincode"] : "",
    },
    validationSchema: Yup.object({
      registered_name: Yup.string().required(
        "Registered Company name is required."
      ),
      company_contact_number: Yup.number().required(
        "Contact number is required."
      ),
      ownership_type_id: Yup.number().required("Ownership type is required."),
      nature_of_business_id: Yup.number().required(
        "Nature of business is required."
      ),
      number_of_branches: Yup.number().required(
        "Number of branches is required."
      ),
      number_of_employees: Yup.number().required(
        "Approximate number of employees is required."
      ),
      gst: Yup.string().required("Company GST is required."),
      state_id: Yup.number().required("State is required."),
      city: Yup.number().required("City is required."),
      pincode: Yup.number().required("Pincode is required."),
    }),
    validateOnChange: true, // Validate on change
    validateOnBlur: true, // Validate on blur
    validateOnMount: true, // Validate on mount
    onSubmit: (values) => {
      // Call the validate function to indicate successful validation
      validate(true, "", values); // Valid form submission
      console.log("Form submitted:", values);
    },
  });

  const handleBlur = (event) => {
    formik.handleBlur(event);
    const isFormValid =
      formik.isValid && Object.keys(formik.errors).length === 0;

    // Call validate function with the current validation state
    validate(
      isFormValid,
      isFormValid ? "" : "Form has error, Please fill all the required fields",
      formik.values
    );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="stretch"
            row
          >
            <Grid item size={6}>
              <TextField
                label="Registered Company Name"
                variant="outlined"
                margin="none"
                fullWidth
                type="text"
                name="registered_name"
                value={formik.values.registered_name}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.registered_name &&
                  Boolean(formik.errors.registered_name)
                }
                helperText={
                  formik.touched.registered_name &&
                  formik.errors.registered_name
                }
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Establishment Year"
                variant="outlined"
                margin="none"
                fullWidth
                type="text"
                name="establishment_year"
                value={formik.values.establishment_year}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.establishment_year &&
                  Boolean(formik.errors.establishment_year)
                }
                helperText={
                  formik.touched.establishment_year &&
                  formik.errors.establishment_year
                }
              />
            </Grid>
            <Grid item size={6}>
              <Autocomplete
                options={ownershiptype || []} // Ensure data is not null or undefined
                getOptionLabel={(option) => option.display_name}
                value={
                  ownershiptype?.find(
                    (item) => item.id === formik.values.ownership_type_id
                  ) || null
                }
                onChange={(event, value) => {
                  formik.setFieldValue(
                    "ownership_type_id",
                    value ? value.id : ""
                  );
                }}
                onBlur={(e) => handleBlur(e)}
                name="ownership_type_id"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ownership Type"
                    variant="outlined"
                    margin="none"
                    error={
                      formik.touched.ownership_type_id &&
                      Boolean(formik.errors.ownership_type_id)
                    }
                    helperText={
                      formik.touched.ownership_type_id &&
                      formik.errors.ownership_type_id
                    }
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item size={6}>
              <Autocomplete
                options={ANUALTURNOVER || []} // Ensure data is not null or undefined
                getOptionLabel={(option) => option.display_name}
                value={
                  ANUALTURNOVER?.find(
                    (item) => item.id === formik.values.annual_turnover
                  ) || null
                }
                onChange={(event, value) => {
                  formik.setFieldValue(
                    "annual_turnover",
                    value ? value.id : ""
                  );
                }}
                onBlur={(e) => handleBlur(e)}
                name="annual_turnover"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Anual Turnover"
                    variant="outlined"
                    margin="none"
                    error={
                      formik.touched.annual_turnover &&
                      Boolean(formik.errors.annual_turnover)
                    }
                    helperText={
                      formik.touched.annual_turnover &&
                      formik.errors.annual_turnover
                    }
                    fullWidth
                    required
                  />
                )}
              />
              {/* <TextField
                label="Anual Turnover"
                variant="outlined"
                margin="none"
                fullWidth
                type="text"
                name="annual_turnover"
                value={formik.values.annual_turnover}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.annual_turnover &&
                  Boolean(formik.errors.annual_turnover)
                }
                helperText={
                  formik.touched.annual_turnover &&
                  formik.errors.annual_turnover
                }
              /> */}
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Mobile Number"
                variant="outlined"
                margin="none"
                fullWidth
                type="tel"
                name="company_contact_number"
                value={formik.values.company_contact_number}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.company_contact_number &&
                  Boolean(formik.errors.company_contact_number)
                }
                helperText={
                  formik.touched.company_contact_number &&
                  formik.errors.company_contact_number
                }
              />
            </Grid>
            <Grid item size={6}>
              <Autocomplete
                options={businessNature || []} // Ensure data is not null or undefined
                getOptionLabel={(option) => option.display_name}
                value={
                  businessNature?.find(
                    (item) => item.id === formik.values.nature_of_business_id
                  ) || null
                }
                onChange={(event, value) => {
                  formik.setFieldValue(
                    "nature_of_business_id",
                    value ? value.id : ""
                  ); // Set the selected country code
                }}
                onBlur={(e) => handleBlur(e)}
                name="nature_of_business_id"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nature of Business"
                    variant="outlined"
                    margin="none"
                    error={
                      formik.touched.nature_of_business_id &&
                      Boolean(formik.errors.nature_of_business_id)
                    }
                    helperText={
                      formik.touched.nature_of_business_id &&
                      formik.errors.nature_of_business_id
                    }
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Number of Branches"
                variant="outlined"
                margin="none"
                fullWidth
                type="number"
                name="number_of_branches"
                required
                value={formik.values.number_of_branches}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.number_of_branches &&
                  Boolean(formik.errors.number_of_branches)
                }
                helperText={
                  formik.touched.number_of_branches &&
                  formik.errors.number_of_branches
                }
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Number of Employees"
                variant="outlined"
                margin="none"
                fullWidth
                required
                type="number"
                name="number_of_employees"
                value={formik.values.number_of_employees}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.number_of_employees &&
                  Boolean(formik.errors.number_of_employees)
                }
                helperText={
                  formik.touched.number_of_employees &&
                  formik.errors.number_of_employees
                }
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Company PAN"
                variant="outlined"
                margin="none"
                fullWidth
                type="text"
                name="pan"
                value={formik.values.pan}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={formik.touched.pan && Boolean(formik.errors.pan)}
                helperText={formik.touched.pan && formik.errors.pan}
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Company GST"
                variant="outlined"
                margin="none"
                fullWidth
                type="text"
                name="gst"
                required
                value={formik.values.gst}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={formik.touched.gst && Boolean(formik.errors.gst)}
                helperText={formik.touched.gst && formik.errors.gst}
              />
            </Grid>
            <Grid item size={6}>
              <Autocomplete
                options={state || []} // Ensure data is not null or undefined
                getOptionLabel={(option) => option.display_name}
                value={
                  state?.find((item) => item.id === formik.values.state_id) ||
                  null
                }
                onChange={(event, value) => {
                  formik.setFieldValue("state_id", value ? value.id : "");
                  setStateId(value.id);
                }}
                onBlur={(e) => handleBlur(e)}
                name="state_id"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State"
                    variant="outlined"
                    margin="none"
                    error={
                      formik.touched.state_id && Boolean(formik.errors.state_id)
                    }
                    helperText={
                      formik.touched.state_id && formik.errors.state_id
                    }
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item size={6}>
              <Autocomplete
                options={cityList || []} // Ensure data is not null or undefined
                getOptionLabel={(option) => option.display_name}
                value={
                  cityList?.find((item) => item.id === formik.values.city) ||
                  null
                }
                onChange={(event, value) => {
                  formik.setFieldValue("city", value ? value.id : ""); // Set the selected country code
                }}
                onBlur={(e) => handleBlur(e)}
                name="city"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="City"
                    variant="outlined"
                    margin="none"
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                    fullWidth
                    required
                  />
                )}
              />
              {/* <TextField
                label="City"
                variant="outlined"
                margin="none"
                fullWidth
                required
                type="text"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              /> */}
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Pincode"
                variant="outlined"
                margin="none"
                fullWidth
                required
                type="number"
                name="pincode"
                value={formik.values.pincode}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={formik.touched.pincode && Boolean(formik.errors.pincode)}
                helperText={formik.touched.pincode && formik.errors.pincode}
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
};

export default StepTwo;
