import React, { useMemo } from "react";
import { TextField, MenuItem, Link, Box, Autocomplete } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useFetch from "../../hooks/useFetch";
import moment from "moment";
import {
  gender,
  bloodGroup,
  maritalStatus,
} from "../../utils/constants/dropdown";

const StepThree = ({ validate, initVals, contactNumber, companyEmail }) => {
  const qUrl = useMemo(() => "/meta/qualification", []);
  const params = useMemo(() => ({}), []);
  const { data: qualifications } = useFetch(qUrl, params);

  const today = moment().startOf("day"); // Current date without time
  const minAge = 18;
  const minDate = today.subtract(minAge, "years"); // Minimum date to be 18 years ago

  const formik = useFormik({
    initialValues: {
      admin_name: initVals ? initVals["admin_name"] : "",
      admin_contact_number: initVals
        ? initVals["admin_contact_number"]
        : contactNumber
        ? contactNumber
        : "",
      admin_gender:
        initVals && typeof initVals === "object" && "admin_gender" in initVals
          ? initVals["admin_gender"]
          : "",
      admin_email: initVals
        ? initVals["admin_email"]
        : companyEmail
        ? companyEmail
        : "",
      admin_blood_group: initVals?.admin_blood_group || "",
      admin_date_of_birth: initVals ? initVals["admin_date_of_birth"] : "",
      admin_residence_address: initVals
        ? initVals["admin_residence_address"]
        : "",
      admin_residence_pincode: initVals
        ? initVals["admin_residence_pincode"]
        : "",
      admin_pan: initVals ? initVals["admin_pan"] : "",
      admin_aadhaar_number: initVals ? initVals["admin_aadhaar_number"] : "",
      admin_uan: initVals ? initVals["admin_uan"] : "",
      admin_marital_status: initVals?.admin_marital_status || "",
      admin_qualification_id: initVals?.admin_qualification_id || "",
      admin_designation: initVals ? initVals["admin_designation"] : "",
      admin_department: initVals ? initVals["admin_department"] : "",
      admin_employee_number: initVals ? initVals["admin_employee_number"] : "",
      admin_working_location: initVals
        ? initVals["admin_working_location"]
        : "",
      admin_date_of_joining: initVals ? initVals["admin_date_of_joining"] : "",
      admin_klockmate_referred_by: initVals
        ? initVals["admin_klockmate_referred_by"]
        : "",
    },
    validationSchema: Yup.object({
      admin_name: Yup.string().required("Name is required."),
      admin_contact_number: Yup.number().required(
        "Contact number is required."
      ),
      admin_gender: Yup.string().required("Gender is required."),
      admin_email: Yup.string().required("Email is required."),
      admin_qualification_id: Yup.number().required(
        "Qualification is required."
      ),
      admin_designation: Yup.string().required("Designation is required."),
      admin_department: Yup.string().required("Department is required."),
      admin_employee_number: Yup.string().required(
        "Employee Number is required."
      ),
      admin_working_location: Yup.string().required(
        "Working location is required."
      ),
      admin_date_of_birth: Yup.date()
        .required("Date of Birth is required")
        .max(today, "Date of Birth cannot be in the future")
        .test("age", "You must be at least 18 years old", (value) => {
          if (!value) return false;
          const age = moment().diff(moment(value), "years");
          return age >= 18;
        }),
    }),
    validateOnChange: true, // Validate on change
    validateOnBlur: true, // Validate on blur
    validateOnMount: true, // Validate on mount
    onSubmit: (values) => {
      // Call the validate function to indicate successful validation
      validate(true, "", values); // Valid form submission
      console.log("Form submitted:", values);
    },
  });

  const handleBlur = (event) => {
    formik.handleBlur(event);
    const isFormValid =
      formik.isValid && Object.keys(formik.errors).length === 0;
    console.log(formik.errors);
    // Call validate function with the current validation state
    validate(
      isFormValid,
      isFormValid ? "" : "Form has error, Please fill all the required fields",
      formik.values
    );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="stretch"
            row
          >
            <Grid item size={6}>
              <TextField
                label="Name"
                variant="outlined"
                margin="none"
                fullWidth
                required
                type="text"
                name="admin_name"
                value={formik.values.admin_name}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.admin_name && Boolean(formik.errors.admin_name)
                }
                helperText={
                  formik.touched.admin_name && formik.errors.admin_name
                }
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Mobile Number"
                variant="outlined"
                margin="none"
                fullWidth
                required
                type="number"
                name="admin_contact_number"
                value={formik.values.admin_contact_number}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.admin_contact_number &&
                  Boolean(formik.errors.admin_contact_number)
                }
                helperText={
                  formik.touched.admin_contact_number &&
                  formik.errors.admin_contact_number
                }
              />
            </Grid>
            <Grid item size={6}>
              <Autocomplete
                options={gender || []} // Ensure data is not null or undefined
                getOptionLabel={(option) => option.label}
                value={
                  gender?.find(
                    (item) => item.id === formik.values.admin_gender
                  ) || null
                }
                onChange={(event, value) => {
                  formik.setFieldValue("admin_gender", value ? value.id : ""); // Set the selected country code
                }}
                onBlur={(e) => handleBlur(e)}
                name="admin_gender"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Gender"
                    variant="outlined"
                    margin="none"
                    error={
                      formik.touched.admin_gender &&
                      Boolean(formik.errors.admin_gender)
                    }
                    helperText={
                      formik.touched.admin_gender && formik.errors.admin_gender
                    }
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Email"
                variant="outlined"
                margin="none"
                fullWidth
                type="text"
                required
                name="admin_email"
                value={formik.values.admin_email}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.admin_email &&
                  Boolean(formik.errors.admin_email)
                }
                helperText={
                  formik.touched.admin_email && formik.errors.admin_email
                }
              />
            </Grid>
            <Grid item size={6}>
              <Autocomplete
                options={bloodGroup || []} // Ensure data is not null or undefined
                getOptionLabel={(option) => option.label}
                value={
                  bloodGroup?.find(
                    (item) => item.id === formik.values.admin_blood_group
                  ) || null
                }
                onChange={(event, value) => {
                  formik.setFieldValue(
                    "admin_blood_group",
                    value ? value.id : ""
                  ); // Set the selected country code
                }}
                onBlur={(e) => handleBlur(e)}
                name="admin_blood_group"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Blood Group"
                    variant="outlined"
                    margin="none"
                    error={
                      formik.touched.admin_blood_group &&
                      Boolean(formik.errors.admin_blood_group)
                    }
                    helperText={
                      formik.touched.admin_blood_group &&
                      formik.errors.admin_blood_group
                    }
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
            <Grid item size={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  name="admin_date_of_birth"
                  label="Date of Birth"
                  maxDate={today} // Disallow future dates
                  value={
                    formik.values.admin_date_of_birth
                      ? moment(formik.values.admin_date_of_birth, "YYYY-MM-DD")
                      : null
                  }
                  onChange={(date) => {
                    // Update Formik field with date in YYYY-MM-DD format
                    formik.setFieldValue(
                      "admin_date_of_birth",
                      moment(date).format("YYYY-MM-DD")
                    );
                  }}
                  onBlur={(e) => handleBlur(e)}
                  slotProps={{
                    textField: {
                      error:
                        formik.touched.admin_date_of_birth &&
                        Boolean(formik.errors.admin_date_of_birth),
                      helperText:
                        formik.touched.admin_date_of_birth &&
                        formik.errors.admin_date_of_birth,
                      fullWidth: true,
                      margin: "none",
                      variant: "outlined",
                    },
                  }}
                  error={
                    formik.touched.admin_date_of_birth &&
                    Boolean(formik.errors.admin_date_of_birth)
                  }
                  helperText={
                    formik.touched.admin_date_of_birth &&
                    formik.errors.admin_date_of_birth
                  }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Residence Address"
                variant="outlined"
                margin="none"
                fullWidth
                name="admin_residence_address"
                value={formik.values.admin_residence_address}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.admin_residence_address &&
                  Boolean(formik.errors.admin_residence_address)
                }
                helperText={
                  formik.touched.admin_residence_address &&
                  formik.errors.admin_residence_address
                }
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Residence Pincode"
                variant="outlined"
                margin="none"
                fullWidth
                type="number"
                name="admin_residence_pincode"
                value={formik.values.admin_residence_pincode}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.admin_residence_pincode &&
                  Boolean(formik.errors.admin_residence_pincode)
                }
                helperText={
                  formik.touched.admin_residence_pincode &&
                  formik.errors.admin_residence_pincode
                }
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="PAN"
                variant="outlined"
                margin="none"
                fullWidth
                type="text"
                name="admin_pan"
                value={formik.values.admin_pan}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.admin_pan && Boolean(formik.errors.admin_pan)
                }
                helperText={formik.touched.admin_pan && formik.errors.admin_pan}
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Adhaar"
                variant="outlined"
                margin="none"
                fullWidth
                type="text"
                name="admin_aadhaar_number"
                value={formik.values.admin_aadhaar_number}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.admin_aadhaar_number &&
                  Boolean(formik.errors.admin_aadhaar_number)
                }
                helperText={
                  formik.touched.admin_aadhaar_number &&
                  formik.errors.admin_aadhaar_number
                }
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="UAN"
                variant="outlined"
                margin="none"
                fullWidth
                type="text"
                name="admin_uan"
                value={formik.values.admin_uan}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.admin_uan && Boolean(formik.errors.admin_uan)
                }
                helperText={formik.touched.admin_uan && formik.errors.admin_uan}
              />
            </Grid>
            <Grid item size={6}>
              <Autocomplete
                options={maritalStatus || []} // Ensure data is not null or undefined
                getOptionLabel={(option) => option.label}
                value={
                  maritalStatus?.find(
                    (item) => item.id === formik.values.admin_marital_status
                  ) || null
                }
                onChange={(event, value) => {
                  formik.setFieldValue(
                    "admin_marital_status",
                    value ? value.id : ""
                  ); // Set the selected country code
                }}
                onBlur={(e) => handleBlur(e)}
                name="admin_marital_status"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Marital Status"
                    variant="outlined"
                    margin="none"
                    error={
                      formik.touched.admin_marital_status &&
                      Boolean(formik.errors.admin_marital_status)
                    }
                    helperText={
                      formik.touched.admin_marital_status &&
                      formik.errors.admin_marital_status
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item size={6}>
              <Autocomplete
                options={qualifications || []} // Ensure data is not null or undefined
                getOptionLabel={(option) => option.display_name}
                onChange={(event, value) => {
                  formik.setFieldValue(
                    "admin_qualification_id",
                    value ? value.id : ""
                  ); // Set the selected country code
                }}
                onBlur={(e) => handleBlur(e)}
                name="admin_qualification_id"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Qualification"
                    variant="outlined"
                    margin="none"
                    error={
                      formik.touched.admin_qualification_id &&
                      Boolean(formik.errors.admin_qualification_id)
                    }
                    helperText={
                      formik.touched.admin_qualification_id &&
                      formik.errors.admin_qualification_id
                    }
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Designation"
                variant="outlined"
                margin="none"
                fullWidth
                required
                name="admin_designation"
                value={formik.values.admin_designation}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.admin_designation &&
                  Boolean(formik.errors.admin_designation)
                }
                helperText={
                  formik.touched.admin_designation &&
                  formik.errors.admin_designation
                }
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Department"
                variant="outlined"
                margin="none"
                fullWidth
                required
                name="admin_department"
                value={formik.values.admin_department}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.admin_department &&
                  Boolean(formik.errors.admin_department)
                }
                helperText={
                  formik.touched.admin_department &&
                  formik.errors.admin_department
                }
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Employee Number"
                variant="outlined"
                margin="none"
                fullWidth
                required
                name="admin_employee_number"
                value={formik.values.admin_employee_number}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.admin_employee_number &&
                  Boolean(formik.errors.admin_employee_number)
                }
                helperText={
                  formik.touched.admin_employee_number &&
                  formik.errors.admin_employee_number
                }
              />
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Working Location Name"
                variant="outlined"
                margin="none"
                fullWidth
                required
                name="admin_working_location"
                value={formik.values.admin_working_location}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.admin_working_location &&
                  Boolean(formik.errors.admin_working_location)
                }
                helperText={
                  formik.touched.admin_working_location &&
                  formik.errors.admin_working_location
                }
              />
            </Grid>
            <Grid item size={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  name="admin_date_of_joining"
                  label="Date of Joining"
                  maxDate={today}
                  value={
                    formik.values.admin_date_of_joining
                      ? moment(
                          formik.values.admin_date_of_joining,
                          "YYYY-MM-DD"
                        )
                      : null
                  }
                  onChange={(date) => {
                    // Update Formik field with date in YYYY-MM-DD format
                    formik.setFieldValue(
                      "admin_date_of_joining",
                      moment(date).format("YYYY-MM-DD")
                    );
                  }}
                  onBlur={(e) => handleBlur(e)}
                  slotProps={{
                    textField: {
                      error:
                        formik.touched.admin_date_of_joining &&
                        Boolean(formik.errors.admin_date_of_joining),
                      helperText:
                        formik.touched.admin_date_of_joining &&
                        formik.errors.admin_date_of_joining,
                      fullWidth: true,
                      margin: "none",
                      variant: "outlined",
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item size={6}>
              <TextField
                label="Klockmate Referred By"
                variant="outlined"
                margin="none"
                fullWidth
                name="admin_klockmate_referred_by"
                value={formik.values.admin_klockmate_referred_by}
                onChange={formik.handleChange}
                onBlur={(e) => handleBlur(e)} // Use custom handleBlur
                error={
                  formik.touched.admin_klockmate_referred_by &&
                  Boolean(formik.errors.admin_klockmate_referred_by)
                }
                helperText={
                  formik.touched.admin_klockmate_referred_by &&
                  formik.errors.admin_klockmate_referred_by
                }
              />
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
};

export default StepThree;
