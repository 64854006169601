import React, { useState } from "react";
import { Button, Radio } from "@mui/material";
import MyCard from "../../../components/common/MyCard";
import defaultLogo from "../../../assets/klockmatehome.png";
import Swal from "sweetalert2";
import axiosInstance from "../../../axios/axiosInstance";

const Branding = () => {
  const [selectedImage, setSelectedImage] = useState(null); // Preview of custom logo
  const [selectedFile, setSelectedFile] = useState(null); // Actual file to send
  const [selectedLogo, setSelectedLogo] = useState("default"); // "default" or "custom"

  // Handle image selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setSelectedFile(file); // Store the file for upload
      setSelectedLogo("custom"); // Automatically select the custom logo
    }
  };

  // Handle logo selection via radio buttons
  const handleLogoSelection = (event) => {
    const value = event.target.value;
    setSelectedLogo(value);
    if (value === "default") {
      setSelectedImage(null);
      setSelectedFile(null);
    }
  };

  // Submit the selected logo
  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      if (selectedLogo === "custom" && selectedFile) {
        formData.append("logo", selectedFile);
      } else {
        formData.append("logo", "default");
      }

      await axiosInstance.patch("/company/branding", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      Swal.fire("Success", "Logo updated successfully!", "success");
    } catch (error) {
      console.error("Error uploading logo:", error.response || error.message);
      Swal.fire(
        "Error",
        error.response?.data?.message || "Failed to update logo",
        "error"
      );
    }
  };

  return (
    <div className="p-4">
      <MyCard title={"Branding Logo"} width="full">
        <div className="flex flex-row gap-20 justify-center">
          {/* Default Logo Section */}
          <div className="flex flex-col items-center gap-4">
            <label className="flex flex-row items-center cursor-pointer">
              <Radio
                checked={selectedLogo === "default"}
                onChange={handleLogoSelection}
                value="default"
                color="primary"
              />
              <h3 className="text-lg font-medium">Default Logo</h3>
            </label>
            <div className="w-40 h-40 border border-gray-300 flex items-center justify-center">
              <img
                src={defaultLogo}
                alt="Default Logo"
                className="w-full h-full object-contain"
              />
            </div>
          </div>

          {/* Custom Logo Section */}
          <div className="flex flex-col items-center gap-4">
            <label className="flex flex-row items-center cursor-pointer">
              <Radio
                checked={selectedLogo === "custom"}
                onChange={handleLogoSelection}
                value="custom"
                color="primary"
              />
              <h3 className="text-lg font-medium">Custom Logo</h3>
            </label>
            <div className="w-40 h-40 border border-gray-300 flex items-center justify-center">
              {selectedImage ? (
                <img
                  src={selectedImage}
                  alt="Custom Logo"
                  className="w-full h-full object-contain"
                />
              ) : (
                <span className="text-md text-gray-500 text-center">
                  Choose your own logo below
                </span>
              )}
            </div>
            <div className="flex items-center">
              <input
                id="custom-logo"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              <label htmlFor="custom-logo">
                <Button variant="contained" color="primary" component="span">
                  Choose Logo
                </Button>
              </label>
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end mt-4">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={selectedLogo === "custom" && !selectedFile}
          >
            Save Changes
          </Button>
        </div>
      </MyCard>
    </div>
  );
};

export default Branding;
