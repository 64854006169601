import React, { createContext, lazy, useMemo } from "react";
import { MyRoutes } from "./router";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SignIn } from "./pages/signin";
import Register from "./pages/register/Register";
// import Header from "./pages/header";
import MyDrawer from "./components/layout/MyDrawer";
import AuthContextProvider from "../src/context/auth/authProvider";
import PrivateRouter from "./utils/PrivateRouter";
import MainLayout from "./components/layout";
import SuspenseLoader from "./utils/SuspenseLoader";
import { createTheme, ThemeProvider } from "@mui/material";
import { connect } from "react-redux";
import PricingCard from "./pricing/PricingCard";
import HolidayConfiguration from "./pages/holiday/HolidayConfiguration";
const PreRegister = lazy(() => import("./pages/register/PreRegister"));
const LandingPage = lazy(() => import("./pages/landing/LandingPage"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Stats = lazy(() => import("./pages/stats/Stats"));
const Branch = lazy(() => import("./pages/branch/Branch"));
const RegisteredUsers = lazy(() => import("./pages/admin/RegisteredUsers"));
const Configuration = lazy(() => import("./pages/configuration/Configuration"));
const EmployeeListing = lazy(() => import("./pages/employee/EmployeeListing"));
const AddEmployee = lazy(() => import("./pages/employee/AddEmployee"));
const MyContext = createContext();

const Layout = () => (
  <section className="main flex">
    <div>
      <MyDrawer />
    </div>
    <div component="main" sx={{ flexGrow: 1, p: 3 }} className="w-[100%]">
      <div className="space"></div>
      <MyRoutes />
    </div>
  </section>
);

function App({ reduxTheme }) {
  console.log("reduxTheme", reduxTheme);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: reduxTheme,
        },
      }),
    [reduxTheme]
  );
  const values = {};

  return (
    <>
      <AuthContextProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <React.Suspense fallback={<SuspenseLoader />}>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/preregister" element={<PreRegister />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/register" element={<Register />} />
                <Route element={<PrivateRouter />}>
                  <Route element={<MainLayout />}>
                    <Route path="/app/dashboard" element={<Dashboard />} />
                    <Route path="/app/stats" element={<Stats />} />
                    <Route path="/app/employee" element={<EmployeeListing />} />
                    <Route
                      path="/app/employee/addemployee"
                      element={<AddEmployee />}
                    />
                    <Route
                      path="/app/organization/branches"
                      element={<Branch />}
                    />
                    <Route
                      path="/app/organization/configuration"
                      element={<Configuration />}
                    />
                    <Route
                      path="/app/organization/holidays"
                      element={<HolidayConfiguration />}
                    />
                    <Route
                      path="/app/reg-companies"
                      element={<RegisteredUsers />}
                    />
                    <Route path="/app/pricing" element={<PricingCard />} />
                  </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </React.Suspense>
          </BrowserRouter>
        </ThemeProvider>
      </AuthContextProvider>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    reduxTheme: state.theme,
  };
};

export default connect(mapStateToProps)(App);
