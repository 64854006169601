import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { IoMenuSharp } from "react-icons/io5";
import { AiOutlineMenuFold } from "react-icons/ai";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import brand from "../../assets/images/customcolor_logo_transparent_background.png";
import { IoSettingsOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { AiOutlineDashboard, AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import Header from "./Header";
import { FaBuildingColumns, FaUsers } from "react-icons/fa6";
import { BsBuildingCheck } from "react-icons/bs";
import { GiPriceTag } from "react-icons/gi";
import { loadState } from "../../local-storage";
import menuItems from "./menuItems";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // backgroundColor: "#fff",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

export default function MiniDrawer({ handleDrawerToggle }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openSubmenuId, setOpenSubmenuId] = React.useState(null);
  const [selectedRoute, setSelectedRoute] = React.useState("/dashboard");
  const [employeeRole, setEmployeeRole] = React.useState(null);
  const [isCompanyAdmin, setIsCompanyAdmin] = React.useState(false);

  React.useEffect(() => {
    const localStorateData = loadState();

    if (localStorateData && localStorateData.userData) {
      setEmployeeRole(localStorateData.userData.employee_role.id);
      setIsCompanyAdmin(localStorateData.userData.is_company_admin);
    }
  }, []);

  // Filter menu items based on employeeRole and isCompanyAdmin
  const filteredMenuItems = menuItems.filter((item) => {
    const hasAccess = item.access_roles.includes(employeeRole);
    // if (isCompanyAdmin && [2, 3, 4, 5, 6, 7].includes(item.id)) {
    //   // alert(isCompanyAdmin);
    //   return hasAccess || isCompanyAdmin;
    // }

    return hasAccess;
  });

  const handleDrawerOpen = () => {
    handleDrawerToggle();
    setOpen(true);
  };

  const handleDrawerClose = () => {
    handleDrawerToggle();
    setOpen(false);
  };

  const handleMenuItemClick = (route) => {
    setSelectedRoute(route);
    navigate(route); // Navigate to the specified route
  };

  const handleMenuClick = (id) => {
    // Toggle submenu based on the clicked menu item
    setOpenSubmenuId(openSubmenuId === id ? null : id);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: "#fff" }}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[{ marginRight: 5 }, open && { display: "none" }]}
          >
            <IoMenuSharp />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div" color="#000">
            Klockmate
          </Typography> */}
          <Header />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img
            src={brand}
            alt="Brand Logo"
            style={{
              width: "70%",
              height: "10%",
              display: "block",
              position: "absolute",
              marginRight: "20%",
            }}
          />
          <IconButton onClick={handleDrawerClose}>
            <AiOutlineMenuFold />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          sx={{ backgroundColor: "#003296", color: "#fff", height: "100%" }}
        >
          {filteredMenuItems.map((item) => (
            <React.Fragment key={item.id}>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  onClick={() => {
                    item.submenu
                      ? handleMenuClick(item.id)
                      : handleMenuItemClick(item.route);
                  }}
                  sx={[
                    { minHeight: 48, px: 2.5 },
                    selectedRoute === item.route ||
                    (item.submenu && openSubmenuId === item.id)
                      ? { backgroundColor: "rgba(255, 255, 255, 0.2)" } // Highlight background
                      : open
                      ? { justifyContent: "initial" }
                      : { justifyContent: "center" },
                  ]}
                >
                  <ListItemIcon
                    sx={[
                      { minWidth: 0, justifyContent: "center" },
                      open ? { mr: 2 } : { mr: "auto" },
                    ]}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    sx={[open ? { opacity: 1 } : { opacity: 0 }]}
                  />
                  {item.submenu && (
                    <span>
                      {openSubmenuId === item.id ? (
                        <IoIosArrowUp color="#fff" />
                      ) : (
                        <IoIosArrowDown color="#fff" />
                      )}
                    </span>
                  )}
                </ListItemButton>
              </ListItem>
              {item.submenu && openSubmenuId === item.id && (
                <List component="div" disablePadding>
                  {item.submenu.map((subItem) => (
                    <ListItem key={subItem.id} disablePadding>
                      <ListItemButton
                        onClick={() => handleMenuItemClick(subItem.route)}
                        sx={[
                          { minHeight: 48, px: 2.5 },
                          selectedRoute === subItem.route
                            ? {
                                backgroundColor: "rgba(255, 255, 255, 0.2)",
                                justifyContent: "initial",
                                pl: 8,
                              } // Highlight background for subitem
                            : open
                            ? { justifyContent: "initial", pl: 8 }
                            : { justifyContent: "center" },
                          // open
                          //   ? { justifyContent: "initial", pl: 8 }
                          //   : { justifyContent: "center" },
                        ]}
                      >
                        <ListItemText
                          primary={subItem.title}
                          sx={[open ? { opacity: 1 } : { opacity: 0 }]}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </React.Fragment>
          ))}
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}
