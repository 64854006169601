import React, { useState, useEffect, useMemo } from "react";
import MyCard from "../../components/common/MyCard";
import {
  TextField,
  Button,
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  gender,
  bloodGroup,
  employmentType,
  maritalStatus,
} from "../../utils/constants/dropdown";
import moment from "moment";
import {
  LocalizationProvider,
  DesktopDatePicker,
  DatePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useFetch from "../../hooks/useFetch";
import axiosInstance from "../../axios/axiosInstance";
import Swal from "sweetalert2";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AddAgency from "./AddAgency";

const AddEmployee = ({ isModalOpen, employeeData = {} }) => {
  const deptUrl = useMemo(() => "/company/departments/list", []);
  const qualificationUrl = useMemo(() => "/meta/qualification", []);
  const branchUrl = useMemo(() => "/company/branch/list", []);
  const desgnUrl = useMemo(() => "/company/designation/list", []);
  const roleRightUrl = useMemo(() => "/authentication/role-right/list", []);
  const agencyUrl = useMemo(() => "/employee/employee-agency/list", []);
  const groupUrl = useMemo(() => "/general-config/group", []);
  const holidayGroupUrl = useMemo(() => "/company/holiday-group", []);
  const params = useMemo(() => ({}), []);
  const [falttenBranchList, setFlattenBranchList] = useState([]);
  const getEmployeeUrl = useMemo(() => "/employee/company-employees", []);

  const [addAgencyOpen, setAddAgencyOpen] = useState(false);
  const { data: employeesList, loading } = useFetch(getEmployeeUrl, params);
  const { data: qualifications } = useFetch(qualificationUrl, params);
  const { data: departmentsList } = useFetch(deptUrl, params);
  const { data: branchesList } = useFetch(branchUrl, params);
  const { data: designationList } = useFetch(desgnUrl, params);
  const { data: roleRights } = useFetch(roleRightUrl, params);
  // const { data: agencyList } = useFetch(agencyUrl, params);
  const { data: groups } = useFetch(groupUrl, params);
  const { data: holidays } = useFetch(holidayGroupUrl, params);
  const [agencyList, setAgencyList] = useState([]);
  const fetchAgencyList = async () => {
    try {
      const response = await axiosInstance.get(agencyUrl);
      setAgencyList(response.data.data);
    } catch (err) {
      console.error("Failed to fetch agency list:", err);
    }
  };
  const flattenBranches = (branches, parent = "") => {
    let flatList = [];
    branches?.forEach((branch) => {
      // Create a label for the current branch (you can customize this)
      const label = parent
        ? `${parent} -> ${branch.branch_name}`
        : branch.branch_name;

      // Add the current branch to the flat list
      flatList.push({
        id: branch.id,
        display_name: branch.branch_name,
        label: label,
        parent: parent, // Optional: store parent for later reference
        sub_branches: branch.sub_branches,
      });

      // If there are sub-branches, recurse and flatten them as well
      if (branch.sub_branches && branch.sub_branches.length > 0) {
        flatList = flatList.concat(flattenBranches(branch.sub_branches, label));
      }
    });

    return flatList;
  };
  useEffect(() => {
    fetchAgencyList();
  }, []);
  useEffect(() => {
    if (Array.isArray(branchesList)) {
      const flatBranchData = flattenBranches(branchesList);
      setFlattenBranchList(flatBranchData);
    }

    if (employeeData && Object.keys(employeeData).length > 0) {
      formik.setValues({
        full_name: employeeData.full_name,
        contact_number: employeeData.contact_number,
        email: employeeData.email,
        employee_number: employeeData.employee_number,
        company_designation_id: employeeData?.company_designation?.id,
        company_department_id: employeeData?.company_department?.id,
        company_branch_id: employeeData?.company_branch?.id,
        employment_category: employeeData.employment_category,
        date_of_birth: formatDate(employeeData.date_of_birth),
        date_of_joining: formatDate(employeeData.date_of_joining),
        company_role_id: employeeData.company_role.id,
        company_agency_id: employeeData.company_agency_id,
        blood_group: employeeData.blood_group,
        gender: employeeData.gender,
        residence_address: employeeData.residence_address,
        residence_pincode: employeeData.residence_pincode,
        pan: employeeData.pan,
        aadhaar_number: employeeData.aadhaar_number,
        uan: employeeData.uan,
        marital_status: employeeData.marital_status,
        qualification_id: employeeData?.qualification?.id,
        group_id: employeeData?.group?.id,
        holiday_group: employeeData.holiday_group,
        approver_1: employeeData?.approver_1?.id,
        approver_2: employeeData?.approver_2?.id,
        approver_3: employeeData?.approver_3?.id,
        id: employeeData.id || null,
      });
    }
  }, [branchesList, employeeData]);

  const formik = useFormik({
    initialValues: {
      full_name: "",
      contact_number: "",
      email: "",
      employee_number: "",
      company_designation_id: "",
      company_department_id: "",
      company_branch_id: "",
      employment_category: "",
      date_of_joining: "",
      company_role_id: "",
      company_agency_id: "",
      blood_group: "",
      gender: "",
      date_of_birth: "",
      residence_address: "",
      residence_pincode: "",
      pan: "",
      aadhaar_number: "",
      uan: "",
      marital_status: "",
      qualification_id: "",
      group_id: "",
      holiday_group: "",
      approver_1: null,
      approver_2: null,
      approver_3: null,
    },
    validationSchema: Yup.object({
      full_name: Yup.string().required("Name is required."),
      contact_number: Yup.number().required("Contact number is required."),
      gender: Yup.string().required("Gender is required."),
      company_branch_id: Yup.number().required("Branch is required."),
      company_department_id: Yup.number().required("Department is required."),
      company_designation_id: Yup.number().required("Designation is required."),
      residence_pincode: Yup.number().required("Pincode is required."),
      qualification_id: Yup.number().required("Qualification is required."),
      employment_category: Yup.string().required(
        "Employment Type is required."
      ),
    }),
    onSubmit: (values) => {
      handleSaveEmployee(values);
    },
  });

  const formatDate = (date) =>
    moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : null;

  const handleSaveEmployee = async (formData) => {
    const employeePayload = {
      ...formData,
      date_of_birth: formatDate(formData.date_of_birth),
      date_of_joining: formatDate(formData.date_of_joining),
    };

    try {
      if (employeeData && Object.keys(employeeData).length > 0) {
        const response = await axiosInstance.put(
          `/employee/company-employees/${employeeData.id}`,
          employeePayload
        );
        if (response.data.status === 200 || response.data.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Employee Details updated successfully!",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          }).then(() => {
            isModalOpen(false);
          });
        }
      } else {
        const response = await axiosInstance.post(
          "/employee/company-employees",
          employeePayload
        );
        if (response.data.status === 200 || response.data.status === 201) {
          Swal.fire({
            title: "success",
            text: "Employee Details added successfully.!",
            icon: "success",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          }).then(() => {
            isModalOpen(false);
          });
        } else {
          Swal.fire({
            title: "error",
            text: "Something went wrong",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          });
        }
      }
    } catch (err) {
      console.log(err.response.data.error);
      const dash = getErrorMessage(err.response.data.error);
      Swal.fire({
        title: "error",
        text: dash,
        icon: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  };

  const getFilteredOptions = (stage) => {
    const selectedIds = [
      formik.values.approver_1,
      formik.values.approver_2,
    ].slice(0, stage - 1); // Only consider stages before the current one

    return (employeesList || []).filter(
      (employee) =>
        !selectedIds.includes(employee.id) || // Exclude already selected employees
        employee.id === formik.values[`approver_${stage}`] // Include current stage's employee if selected
    );
  };
  return (
    <>
      <Dialog open={true} maxWidth="lg" onClose={() => isModalOpen(false)}>
        <DialogTitle>
          {employeeData && Object.keys(employeeData).length > 0
            ? "Update Employee"
            : "Add Employee"}
          <IconButton
            aria-label="close"
            onClick={() => isModalOpen(false)}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={formik.handleSubmit}>
            <MyCard title="Personal Information" width="full">
              <Box>
                <Grid container spacing={2} row>
                  <Grid item size={4}>
                    <TextField
                      label="Full Name"
                      name="full_name"
                      value={formik.values.full_name}
                      onChange={formik.handleChange}
                      onBlur={(e) => formik.handleBlur(e)}
                      variant="outlined"
                      fullWidth
                      required
                      error={
                        formik.touched.full_name &&
                        Boolean(formik.errors.full_name)
                      }
                      helperText={
                        formik.touched.full_name && formik.errors.full_name
                      }
                    />
                  </Grid>
                  <Grid item size={4}>
                    <TextField
                      label="Mobile Number"
                      name="contact_number"
                      value={formik.values.contact_number}
                      onChange={formik.handleChange}
                      onBlur={(e) => formik.handleBlur(e)}
                      variant="outlined"
                      fullWidth
                      required
                      error={
                        formik.touched.contact_number &&
                        Boolean(formik.errors.contact_number)
                      }
                      helperText={
                        formik.touched.contact_number &&
                        formik.errors.contact_number
                      }
                    />
                  </Grid>
                  <Grid item size={4}>
                    <TextField
                      label="Email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={(e) => formik.handleBlur(e)}
                      variant="outlined"
                      fullWidth
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item size={4}>
                    <Autocomplete
                      options={gender || []}
                      getOptionLabel={(option) => option.label}
                      value={
                        gender?.find(
                          (item) => item.id === formik.values.gender
                        ) || null
                      }
                      onChange={(event, value) => {
                        formik.setFieldValue("gender", value ? value.id : "");
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      name="gender"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Gender"
                          variant="outlined"
                          margin="none"
                          error={
                            formik.touched.gender &&
                            Boolean(formik.errors.gender)
                          }
                          helperText={
                            formik.touched.gender && formik.errors.gender
                          }
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item size={4}>
                    <Autocomplete
                      options={bloodGroup || []}
                      getOptionLabel={(option) => option.label}
                      value={
                        bloodGroup?.find(
                          (item) => item.id === formik.values.blood_group
                        ) || null
                      }
                      onChange={(event, value) => {
                        formik.setFieldValue(
                          "blood_group",
                          value ? value.id : ""
                        );
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      name="blood_group"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Blood Group"
                          variant="outlined"
                          margin="none"
                          error={
                            formik.touched.blood_group &&
                            Boolean(formik.errors.blood_group)
                          }
                          helperText={
                            formik.touched.blood_group &&
                            formik.errors.blood_group
                          }
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item size={4}>
                    <TextField
                      label="Employee Number"
                      name="employee_number"
                      value={formik.values.employee_number}
                      onChange={formik.handleChange}
                      onBlur={(e) => formik.handleBlur(e)}
                      variant="outlined"
                      fullWidth
                      error={
                        formik.touched.employee_number &&
                        Boolean(formik.errors.employee_number)
                      }
                      helperText={
                        formik.touched.employee_number &&
                        formik.errors.employee_number
                      }
                    />
                  </Grid>
                  <Grid item size={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        name="date_of_birth"
                        label="Date of Birth"
                        inputFormat="MM/DD/YYYY"
                        value={
                          formik.values.date_of_birth
                            ? moment(formik.values.date_of_birth)
                            : null
                        }
                        onChange={(newValue) =>
                          formik.setFieldValue(
                            "date_of_birth",
                            newValue && moment(newValue).isValid()
                              ? moment(newValue).toISOString()
                              : ""
                          )
                        }
                        slotProps={{
                          textField: {
                            error:
                              formik.touched.date_of_birth &&
                              Boolean(formik.errors.date_of_birth),
                            helperText:
                              formik.touched.date_of_birth &&
                              formik.errors.date_of_birth,
                            fullWidth: true,
                            variant: "outlined",
                          },
                        }}
                        onBlur={(e) => formik.handleBlur(e)}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item size={4}>
                    <TextField
                      label="Residence Address"
                      name="residence_address"
                      value={formik.values.residence_address}
                      onChange={formik.handleChange}
                      onBlur={(e) => formik.handleBlur(e)}
                      variant="outlined"
                      fullWidth
                      error={
                        formik.touched.residence_address &&
                        Boolean(formik.errors.residence_address)
                      }
                      helperText={
                        formik.touched.residence_address &&
                        formik.errors.residence_address
                      }
                    />
                  </Grid>
                  <Grid item size={4}>
                    <TextField
                      label="Residence Pincode"
                      name="residence_pincode"
                      type="number"
                      value={formik.values.residence_pincode}
                      onChange={formik.handleChange}
                      onBlur={(e) => formik.handleBlur(e)}
                      variant="outlined"
                      fullWidth
                      required
                      error={
                        formik.touched.residence_pincode &&
                        Boolean(formik.errors.residence_pincode)
                      }
                      helperText={
                        formik.touched.residence_pincode &&
                        formik.errors.residence_pincode
                      }
                    />
                  </Grid>
                  <Grid item size={4}>
                    <TextField
                      label="PAN"
                      name="pan"
                      value={formik.values.pan}
                      onChange={formik.handleChange}
                      onBlur={(e) => formik.handleBlur(e)}
                      variant="outlined"
                      fullWidth
                      error={formik.touched.pan && Boolean(formik.errors.pan)}
                      helperText={formik.touched.pan && formik.errors.pan}
                    />
                  </Grid>
                  <Grid item size={4}>
                    <TextField
                      label="Aadhaar"
                      name="aadhaar_number"
                      type="number"
                      value={formik.values.aadhaar_number}
                      onChange={formik.handleChange}
                      onBlur={(e) => formik.handleBlur(e)}
                      variant="outlined"
                      fullWidth
                      error={
                        formik.touched.aadhaar_number &&
                        Boolean(formik.errors.aadhaar_number)
                      }
                      helperText={
                        formik.touched.aadhaar_number &&
                        formik.errors.aadhaar_number
                      }
                    />
                  </Grid>
                  <Grid item size={4}>
                    <TextField
                      label="UAN Number"
                      name="uan"
                      value={formik.values.uan}
                      onChange={formik.handleChange}
                      onBlur={(e) => formik.handleBlur(e)}
                      variant="outlined"
                      fullWidth
                      error={formik.touched.uan && Boolean(formik.errors.uan)}
                      helperText={formik.touched.uan && formik.errors.uan}
                    />
                  </Grid>
                  <Grid item size={4}>
                    <Autocomplete
                      options={maritalStatus || []}
                      getOptionLabel={(option) => option.label}
                      value={
                        maritalStatus?.find(
                          (item) => item.id === formik.values.marital_status
                        ) || null
                      }
                      onChange={(event, value) => {
                        formik.setFieldValue(
                          "marital_status",
                          value ? value.id : ""
                        );
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      name="marital_status"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Marital Status"
                          variant="outlined"
                          margin="none"
                          error={
                            formik.touched.marital_status &&
                            Boolean(formik.errors.marital_status)
                          }
                          helperText={
                            formik.touched.marital_status &&
                            formik.errors.marital_status
                          }
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item size={4}>
                    <Autocomplete
                      options={qualifications || []}
                      getOptionLabel={(option) => option.display_name}
                      value={
                        qualifications?.find(
                          (item) => item.id === formik.values.qualification_id
                        ) || null
                      }
                      onChange={(event, value) => {
                        formik.setFieldValue(
                          "qualification_id",
                          value ? value.id : ""
                        );
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      name="qualifications"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Qualification"
                          variant="outlined"
                          margin="none"
                          error={
                            formik.touched.qualification_id &&
                            Boolean(formik.errors.qualification_id)
                          }
                          helperText={
                            formik.touched.qualification_id &&
                            formik.errors.qualification_id
                          }
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </MyCard>

            {/* Company Details */}
            <MyCard title="Company Information" width="full">
              <Box>
                <Grid container spacing={2} row>
                  <Grid item size={4}>
                    <Autocomplete
                      options={falttenBranchList || []}
                      getOptionLabel={(option) => option.label}
                      value={
                        falttenBranchList?.find(
                          (item) => item.id === formik.values.company_branch_id
                        ) || null
                      }
                      onChange={(event, value) => {
                        formik.setFieldValue(
                          "company_branch_id",
                          value ? value.id : ""
                        );
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      name="company_branch_id"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Branch"
                          variant="outlined"
                          margin="none"
                          error={
                            formik.touched.company_branch_id &&
                            Boolean(formik.errors.company_branch_id)
                          }
                          helperText={
                            formik.touched.company_branch_id &&
                            formik.errors.company_branch_id
                          }
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item size={4}>
                    <Grid container row spacing={2}>
                      <Grid item size={12}>
                        <Autocomplete
                          options={departmentsList || []}
                          getOptionLabel={(option) => option.display_name}
                          value={
                            departmentsList?.find(
                              (item) =>
                                item.id === formik.values.company_department_id
                            ) || null
                          }
                          onChange={(event, value) => {
                            formik.setFieldValue(
                              "company_department_id",
                              value ? value.id : ""
                            );
                          }}
                          onBlur={(e) => formik.handleBlur(e)}
                          name="company_department_id"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Department"
                              variant="outlined"
                              margin="none"
                              error={
                                formik.touched.company_department_id &&
                                Boolean(formik.errors.company_department_id)
                              }
                              helperText={
                                formik.touched.company_department_id &&
                                formik.errors.company_department_id
                              }
                              fullWidth
                              required
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item size={4}>
                    <Grid container row spacing={2}>
                      <Grid item size={12}>
                        <Autocomplete
                          options={designationList || []} // Ensure data is not null or undefined
                          getOptionLabel={(option) => option.display_name}
                          value={
                            designationList?.find(
                              (item) =>
                                item.id === formik.values.company_designation_id
                            ) || null
                          }
                          onChange={(event, value) => {
                            formik.setFieldValue(
                              "company_designation_id",
                              value ? value.id : ""
                            ); // Set the selected country code
                          }}
                          onBlur={(e) => formik.handleBlur(e)}
                          name="company_designation_id"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Designation"
                              variant="outlined"
                              margin="none"
                              error={
                                formik.touched.company_designation_id &&
                                Boolean(formik.errors.company_designation_id)
                              }
                              helperText={
                                formik.touched.company_designation_id &&
                                formik.errors.company_designation_id
                              }
                              fullWidth
                              required
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item size={4}>
                    <Autocomplete
                      options={employmentType || []}
                      getOptionLabel={(option) => option.label}
                      value={
                        employmentType?.find(
                          (item) =>
                            item.id === formik.values.employment_category
                        ) || null
                      }
                      onChange={(event, value) => {
                        formik.setFieldValue(
                          "employment_category",
                          value ? value.id : ""
                        );
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      name="employment_category"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Employment Type"
                          variant="outlined"
                          margin="none"
                          error={
                            formik.touched.employment_category &&
                            Boolean(formik.errors.employment_category)
                          }
                          helperText={
                            formik.touched.employment_category &&
                            formik.errors.employment_category
                          }
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item size={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        name="date_of_joining"
                        label="Date of Joining"
                        inputFormat="DD/MM/DD/YYYY"
                        value={
                          formik.values.date_of_joining
                            ? moment(formik.values.date_of_joining)
                            : null
                        }
                        onChange={(newValue) =>
                          formik.setFieldValue(
                            "date_of_joining",
                            newValue && moment(newValue).isValid()
                              ? moment(newValue).toISOString()
                              : ""
                          )
                        }
                        slotProps={{
                          textField: {
                            error:
                              formik.touched.date_of_joining &&
                              Boolean(formik.errors.date_of_joining),
                            helperText:
                              formik.touched.date_of_joining &&
                              formik.errors.date_of_joining,
                            fullWidth: true,
                            variant: "outlined",
                          },
                        }}
                        onBlur={(e) => formik.handleBlur(e)}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item size={4}>
                    <Autocomplete
                      options={roleRights || []}
                      getOptionLabel={(option) => option.display_name}
                      value={
                        roleRights?.find(
                          (item) => item.id === formik.values.company_role_id
                        ) || null
                      }
                      onChange={(event, value) => {
                        formik.setFieldValue(
                          "company_role_id",
                          value ? value.id : ""
                        );
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      name="company_role_id"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Role & Rights"
                          variant="outlined"
                          margin="none"
                          error={
                            formik.touched.company_role_id &&
                            Boolean(formik.errors.company_role_id)
                          }
                          helperText={
                            formik.touched.company_role_id &&
                            formik.errors.company_role_id
                          }
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item size={4}>
                    <div className="flex items-center space-x-2">
                      <Autocomplete
                        options={agencyList || []}
                        getOptionLabel={(option) => option.display_name || ""}
                        value={
                          (agencyList?.length &&
                            agencyList.find(
                              (item) =>
                                item.id === formik.values.company_agency_id
                            )) ||
                          null
                        }
                        onChange={(event, value) => {
                          formik.setFieldValue(
                            "company_agency_id",
                            value ? value.id : ""
                          );
                        }}
                        onBlur={(e) => formik.handleBlur(e)}
                        name="company_agency_id"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Agency"
                            variant="outlined"
                            margin="none"
                            error={
                              formik.touched.company_agency_id &&
                              Boolean(formik.errors.company_agency_id)
                            }
                            helperText={
                              formik.touched.company_agency_id &&
                              formik.errors.company_agency_id
                            }
                            fullWidth
                          />
                        )}
                        className="flex-1"
                      />
                      {/* Add Button */}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setAddAgencyOpen(true)}
                        sx={{
                          height: "56px",
                          minWidth: "56px",
                          fontSize: "2rem",
                        }}
                      >
                        +
                      </Button>
                    </div>
                  </Grid>

                  <Grid item size={4}>
                    <Autocomplete
                      options={groups || []}
                      getOptionLabel={(option) => option.group_name}
                      value={
                        groups?.find(
                          (item) => item.id === formik.values.group_id
                        ) || null
                      }
                      onChange={(event, value) => {
                        formik.setFieldValue("group_id", value ? value.id : "");
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      name="group_id"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Attendance Group"
                          variant="outlined"
                          margin="none"
                          error={
                            formik.touched.group_id &&
                            Boolean(formik.errors.group_id)
                          }
                          helperText={
                            formik.touched.group_id && formik.errors.group_id
                          }
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item size={4}>
                    <Autocomplete
                      options={holidays || []}
                      getOptionLabel={(option) => option.display_name}
                      value={
                        holidays?.find(
                          (item) => item.id === formik.values.holiday_group
                        ) || null
                      }
                      onChange={(event, value) => {
                        formik.setFieldValue(
                          "holiday_group",
                          value ? value.id : ""
                        );
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      name="holiday_group"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Holiday Group"
                          variant="outlined"
                          margin="none"
                          error={
                            formik.touched.holiday_group &&
                            Boolean(formik.errors.holiday_group)
                          }
                          helperText={
                            formik.touched.holiday_group &&
                            formik.errors.holiday_group
                          }
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </MyCard>
            <MyCard title="Approval Stage" width="full">
              <Grid container spacing={2} row>
                <Grid item size={4}>
                  {/* Stage 1 */}
                  <Autocomplete
                    options={employeesList || []}
                    getOptionLabel={(option) => option.full_name}
                    value={
                      employeesList?.find(
                        (item) => item.id === formik.values.approver_1
                      ) || null
                    }
                    onChange={(e, value) => {
                      formik.setFieldValue("approver_1", value ? value.id : "");
                    }}
                    disabled={loading}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Approver Stage 1"
                        error={
                          formik.touched.approver_1 &&
                          Boolean(formik.errors.approver_1)
                        }
                        helperText={
                          formik.touched.approver_1 && formik.errors.approver_1
                        }
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item size={4}>
                  {/* Stage 2 */}
                  <Autocomplete
                    options={getFilteredOptions(2)}
                    getOptionLabel={(option) => option.full_name}
                    value={
                      employeesList?.find(
                        (item) => item.id === formik.values.approver_2
                      ) || null
                    }
                    onChange={(e, value) => {
                      formik.setFieldValue("approver_2", value ? value.id : "");
                    }}
                    disabled={!formik.values.approver_1 || loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Approver Stage 2"
                        error={
                          formik.touched.approver_2 &&
                          Boolean(formik.errors.approver_2)
                        }
                        helperText={
                          formik.touched.approver_2 && formik.errors.approver_2
                        }
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item size={4}>
                  {/* Stage 3 */}
                  <Autocomplete
                    options={getFilteredOptions(3)}
                    getOptionLabel={(option) => option.full_name}
                    value={
                      employeesList?.find(
                        (item) => item.id === formik.values.approver_3
                      ) || null
                    }
                    onChange={(e, value) => {
                      formik.setFieldValue("approver_3", value ? value.id : "");
                    }}
                    disabled={!formik.values.approver_2 || loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Approver Stage 3"
                        error={
                          formik.touched.approver_3 &&
                          Boolean(formik.errors.approver_3)
                        }
                        helperText={
                          formik.touched.approver_3 && formik.errors.approver_3
                        }
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </MyCard>
            <div className="flex justify-end w-full px-5 ">
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {addAgencyOpen && (
        <AddAgency
          isModalOpen={setAddAgencyOpen}
          refreshAgencyList={fetchAgencyList}
        />
      )}
    </>
  );
};

export default AddEmployee;
