import React, { useState, useEffect, useMemo } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import axiosInstance from "../../axios/axiosInstance";
import { getErrorMessage } from "../../utils/getErrorMessage";
import useFetch from "../../hooks/useFetch";

const AddBranchHead = ({ isModalOpen, branchData }) => {
  const [branchHead, setBranchHead] = useState("");
  const params = useMemo(() => ({}), []);
  const employeeUrl = useMemo(() => "/employee/company-employees", []);
  const { data: employeeList } = useFetch(employeeUrl, params);
  const employees = Array.isArray(employeeList) ? employeeList : [];

  useEffect(() => {
    if (branchData && branchData.branch_head) {
      setBranchHead(branchData.branch_head);
    }
  }, [branchData]);

  const handleBranchHead = async () => {
    try {
      const response = await axiosInstance.put(`/company/branch/update`, {
        branch_id: branchData.id,
        branch_head: branchHead,
      });

      if (response.data.status === 200 || response.data.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Branch Head assigned successfully!",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then(() => isModalOpen(false));
      }
    } catch (err) {
      const dash = getErrorMessage(err.response.data.error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: dash,
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  };

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth={true}
      onClose={() => isModalOpen(false)}
    >
      <DialogTitle>Manage Branch Head</DialogTitle>
      <div>
        <DialogContent>
          <div className="flex flex-col gap-4">
            <TextField
              label="Branch Head"
              variant="outlined"
              fullWidth
              name="branch_head"
              value={branchHead}
              onChange={(event) => setBranchHead(event.target.value)}
              select
            >
              {employees.map((list) => (
                <MenuItem key={list.id} value={list.id}>
                  {list.full_name}
                </MenuItem>
              ))}
            </TextField>

            <div className="flex justify-end gap-4">
              <Button variant="outlined" onClick={() => isModalOpen(false)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleBranchHead}
              >
                Assign Branch head
              </Button>
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default AddBranchHead;
