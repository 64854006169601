import React, { useState, useEffect, useMemo } from "react";
import {
  GoogleMap,
  useLoadScript,
  Circle,
  Marker,
  LoadScript,
} from "@react-google-maps/api";
import { Box, Typography } from "@mui/material";

const GeoFencing = ({ formData }) => {
  const { location_latitude, location_longitude, fence_radius } =
    formData.geoFencing;

  const center = { lat: location_latitude, lng: location_longitude };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  console.log("isLoaded:", isLoaded);
  console.log("loadError:", loadError);

  if (!isLoaded) {
    console.error("Google Maps API is not loaded properly:", loadError);
    return <p>Loading map...</p>;
  }

  // If geo-fencing data is unavailable, show a fallback message
  if (!location_latitude || !location_longitude || !fence_radius) {
    return (
      <div className="p-6 bg-gray-100 border border-gray-300 rounded-lg">
        <div className="space-y-4 text-center">
          <h1 className="text-3xl font-extrabold text-theme-blue bg-clip-text mb-4 tracking-wide">
            Geo Fencing Unavailable
          </h1>
          <p className="text-gray-900 text-lg leading-relaxed font-medium tracking-wider">
            Please finish the GeoFencing settings through the mobile app to
            proceed further.
          </p>
        </div>
      </div>
    );
  }
  console.log("data", location_latitude, location_longitude, fence_radius);

  // Render Google Map with fetched geo-fencing data
  return (
    <div style={{ width: "100%", height: "400px" }}>
      {/* Ensure this has fixed height */}
      <GoogleMap
        center={center}
        zoom={16}
        mapContainerStyle={{ width: "100%", height: "100%" }} // Ensure height is correctly set
      >
        <Marker position={center} />
        <Circle
          center={center}
          radius={fence_radius * 1000}
          options={{
            fillColor: "#00CED1",
            fillOpacity: 0.3,
            strokeColor: "#00CED1",
            strokeOpacity: 0.8,
            strokeWeight: 2,
          }}
        />
      </GoogleMap>
    </div>
  );
};

export default GeoFencing;
