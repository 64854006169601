import React, { useState, useMemo } from "react";
import TreeViewComponent from "../../components/common/TreeViewComponent";
import { Button, Menu, MenuItem } from "@mui/material";
import AddBranch from "./AddBranch";
import useFetch from "../../hooks/useFetch";
import MyCard from "../../components/common/MyCard";
import ResetRadius from "./ResetRadius";
import ManageFenceAdmin from "./ManageFenceAdmin";
import AddBranchHead from "./AddBranchHead";

const Branch = () => {
  const branchListUrl = useMemo(() => "/company/branch/list", []);
  const params = useMemo(() => ({}), []);
  const { data: branchData } = useFetch(branchListUrl, params);

  const [anchorEl, setAnchorEl] = useState(null); // For managing Menu state
  const [currentBranch, setCurrentBranch] = useState(null); // For storing current branch for actions
  const [isAddBranchOpen, setIsAddBranchOpen] = useState(false);
  const [isResetRadiusOpen, setIsResetRadiusOpen] = useState(false);
  const [isFenceAdminOpen, setIsFenceAdminOpen] = useState(false);
  const [isBranchHeadOpen, setIsBranchHeadOpen] = useState(false);

  // Handle opening the menu for actions
  const handleMenuClick = (event, branch) => {
    setAnchorEl(event.currentTarget);
    setCurrentBranch(branch); // Store the current branch
  };

  // Close the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle actions like Edit, Reset Radius, etc.
  const handleAction = (action) => {
    switch (action) {
      case "Edit":
        setIsAddBranchOpen(true);
        break;
      case "Manage Branch Head":
        setIsBranchHeadOpen(true);
        break;
      case "Reset Radius":
        setIsResetRadiusOpen(true);
        break;
      case "Manage Fence Admin":
        setIsFenceAdminOpen(true);
        break;
      default:
        console.warn("Unhandled action:", action);
    }
    handleMenuClose();
  };

  return (
    <div className="flex flex-col w-full p-5 m-2">
      {/* Add Branch Button */}
      <div className="flex justify-end px-6 pt-5">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsAddBranchOpen(true);
            setCurrentBranch(null);
          }}
        >
          Add Branch
        </Button>
      </div>

      {/* Branch Tree View */}
      <MyCard title="Branches" width="full">
        {branchData && branchData.length > 0 ? (
          branchData.map((item) => (
            <div key={item.id} style={{ width: "100%" }}>
              {/* TreeViewComponent renders branch with Actions button */}
              <TreeViewComponent
                node={item}
                parentbranchkey="branch_name"
                childbranchkey="sub_branches"
                handleMenuClick={handleMenuClick} // Pass handleMenuClick to TreeViewComponent
              />
            </div>
          ))
        ) : (
          <div>No branches found.</div>
        )}
        {/* Menu for Actions */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleAction("Edit")}>Edit</MenuItem>
          <MenuItem onClick={() => handleAction("Manage Branch Head")}>
            Manage Branch Head
          </MenuItem>
          <MenuItem onClick={() => handleAction("Reset Radius")}>
            Reset Radius
          </MenuItem>
          <MenuItem onClick={() => handleAction("Enable Refench")}>
            Enable Refench
          </MenuItem>
          <MenuItem onClick={() => handleAction("Manage Fence Admin")}>
            Manage Fence Admin
          </MenuItem>
        </Menu>
      </MyCard>
      {isAddBranchOpen && (
        <AddBranch
          isModalOpen={setIsAddBranchOpen}
          branchData={currentBranch}
        />
      )}

      {isResetRadiusOpen && (
        <ResetRadius
          isModalOpen={setIsResetRadiusOpen}
          branchData={currentBranch}
        />
      )}

      {isBranchHeadOpen && (
        <AddBranchHead
          isModalOpen={setIsBranchHeadOpen}
          branchData={currentBranch}
        />
      )}

      {isFenceAdminOpen && (
        <ManageFenceAdmin
          isModalOpen={setIsFenceAdminOpen}
          branchData={currentBranch}
        />
      )}
    </div>
  );
};

export default Branch;
