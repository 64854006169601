import React, { useState, useRef, useEffect } from "react";
import { TextField, Button, Box, Grid2, Typography } from "@mui/material";

const OtpInput = ({ onSubmit, onResend, type, isVerified }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(30); // Timer state
  const [isTimerActive, setIsTimerActive] = useState(true);
  const inputRefs = useRef([]);

  useEffect(() => {
    let interval = null;
    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
      setTimer(30); // Reset timer
    }
    return () => clearInterval(interval);
  }, [isTimerActive, timer]);

  const handleChange = (index, event) => {
    const value = event.target.value;
    // Ensure only one character is entered
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input automatically
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    // Allow backspace to move to the previous input
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(otp.join(""), type); // Join the OTP array to form a single string
  };

  const handleResend = () => {
    onResend(type); // Call the resend OTP function
    setIsTimerActive(true); // Start the timer
    setTimer(30); // Reset the timer to 30 seconds
  };

  return (
    <Box component="form" sx={{ mt: 1 }}>
      <Grid2 container spacing={1}>
        {otp.map((digit, index) => (
          <Grid2 item xs={2} key={index}>
            <TextField
              inputRef={(el) => (inputRefs.current[index] = el)} // Assign ref to input
              id={`otp-input-${index}`}
              // label={`Digit ${index + 1}`}
              variant="outlined"
              value={digit}
              onChange={(event) => handleChange(index, event)}
              onKeyDown={(event) => handleKeyDown(index, event)}
              inputProps={{
                maxLength: 1, // Limit input to one character
              }}
              required
              fullWidth
              sx={{ width: "50px" }} // Set width to 50px for each input
            />
          </Grid2>
        ))}
      </Grid2>
      <Button
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
      >
        Verify OTP
      </Button>
      {!isVerified && (
        <Box mt={2}>
          {isTimerActive ? (
            <Typography variant="body2">
              Resend OTP in {timer} seconds
            </Typography>
          ) : (
            <Button variant="outlined" color="secondary" onClick={handleResend}>
              Resend OTP
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default OtpInput;
