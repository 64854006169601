import React from "react";
import { Checkbox, FormControlLabel, Stack, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Fieldset from "../../../../components/common/Fieldset";
import InfoIcon from "@mui/icons-material/Info";
import { Field } from "formik";

const Restrictions = ({ values, setFieldValue }) => {
  return (
    <div className="container">
      <Fieldset
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            <InfoIcon />
            Restrictions
          </Stack>
        }
        borderWidth={2}
        borderRadius={1}
        sx={{
          padding: 2,
          "& legend": {
            borderRadius: "4px",
          },
        }}
      >
        <Grid container spacing={2} row>
          <Grid item size={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    values.leave_restriction.allow_exceeding_entitlement ||
                    false
                  }
                  onChange={(event) =>
                    setFieldValue(
                      "leave_restriction.allow_exceeding_entitlement",
                      event.target.checked
                    )
                  }
                  name="allow_exceeding_entitlement"
                />
              }
              label="Allow leave request that exceed entitled leaves"
            />
          </Grid>
          <Grid item size={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    values.leave_restriction.consider_weekends_holidays || false
                  }
                  onChange={(event) =>
                    setFieldValue(
                      "leave_restriction.consider_weekends_holidays",
                      event.target.checked
                    )
                  }
                  name="consider_weekends_holidays"
                />
              }
              label="Enable to consider weekends and holidays between the leave period as leave"
            />
          </Grid>
          <Grid item size={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    values.leave_restriction.clubbing_not_allowed || false
                  }
                  onChange={(event) =>
                    setFieldValue(
                      "leave_restriction.clubbing_not_allowed",
                      event.target.checked
                    )
                  }
                  name="clubbing_not_allowed"
                />
              }
              label="Clubbing Not Allowed"
            />
          </Grid>
          <Grid item size={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.leave_restriction.allow_past_dates || false}
                  onChange={(event) =>
                    setFieldValue(
                      "leave_restriction.allow_past_dates",
                      event.target.checked
                    )
                  }
                  name="allow_past_dates"
                />
              }
              label="Allow past dates to apply for leave"
            />
          </Grid>
          <Grid item size={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.leave_restriction.allow_future_dates || false}
                  onChange={(event) =>
                    setFieldValue(
                      "leave_restriction.allow_future_dates",
                      event.target.checked
                    )
                  }
                  name="allow_future_dates"
                />
              }
              label="Allow Future dates to apply for leave"
            />
          </Grid>
          <Grid item size={4}>
            <label>Minimum Leaves can be applied per request</label>
          </Grid>
          <Grid item size={2}>
            <Field
              as={TextField}
              name="min_leaves_allowed_per_request"
              size="small"
              type="number"
              fullWidth
              value={values.leave_restriction.min_leaves_allowed_per_request}
              onChange={(event) =>
                setFieldValue(
                  "leave_restriction.min_leaves_allowed_per_request",
                  event.target.value
                )
              }
            />
          </Grid>
          <Grid item size={4}>
            <label>Maximum Leaves can be applied per request</label>
          </Grid>
          <Grid item size={2}>
            <Field
              as={TextField}
              name="max_leaves_allowed_per_request"
              size="small"
              type="number"
              fullWidth
              value={values.leave_restriction.max_leaves_allowed_per_request}
              onChange={(event) =>
                setFieldValue(
                  "leave_restriction.max_leaves_allowed_per_request",
                  event.target.value
                )
              }
            />
          </Grid>
          <Grid item size={4}>
            <label>Minimum gap between two leave request</label>
          </Grid>
          <Grid item size={2}>
            <Field
              as={TextField}
              name="min_gap_between_requests"
              size="small"
              type="number"
              fullWidth
              value={values.leave_restriction.min_gap_between_requests}
              onChange={(event) =>
                setFieldValue(
                  "leave_restriction.min_gap_between_requests",
                  event.target.value
                )
              }
            />
          </Grid>
          <Grid item size={4}>
            <label>Number of leave approvals required</label>
          </Grid>
          <Grid item size={2}>
            <Field
              as={TextField}
              name="total_approvals_required"
              size="small"
              type="number"
              fullWidth
              value={values.leave_restriction.total_approvals_required}
              onChange={(event) =>
                setFieldValue(
                  "leave_restriction.total_approvals_required",
                  event.target.value
                )
              }
            />
          </Grid>
        </Grid>
      </Fieldset>
    </div>
  );
};

export default Restrictions;
