import React, { useEffect, useState } from "react";
const Welcome = () => {
  const details = [
    "Geo tagging",
    "Real-time statistics",
    "Salary calculations",
    "Payments and payslips",
    "And much more!!!",
  ];

  return (
    <div className="flex flex-col items-center justify-center h-[screen] w-screen bg-gradient-to-r to-sky-500 from-theme-blue ">
      <h1 className="text-4xl md:text-5xl font-extrabold text-white text-center animate-fade-in text-shadow ">
        Welcome to Klockmate
      </h1>

      <p className="mt-4 text-lg md:text-xl text-white font-bold  text-center animate-fade-in-up text-shadow ">
        Your logs, our responsibility
      </p>

      <ul className="mt-10 space-y-4 text-sm md:text-sm list-none tracking-wide animate-slide-in-left">
        {details.map((item, index) => (
          <li key={index} className="relative pl-6">
            {/* Arrow Bullet */}
            <span className="absolute left-0 top-1 text-blue-50">➔</span>
            <span className="text-blue-50 text-md">{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Welcome;
