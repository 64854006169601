import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import axiosInstance from "../../axios/axiosInstance";
import { getErrorMessage } from "../../utils/getErrorMessage";
import useFetch from "../../hooks/useFetch";

const ManageFenceAdmin = ({ isModalOpen, branchData }) => {
  const [fenceAdmin, setFenceAdmin] = useState("");
  const employeeUrl = React.useMemo(() => "/employee/company-employees", []);
  const params = React.useMemo(() => ({}), []);
  const { data: employeeList } = useFetch(employeeUrl, params);
  const employees = Array.isArray(employeeList) ? employeeList : [];

  useEffect(() => {
    if (branchData && branchData.fence_user) {
      setFenceAdmin(branchData.fence_user);
    }
  }, [branchData]);

  const handleResetFenceAdmin = async () => {
    try {
      const response = await axiosInstance.put(`/company/branch/update`, {
        branch_id: branchData.id,
        fence_user: fenceAdmin,
      });

      if (response.data.status === 200 || response.data.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Fence Admin updated successfully!",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then(() => isModalOpen(false));
      }
    } catch (err) {
      const dash = getErrorMessage(err.response.data.error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: dash,
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  };

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth={true}
      onClose={() => isModalOpen(false)}
    >
      <DialogTitle>Reset Fence Admin</DialogTitle>
      <div>
        <DialogContent>
          <div className="flex flex-col gap-4">
            <TextField
              label="Assign Fence Admin"
              variant="outlined"
              fullWidth
              value={fenceAdmin}
              onChange={(e) => setFenceAdmin(e.target.value)}
              type="text"
              select
            >
              {employees.map((list) => (
                <MenuItem key={list.id} value={list.id}>
                  {list.full_name}
                </MenuItem>
              ))}
            </TextField>

            <div className="flex justify-end gap-4">
              <Button variant="outlined" onClick={() => isModalOpen(false)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleResetFenceAdmin}
              >
                Reset Fence Admin
              </Button>
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default ManageFenceAdmin;
