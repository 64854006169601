import React from "react";
import { Autocomplete } from "@mui/material";
import { TextField, Chip } from "@mui/material";

const MultiSelectAutocomplete = ({
  options,
  label,
  selectedOptions,
  setSelectedOptions,
}) => {
  // const [selectedOptions, setSelectedOptions] = React.useState([]);
  // console.log("selectedOptions: ", selectedOptions);
  const handleChange = (event, value) => {
    // setSelectedOptions(value);
    setSelectedOptions(value.map((option) => option.id));
    // setSelectedOptions((prev)=>{});
  };

  const handleDelete = (optionToDelete) => {
    const newValue = selectedOptions.filter(
      (option) => option.id !== optionToDelete.id
    );
    setSelectedOptions(newValue.map((option) => option.id));
  };

  return (
    <Autocomplete
      multiple
      options={[...options]} // Add "Select All" to options
      getOptionLabel={(option) => option.label}
      value={selectedOptions}
      onChange={handleChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={option.id} // Use a unique identifier as the key
            label={option.label}
            {...getTagProps({ index })}
            onDelete={() => handleDelete(option)} // Call handleDelete
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder="Select multiple options"
        />
      )}
    />
  );
};

export default MultiSelectAutocomplete;
