import React from "react";
import MyCard from "../../components/common/MyCard";
import { styled } from "@mui/material/styles";
import { Button, IconButton, Box, Icon } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ViewCompanyDetails = ({ company, setShowModal, showModal }) => {
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showModal}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Detailed View..!!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>Company information</Typography>
          <div>
            <table width={"100%"} border={1}>
              <tbody>
                <tr>
                  <th>Company Name: </th>
                  <td>{company?.registered_name}</td>
                  <th>Establishment Year: </th>
                  <td>{company?.establishment_year}</td>
                </tr>
                <tr>
                  <th>Contact Number: </th>
                  <td>{company?.company_contact_number}</td>
                  <th>Email: </th>
                  <td>{company?.company_email}</td>
                </tr>
                <tr>
                  <th>Ownership Type: </th>
                  <td>{company?.ownership_type}</td>
                  <th>Annual Turnover: </th>
                  <td>{company?.annual_turnover}</td>
                </tr>
                <tr>
                  <th>Nature of business: </th>
                  <td>{company?.nature_of_business}</td>
                  <th>No of branches: </th>
                  <td>{company?.number_of_branches}</td>
                </tr>
                <tr>
                  <th>No of employees: </th>
                  <td>{company?.number_of_employees}</td>
                  <th>Company PAN: </th>
                  <td>{company?.pan}</td>
                </tr>
                <tr>
                  <th>Company GST: </th>
                  <td>{company?.gst}</td>
                  <th>State: </th>
                  <td>{company?.state}</td>
                </tr>
                <tr>
                  <th>City: </th>
                  <td>{company?.city}</td>
                  <th>Pincode: </th>
                  <td>{company?.pincode}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Typography gutterBottom>Admin information</Typography>
          <div>
            <table width={"100%"} border={1}>
              <tbody>
                <tr>
                  <th>Name: </th>
                  <td>{company?.admin_name}</td>
                  <th>Contact Number: </th>
                  <td>{company?.admin_contact_number}</td>
                </tr>
                <tr>
                  <th>Gender: </th>
                  <td>{company?.admin_gender}</td>
                  <th>Email: </th>
                  <td>{company?.admin_email}</td>
                </tr>
                <tr>
                  <th>Blood Group: </th>
                  <td>{company?.admin_blood_group}</td>
                  <th>DOB: </th>
                  <td>{company?.admin_date_of_birth}</td>
                </tr>
                <tr>
                  <th>Residence Address: </th>
                  <td>{company?.admin_residence_address}</td>
                  <th>Pincode: </th>
                  <td>{company?.admin_residence_pincode}</td>
                </tr>
                <tr>
                  <th>Employee Number: </th>
                  <td>{company?.admin_employee_number}</td>
                  <th>PAN: </th>
                  <td>{company?.admin_pan}</td>
                </tr>
                <tr>
                  <th>Aadhar Number: </th>
                  <td>{company?.admin_aadhaar_number}</td>
                  <th>UAN Number: </th>
                  <td>{company?.admin_uan}</td>
                </tr>
                <tr>
                  <th>Marital Status: </th>
                  <td>{company?.admin_marital_status}</td>
                  <th>Qualification: </th>
                  <td>{company?.admin_qualification}</td>
                </tr>
                <tr>
                  <th>Designation: </th>
                  <td>{company?.admin_designation}</td>
                  <th>Department: </th>
                  <td>{company?.admin_department}</td>
                </tr>
                <tr>
                  <th>Working Location: </th>
                  <td>{company?.admin_working_location}</td>
                  <th>Date of joining: </th>
                  <td>{company?.admin_date_of_joining}</td>
                </tr>
                <tr>
                  <th>Klockmate Reffered By: </th>
                  <td>{company?.admin_klockmate_referred_by}</td>
                  <th> </th>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default ViewCompanyDetails;
