import React from "react";

const PricingCard = () => {
  const plans = [
    {
      name: "Elite",
      price: "499",
      // price: "25.99",
      features: [
        "AI Powered Face Attendance",
        "Attendance on the Go",
        "Holiday Calendar",
        "Leave Management",
        "Attendance Reports",
        "ID Card Generation",
      ],
    },
    {
      name: "Pro",
      // price: "799",
      price: "32.99",
      features: [
        "All the  Features in Elite",
        "Live Tracking",
        "Attendance for Others",
        "Over Time Management",
        "Shift Management",
        "Out of Office Alert",
      ],
    },
    {
      name: "Premium",
      // price: "999",
      price: "39.99",
      features: [
        "All the  Features in Pro",
        "Task Management",
        "Payroll Management",
        "Salary Reports",
        "Payslips",
        "ERP / HRMS Integration",
        "Dedicated Account Manager",
        "Customization",
      ],
    },
  ];

  return (
    <div>
      <div className="flex flex-col justify-center item-center pt-10">
        <div className="flex justify-center">
          <h2 className=" text-5xl font-bold text-theme-blue mb-5">
            Subscription Plans
          </h2>
        </div>
        <div className="flex flex-row gap-x-2 justify-center items-center">
          <div>
            <h2 className="text-4xl font-bold text-theme-blue">India</h2>
          </div>
          <div className="w-10">
            <img
              src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
              alt="Indian Flag"
              class="flag"
            ></img>
          </div>

          {/* <div>
            <h2 className="text-4xl font-bold text-theme-blue">USA</h2>
          </div>
          <div className="w-14">
            <img
              src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
              alt="USA Flag"
              class="flag"
            />
          </div> */}
        </div>
      </div>

      <div className="flex flex-wrap justify-center item-center gap-16 py-10">
        {plans.map((plan, index) => (
          <div
            key={index}
            className="flex flex-col justify-between w-80 p-6 bg-theme-blue shadow-lg rounded-xl border-2 hover:border-color1 transition-all duration-300 hover:z-50"
            style={{ transition: "z-index 0.5s" }} // Optional if CSS handles this
          >
            <div className="border-b-2 border-white pb-4 shadow-md">
              <h3 className="text-2xl font-bold text-center text-white">
                {plan.name}
              </h3>
              <p className="text-center mt-2 text-white text-2xl">
                ₹{plan.price}
                {/* ${plan.price} */}
              </p>
              <p className="text-center mt-2 text-white text-sm">
                Monthly / Employee
                {/* Annually / Employee */}
              </p>
            </div>

            <ul className="mt-4 space-y-3">
              {plan.features.map((feature, idx) => (
                <li key={idx} className="flex items-center gap-2 text-white">
                  <svg
                    className="w-5 h-5 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <span>{feature}</span>
                </li>
              ))}
            </ul>

            <div className="mt-6">
              <button className="w-full py-2 px-4 text-theme-blue bg-white text-lg  font-semibold rounded-lg transition duration-300">
                {/* Choose {plan.name} */}
                Get Started
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingCard;
