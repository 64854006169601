import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { Edit, Delete } from "@mui/icons-material";
import { Formik, Form, Field } from "formik";

import leaveData from "../leave_configuration/leaveData.json";
import MyCard from "../../../components/common/MyCard";
import LeaveList from "./LeaveList";
import LeaveTypeEnCash from "./LeaveTypeEnCash";
import * as Yup from "yup";
import LeaveApplicable from "./LeaveApplicable";
import { AddLeave } from "./create";
import useFetch from "../../../hooks/useFetch";

const LeaveConfiguration = ({ formData, onStepDataChange }) => {
  const leaveListUrl = useMemo(() => "/leave/leave-configuration-list", []);
  const params = useMemo(() => ({}), []);
  const { data: leaveData } = useFetch(leaveListUrl, params);

  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = (values) => {
    console.log("Form Submitted:", values);
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-bold">Leaves</h3>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowPopup(true)}
        >
          Add Leave
        </Button>
      </div>

      {leaveData?.length > 0 ? (
        <LeaveList leaveData={leaveData} />
      ) : (
        <p className="text-gray-500 text-center">No leaves configured yet.</p>
      )}

      {showPopup && (
        <AddLeave setShowModal={setShowPopup} showModal={showPopup} />
      )}

      {/* Dialog for Adding Leave */}
      {/* <Dialog
        open={showPopup}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setShowPopup(false);
          }
        }}
        fullWidth
        maxWidth="md"
        scroll="paper"
      >
        <DialogTitle>
          <div>
            <h1 className="text-xl font-bold">Add Leave Configuration</h1>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, touched, errors }) => (
              <Form className="flex flex-col gap-4 ">
                <LeaveTypeEnCash
                  setFieldValue={setFieldValue}
                  touched={touched}
                  errors={errors}
                  values={values}
                />
                <LeaveApplicable />
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPopup(false)} variant="outlined">
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Add
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default LeaveConfiguration;
