import { type } from "@testing-library/user-event/dist/type";

export const CLEAR_REDUX_STATE = "CLEAR_REDUX_STATE";
export const ADMIN_AUTHORIZED = "ADMIN_AUTHORIZED";
export const SET_SIDEBAR_DATA = "SET_SIDEBAR_DATA";
export const ADMIN_MASTER_LOADER = "ADMIN_MASTER_LOADER";
export const SET_THEME = "SET_THEME";

// /Sidebar
export const SET_ACTIVE_MENU = "SET_ACTIVE_MENU";
export const SET_ACTIVE_SUBMENU = "SET_ACTIVE_SUBMENU";
export const SET_ACTIVE_INDEX = "SET_ACTIVE_INDEX";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_BULK_ACTIVE_TAB = "SET_BULK_ACTIVE_TAB";
export const SET_THEME_COLOR = "SET_THEME_COLOR";
//

export const onAdminAuthorize = (authState, userData) => ({
  type: ADMIN_AUTHORIZED,
  payload: { authState, userData },
});

// Action creator for setting sidebar data
export const setSidebarData = (sidebarDataFromApi) => {
  return {
    type: SET_SIDEBAR_DATA,
    payload: sidebarDataFromApi,
  };
};

export const setTheme = (theme) => ({
  type: SET_THEME,
  payload: theme,
});
// /Sidebar
export const setActiveMenu = (menuId) => ({
  type: SET_ACTIVE_MENU,
  payload: menuId,
});

export const setActiveTab = (index) => ({
  type: SET_ACTIVE_TAB,
  payload: index,
});

export const setBulkActiveTab = (index) => ({
  type: SET_BULK_ACTIVE_TAB,
  payload: index,
});

export const setActiveSubMenu = (subMenuId) => ({
  type: SET_ACTIVE_SUBMENU,
  payload: subMenuId,
});

export const setActiveIndex = (type, index) => ({
  type: SET_ACTIVE_INDEX,
  payload: { type, index },
});
//
