import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.klockm8.com/api", // Replace with your API's base URL
  timeout: 10000, // Optional timeout
  headers: {
    "Content-Type": "application/json",
    "x-api-key": "km@123",
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Optionally add authorization headers or other configs
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // List of endpoints that shouldn't have Authorization header
    const noAuthEndpoints = [
      "/authentication/login",
      "/meta/country",
      "/meta/marital-status",
      "/meta/company-nature-of-business",
      "/meta/state",
      "/meta/district",
      "/meta/qualification",
      "/company/register/start",
      "/company/register/complete",
      "/meta/company-ownership-type",
    ];

    // Check if the request URL matches any of the no-auth endpoints
    if (noAuthEndpoints.some((endpoint) => config.url.includes(endpoint))) {
      delete config.headers["Authorization"];
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle errors globally
    const { response } = error;
    if (response) {
      // The request was made and the server responded with a status code
      console.error("API error:", response.data);
      //   alert(`Error: ${response.data.message || response.statusText}`);
    } else {
      // The request was made but no response was received
      console.error("Network error:", error.message);
      //   alert("Network error. Please try again later.");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
