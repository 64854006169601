import React, { useEffect, useState, useMemo } from "react";
import MyCard from "../../components/common/MyCard";
import {
  TextField,
  Button,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import useFetch from "../../hooks/useFetch";
import axiosInstance from "../../axios/axiosInstance";
import Swal from "sweetalert2";
import { flattenBranches } from "../../utils/flattenBranch";
import Grid from "@mui/material/Grid2";
import { getErrorMessage } from "../../utils/getErrorMessage";

const AddBranch = ({ isModalOpen, branchData = {} }) => {
  const branchUrl = useMemo(() => "/company/branch/list", []);
  const stateUrl = useMemo(() => "/meta/state", []);
  const districtUrl = useMemo(() => "/meta/district", []);
  const employeeUrl = useMemo(() => "/employee/company-employees", []);

  const [falttenBranchList, setFlattenBranchList] = useState([]);
  const [selectedBranchId, setSelectedBranchId] = useState("");
  const [stateId, setStateId] = useState(null);

  const params = useMemo(() => ({}), []);
  const cityUrl = useMemo(() => "/meta/city", []);
  const params1 = useMemo(() => ({ state: stateId }), [stateId]);
  const { data: cityList } = useFetch(cityUrl, params1);

  const { data: branchesList } = useFetch(branchUrl, params);
  const { data: statesResponse } = useFetch(stateUrl, params);
  const { data: districtsResponse } = useFetch(districtUrl, params);
  const { data: employeeList } = useFetch(employeeUrl, params);

  // Ensure fetched data is an array
  const states = Array.isArray(statesResponse) ? statesResponse : [];
  const districts = Array.isArray(districtsResponse) ? districtsResponse : [];
  const employees = Array.isArray(employeeList) ? employeeList : [];

  useEffect(() => {
    if (Array.isArray(branchesList)) {
      const flatBranchData = flattenBranches(branchesList);
      setFlattenBranchList(flatBranchData);
    }

    if (branchData) {
      formik.setValues({
        branch_id: branchData.id || null,
        branch_name: branchData.branch_name || "",
        branch_code: branchData.branch_code || "",
        branch_parent: branchData.branch_parent || "",
        full_address: branchData.full_address || "",
        pincode: branchData.pincode || "",
        state_id: branchData.state.id || "",
        city_id: branchData?.city?.id || "",
        district_id: branchData.district.id || "",
        display_name: branchData.display_name || "",
      });
    }
  }, [branchesList, branchData]);

  const handleBranchSelection = (event) => {
    const selectedId = event.target.value;
    setSelectedBranchId(selectedId);
    formik.setFieldValue("branch_parent", selectedId); // Update formik value
  };

  const onSubmit = async (values) => {
    try {
      let response;
      if (branchData && Object.keys(branchData).length > 0) {
        // If editing, send a PUT request
        response = await axiosInstance.put(`/company/branch/update`, values);
        if (response.data.status === 200 || response.data.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Branch updated successfully!",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          }).then(() => {
            isModalOpen(false); // Close the modal after confirmation
          });
        }
      } else {
        // If adding a new branch, send a POST request
        response = await axiosInstance.post("/company/branch/add", values);
        if (response.data.status === 200 || response.data.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Branch added successfully!",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
          }).then(() => {
            isModalOpen(false); // Close the modal after confirmation
          });
        }
      }
    } catch (err) {
      const dash = getErrorMessage(err.response.data.error);
      Swal.fire({
        icon: "error",
        text: dash,
        title: "Error!",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      branch_name: "",
      branch_code: "",
      branch_parent: "",
      full_address: "",
      pincode: "",
      state_id: "",
      city_id: "",
      district_id: "",
      display_name: "",
    },
    validationSchema: Yup.object({
      branch_name: Yup.string().required("Branch Name is required"),
      branch_code: Yup.string().required("Branch Code is required"),
      branch_parent: Yup.number(),
      full_address: Yup.string().required("Address is required"),
      pincode: Yup.number()
        .typeError("Pincode must be a number")
        .required("Pincode is required"),
      state_id: Yup.string().required("State is required"),
      city_id: Yup.string().required("City is required"),
      district_id: Yup.string(),
      display_name: Yup.string().required("Display Name is required"),
    }),
    onSubmit,
  });

  return (
    <>
      <Dialog open={true} maxWidth="md" onClose={() => isModalOpen(false)}>
        <DialogTitle>
          {branchData && Object.keys(branchData).length > 0
            ? "Update Branch"
            : "Add Branch"}
          <IconButton
            aria-label="close"
            onClick={() => isModalOpen(false)}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <MyCard width={1200}>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex flex-col">
                <Grid container spacing={2} row>
                  <Grid item size={6}>
                    <TextField
                      label="Branch Name"
                      variant="outlined"
                      fullWidth
                      name="branch_name"
                      value={formik.values.branch_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.branch_name &&
                        Boolean(formik.errors.branch_name)
                      }
                      helperText={
                        formik.touched.branch_name && formik.errors.branch_name
                      }
                    />
                  </Grid>
                  <Grid item size={6}>
                    <TextField
                      label="Display Name"
                      variant="outlined"
                      fullWidth
                      name="display_name"
                      value={formik.values.display_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.display_name &&
                        Boolean(formik.errors.display_name)
                      }
                      helperText={
                        formik.touched.display_name &&
                        formik.errors.display_name
                      }
                    />
                  </Grid>
                  <Grid item size={6}>
                    <TextField
                      label="Branch Code"
                      variant="outlined"
                      fullWidth
                      name="branch_code"
                      value={formik.values.branch_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.branch_code &&
                        Boolean(formik.errors.branch_code)
                      }
                      helperText={
                        formik.touched.branch_code && formik.errors.branch_code
                      }
                    />
                  </Grid>
                  <Grid item size={6}>
                    <TextField
                      label="Full Address"
                      variant="outlined"
                      fullWidth
                      name="full_address"
                      value={formik.values.full_address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.full_address &&
                        Boolean(formik.errors.full_address)
                      }
                      helperText={
                        formik.touched.full_address &&
                        formik.errors.full_address
                      }
                    />
                  </Grid>
                  <Grid item size={12}>
                    <TextField
                      label="Parent Branch"
                      variant="outlined"
                      fullWidth
                      name="branch_parent"
                      value={
                        formik.values.branch_parent || selectedBranchId || ""
                      }
                      onChange={handleBranchSelection}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.branch_parent &&
                        Boolean(formik.errors.branch_parent)
                      }
                      helperText={
                        formik.touched.branch_parent &&
                        formik.errors.branch_parent
                      }
                      select
                    >
                      {falttenBranchList.map((branch) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          {branch.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item size={6}>
                    <Autocomplete
                      options={statesResponse || []}
                      getOptionLabel={(option) => option.display_name}
                      label="State"
                      variant="outlined"
                      fullWidth
                      name="state_id"
                      value={
                        statesResponse?.find(
                          (item) => item.id === formik.values.state_id
                        ) || null
                      }
                      onChange={(event, value) => {
                        formik.setFieldValue("state_id", value ? value.id : "");
                        setStateId(value.id);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          variant="outlined"
                          margin="none"
                          error={
                            formik.touched.state_id &&
                            Boolean(formik.errors.state_id)
                          }
                          helperText={
                            formik.touched.state_id && formik.errors.state_id
                          }
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item size={6}>
                    <TextField
                      label="District"
                      variant="outlined"
                      fullWidth
                      name="district_id"
                      value={formik.values.district_id || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.district_id &&
                        Boolean(formik.errors.district_id)
                      }
                      helperText={
                        formik.touched.district_id && formik.errors.district_id
                      }
                      select
                    >
                      {districts.map((district) => (
                        <MenuItem key={district.id} value={district.id}>
                          {district.display_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item size={6}>
                    <Autocomplete
                      options={cityList || []}
                      getOptionLabel={(option) => option.display_name}
                      value={
                        cityList?.find(
                          (item) => item.id === formik.values.city_id
                        ) || null
                      }
                      onChange={(event, value) => {
                        formik.setFieldValue("city_id", value ? value.id : "");
                      }}
                      onBlur={formik.handleBlur}
                      name="city_id"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="City"
                          variant="outlined"
                          margin="none"
                          error={
                            formik.touched.city_id &&
                            Boolean(formik.errors.city_id)
                          }
                          helperText={
                            formik.touched.city_id && formik.errors.city_id
                          }
                          fullWidth
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item size={6}>
                    <TextField
                      label="Pincode"
                      variant="outlined"
                      fullWidth
                      name="pincode"
                      value={formik.values.pincode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.pincode && Boolean(formik.errors.pincode)
                      }
                      helperText={
                        formik.touched.pincode && formik.errors.pincode
                      }
                    />
                  </Grid>
                </Grid>

                <div className="mt-4 flex justify-end gap-x-4">
                  <div>
                    {" "}
                    <Button
                      variant="outlined"
                      onClick={() => isModalOpen(false)}
                    >
                      cancel
                    </Button>
                  </div>
                  <div>
                    <Button type="submit" variant="contained" color="primary">
                      {branchData && Object.keys(branchData).length > 0
                        ? "Update Branch"
                        : "Add Branch"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </MyCard>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddBranch;
