import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Autocomplete, Chip, TextField, Button } from "@mui/material";
import { MdModeEdit } from "react-icons/md";
import Swal from "sweetalert2";
import MyCard from "../../../components/common/MyCard";
import axiosInstance from "../../../axios/axiosInstance";
import { LowerCase, SentenceCase } from "../../../utils/stringUtils";

const WeekoffManager = () => {
  const weekoffUrl = useMemo(() => "/general-config/weekoff", []);
  const formRef = useRef(null);

  const [weekoffData, setWeekoffData] = useState([]);
  const [selectedWeekoff, setSelectedWeekoff] = useState(null);

  const weekOptions = ["1", "2", "3", "4", "5"];
  const weekdayOptions = [
    { label: "Monday", value: "MONDAY" },
    { label: "Tuesday", value: "TUESDAY" },
    { label: "Wednesday", value: "WEDNESDAY" },
    { label: "Thursday", value: "THURSDAY" },
    { label: "Friday", value: "FRIDAY" },
    { label: "Saturday", value: "SATURDAY" },
    { label: "Sunday", value: "SUNDAY" },
  ];

  // Fetch weekoff data
  const fetchWeekoff = async () => {
    try {
      const response = await axiosInstance.get(weekoffUrl);
      setWeekoffData(response.data.data || []);
    } catch (error) {
      console.error("Failed to fetch weekoff data", error);
    }
  };

  useEffect(() => {
    fetchWeekoff();
  }, []);

  const getOrdinalWeek = (week) => {
    const weekNumber = parseInt(week, 10);
    if (weekNumber === 1) return `${weekNumber}st week`;
    if (weekNumber === 2) return `${weekNumber}nd week`;
    if (weekNumber === 3) return `${weekNumber}rd week`;
    return `${weekNumber}th week`;
  };

  const formik = useFormik({
    initialValues: {
      weekoffName: "",
      days: [],
      selectedWeeks: [],
    },
    validationSchema: Yup.object({
      weekoffName: Yup.string().required("Weekoff name is required"),
      days: Yup.array().min(1, "At least one day is required"),
      selectedWeeks: Yup.array().min(1, "At least one week is required"),
    }),
    onSubmit: async (values) => {
      try {
        if (selectedWeekoff) {
          await axiosInstance.put(weekoffUrl, values);
          Swal.fire("Success", "Weekoff updated successfully!", "success");
        } else {
          await axiosInstance.post(weekoffUrl, values);
          Swal.fire("Success", "Weekoff added successfully!", "success");
        }

        formik.resetForm();

        setSelectedWeekoff(null);
        fetchWeekoff(); // Refresh data after submission
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Failed to add/update Weekoff";
        Swal.fire("Error", errorMessage, "error");
      }
    },
  });

  const handleEditClick = (weekoff) => {
    setSelectedWeekoff(weekoff);
    formik.setValues({
      id: weekoff.id,
      weekoffName: weekoff.weekoffName,
      days: weekoff.days || [],
      selectedWeeks: weekoff.selectedWeeks || [],
    });

    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleWeekSelection = (value) => {
    if (value.includes("Select All")) {
      formik.setFieldValue("selectedWeeks", weekOptions);
    } else {
      formik.setFieldValue(
        "selectedWeeks",
        value.filter((week) => week !== "Select All")
      );
    }
  };

  return (
    <>
      <form ref={formRef} onSubmit={formik.handleSubmit}>
        {/* Weekoff Name Input */}
        <div className="mb-4">
          <TextField
            label="Weekoff Name"
            name="weekoffName"
            value={formik.values.weekoffName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="outlined"
            size="small"
            fullWidth
            error={
              formik.touched.weekoffName && Boolean(formik.errors.weekoffName)
            }
            helperText={formik.touched.weekoffName && formik.errors.weekoffName}
          />
        </div>

        {/* Weekday Select */}
        <div className="mb-4">
          <Autocomplete
            multiple
            options={weekdayOptions}
            getOptionLabel={(option) => option.label}
            value={weekdayOptions.filter((day) =>
              (formik.values.days || []).includes(day.value)
            )}
            onChange={(event, newValue) => {
              const selectedValues = (newValue || []).map((day) => day.value);
              formik.setFieldValue("days", selectedValues);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.value || index}
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Weekdays"
                variant="outlined"
                size="small"
                error={formik.touched.days && Boolean(formik.errors.days)}
                helperText={formik.touched.days && formik.errors.days}
              />
            )}
          />
        </div>

        {/* Week Selection with Autocomplete */}
        <div className="mb-4">
          <Autocomplete
            multiple
            options={["Select All", ...weekOptions]}
            getOptionLabel={(option) =>
              option === "Select All" ? "All Weeks" : `Week ${option}`
            }
            value={formik.values.selectedWeeks || []}
            onChange={(event, value) => handleWeekSelection(value)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index} // Set key here
                  label={
                    option === "Select All" ? "All Weeks" : `Week ${option}`
                  }
                  {...getTagProps({ index })}
                  onDelete={() => {
                    const newValue = [...value];
                    newValue.splice(index, 1);
                    formik.setFieldValue("selectedWeeks", newValue);
                  }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Weeks"
                variant="outlined"
                size="small"
                error={
                  formik.touched.selectedWeeks &&
                  Boolean(formik.errors.selectedWeeks)
                }
                helperText={
                  formik.touched.selectedWeeks && formik.errors.selectedWeeks
                }
              />
            )}
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-end mt-4">
          <Button type="submit" variant="contained" color="primary">
            {selectedWeekoff ? "Update Weekoff" : "Add Weekoff"}
          </Button>
        </div>
      </form>

      {/* Display Selected Weekoff Details */}

      <div>
        <div className="border-b-2 pb-2 mb-4">
          <h5 className="text-lg font-semibold">Added Weekoffs</h5>
        </div>
        <div className="grid grid-cols-1 gap-4">
          {weekoffData?.length > 0 ? (
            weekoffData.map((weekoff) => (
              <MyCard
                key={weekoff.id}
                title={
                  <div className="flex items-center justify-between ">
                    <h5 className="text-lg font-semibold">
                      {weekoff.weekoffName}
                    </h5>
                    <MdModeEdit
                      className="text-xl cursor-pointer"
                      onClick={() => handleEditClick(weekoff)}
                    />
                  </div>
                }
                width="full"
              >
                <div className="flex flex-row gap-x-10">
                  <div className="flex flex-col text-sm">
                    <p>
                      {(() => {
                        console.log(
                          "Days data before formatting:",
                          weekoff.days
                        );
                      })()}
                      <strong>Week day:</strong> {SentenceCase(weekoff.days)}
                    </p>

                    <p>
                      <strong>Weeks:</strong>{" "}
                      {weekoff.selectedWeeks.map(getOrdinalWeek).join(", ")}
                    </p>
                  </div>
                </div>
              </MyCard>
            ))
          ) : (
            <p>No weekoff data available.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default WeekoffManager;
