import { Button } from "@mui/material";
import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";

const TreeViewComponent = ({
  node,
  parentbranchkey,
  childbranchkey,
  handleMenuClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div style={{ width: "100%" }}>
      {/* Branch Name Section with Border */}
      <div
        className="mb-1 py-1 tracking-wide text-md px-7"
        style={{
          borderBottom: "2px solid #ddd", // Border only for the branch name
          display: "flex",
          justifyContent: "space-between", // To align branch name and actions button
          alignItems: "center",
        }}
      >
        {/* Branch Name and Arrow Icon */}
        <div className="flex items-center gap-x-2" onClick={toggle}>
          {node[childbranchkey]?.length > 0 && (
            <span
              className={`transition-transform ${isOpen ? "rotate-90" : ""}`}
            >
              <IoIosArrowForward size={20} />
            </span>
          )}

          <span
            className={`pr-5 py-1 ${
              node[childbranchkey]?.length > 0 ? "font-bold" : ""
            }`}
          >
            {node[parentbranchkey] || "Unnamed Branch"}
          </span>
        </div>

        {/* Actions Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={(event) => handleMenuClick(event, node)} // Trigger menu for Actions
        >
          Actions
        </Button>
      </div>

      {/* Sub-branches Section */}
      {isOpen && node[childbranchkey]?.length > 0 && (
        <div className="pl-10 mt-2" style={{ width: "100%" }}>
          {node[childbranchkey].map((item) => (
            <TreeViewComponent
              key={item.id}
              node={item}
              parentbranchkey={parentbranchkey}
              childbranchkey={childbranchkey}
              handleMenuClick={handleMenuClick} // Pass handleMenuClick to sub-branches
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TreeViewComponent;
