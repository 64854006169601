import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import MyCard from "../../../components/common/MyCard";
import Swal from "sweetalert2";
import axiosInstance from "../../../axios/axiosInstance";
import { MdModeEdit } from "react-icons/md";
import { SentenceCase } from "../../../utils/stringUtils";

const ShiftManager = () => {
  const shiftUrl = useMemo(() => "/general-config/shift", []);
  const params = useMemo(() => ({}), []);
  const formRef = useRef(null);
  const [shiftData, setShiftData] = useState([]);
  const [selectedShift, setSelectedShift] = useState(null); // For editing shift

  // Function to fetch shifts
  const fetchShifts = async () => {
    try {
      const response = await axiosInstance.get(shiftUrl, params); // Fetch shift data
      setShiftData(response.data.data || []); // Update shift data in the state
    } catch (error) {
      console.error("Failed to fetch shifts:", error);
    }
  };

  useEffect(() => {
    fetchShifts(); // Fetch shifts on component mount
  }, []);

  const formik = useFormik({
    initialValues: {
      shift_name: "",
      start_time: null,
      end_time: null,
      grace_time: null,
      break_purpose: "",
      break_start: null,
      break_end: null,
      overtime_hours: "",
      overtime_pay: "",
      overtime_type: "",
    },
    validationSchema: Yup.object({
      shift_name: Yup.string().required("Shift Name is required"),
      start_time: Yup.mixed().required("Start Time is required"),
      end_time: Yup.mixed()
        .required("End Time is required")
        .test(
          "end_time-after-start_time",
          "End Time must be after Start Time",
          function (value) {
            const { start_time } = this.parent;
            return start_time && value && moment(value).isAfter(start_time);
          }
        ),
      grace_time: Yup.number().nullable(),
      break_purpose: Yup.string(),
      break_start: Yup.mixed().nullable(),
      break_end: Yup.mixed().nullable(),
    }),

    onSubmit: async (values) => {
      const formattedData = {
        ...values,
        start_time: values.start_time
          ? moment(values.start_time).format("HH:mm:ss")
          : null,
        end_time: values.end_time
          ? moment(values.end_time).format("HH:mm:ss")
          : null,
        break_start: values.break_start
          ? moment(values.break_start).format("HH:mm:ss")
          : null,
        break_end: values.break_end
          ? moment(values.break_end).format("HH:mm:ss")
          : null,
        grace_time: values.grace_time ? values.grace_time : null,
      };

      try {
        if (selectedShift) {
          // Update the shift (PUT request)
          await axiosInstance.put(shiftUrl, formattedData);
          Swal.fire("Success", "Shift updated successfully!", "success");
        } else {
          // Add a new shift (POST request)
          await axiosInstance.post(shiftUrl, formattedData);
          Swal.fire("Success", "Shift added successfully!", "success");
        }

        formik.resetForm();
        setSelectedShift(null); // Clear selected shift after submission
        fetchShifts(); // Re-fetch shifts to update the list
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Failed to add/update shift";
        Swal.fire("Error", errorMessage, "error");
      }
    },
  });

  // Set form fields when selecting a shift to edit
  const handleEditClick = (shift) => {
    setSelectedShift(shift); // Set the shift to be edited
    formik.setValues({
      id: shift.id,
      shift_name: shift.shift_name,
      start_time: shift.start_time
        ? moment(shift.start_time, "HH:mm:ss")
        : null,
      end_time: shift.end_time ? moment(shift.end_time, "HH:mm:ss") : null,
      grace_time: shift.grace_time || "",
      break_purpose: shift.break_purpose || "",
      break_start: shift.break_start
        ? moment(shift.break_start, "HH:mm:ss")
        : null,
      break_end: shift.break_end ? moment(shift.break_end, "HH:mm:ss") : null,
      overtime_hours: shift.overtime_hours || "",
      overtime_pay: shift.overtime_pay || "",
      overtime_type: shift.overtime_type || "",
    });

    // Scroll to the top of the form
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <form ref={formRef} onSubmit={formik.handleSubmit}>
      {/* Shift Name */}
      <div className="flex flex-row gap-4 mt-4">
        <div className="flex-1">
          {/* <label className="text-sm font-semibold">Shift Name</label> */}
          <TextField
            label="Shift Name"
            name="shift_name"
            value={formik.values.shift_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.shift_name && Boolean(formik.errors.shift_name)
            }
            helperText={formik.touched.shift_name && formik.errors.shift_name}
            size="small"
            fullWidth
          />
        </div>
      </div>

      {/* Start Time, End Time, Buffer Time */}
      <div className="flex flex-row gap-4 mt-4">
        <div className="flex-1">
          <label className="text-sm font-semibold">Start Time</label>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileTimePicker
              value={formik.values.start_time}
              onChange={(newValue) =>
                formik.setFieldValue("start_time", newValue)
              }
              onBlur={formik.handleBlur}
              slotProps={{
                textField: {
                  error:
                    formik.touched.start_time &&
                    Boolean(formik.errors.start_time),

                  helperText:
                    formik.touched.start_time && formik.errors.start_time,

                  fullWidth: true,
                  size: "small",
                },
              }}
            />
          </LocalizationProvider>
        </div>

        <div className="flex-1">
          <label className="text-sm font-semibold">End Time</label>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileTimePicker
              value={formik.values.end_time}
              onChange={(newValue) =>
                formik.setFieldValue("end_time", newValue)
              }
              onBlur={formik.handleBlur}
              slotProps={{
                textField: {
                  error:
                    formik.touched.end_time && Boolean(formik.errors.end_time),

                  helperText: formik.touched.end_time && formik.errors.end_time,

                  fullWidth: true,
                  size: "small",
                },
              }}
            />
          </LocalizationProvider>
        </div>

        <div className="flex-1">
          <label className="text-sm font-semibold">Buffer Time</label>
          <TextField
            label="Buffer time(in minutes)"
            name="grace_time"
            value={formik.values.grace_time}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.grace_time && Boolean(formik.errors.grace_time)
            }
            helperText={formik.touched.grace_time && formik.errors.grace_time}
            size="small"
            fullWidth
          />
        </div>
      </div>

      {/* Break Inputs */}
      <div className="mt-4">
        <div className="border-b-2 pb-2 mb-4">
          <h5 className="text-lg font-semibold">Add Break</h5>
        </div>
        <div className="flex flex-wrap gap-4">
          <div className="flex-1">
            {/* <label className="text-sm font-semibold">Break Purpose</label>
             */}
            <div className="h-6"></div>
            <TextField
              name="break_purpose"
              value={formik.values.break_purpose}
              label="Break Purpose"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.break_purpose &&
                Boolean(formik.errors.break_purpose)
              }
              helperText={
                formik.touched.break_purpose && formik.errors.break_purpose
              }
              size="small"
              fullWidth
            />
          </div>

          <div className="flex-1">
            <label className="text-sm font-semibold">Break Start Time</label>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileTimePicker
                value={formik.values.break_start}
                onChange={(newValue) =>
                  formik.setFieldValue("break_start", newValue)
                }
                onBlur={formik.handleBlur}
                slotProps={{
                  textField: {
                    error:
                      formik.touched.break_start &&
                      Boolean(formik.errors.break_start),

                    helperText:
                      formik.touched.break_start && formik.errors.break_start,

                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
          </div>

          <div className="flex-1">
            <label className="text-sm font-semibold">Break End Time</label>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileTimePicker
                value={formik.values.break_end}
                onChange={(newValue) =>
                  formik.setFieldValue("break_end", newValue)
                }
                onBlur={formik.handleBlur}
                slotProps={{
                  textField: {
                    error:
                      formik.touched.break_end &&
                      Boolean(formik.errors.break_end),

                    helperText:
                      formik.touched.break_end && formik.errors.break_end,

                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>

      {/* OT Inputs */}
      <div className="mt-4">
        <div className="border-b-2 pb-2 mb-4">
          <h5 className="text-lg font-semibold">Add Overtime</h5>
        </div>
        <div className="flex space-x-4">
          <FormControl className="flex-1">
            <InputLabel size="small">OT Hours</InputLabel>
            <Select
              name="overtime_hours"
              value={formik.values.overtime_hours}
              size="small"
              onChange={formik.handleChange}
              label="OT Hours"
            >
              <MenuItem value="">-- Not required --</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
            </Select>
          </FormControl>

          <FormControl className="flex-1">
            <InputLabel size="small">OT Pay</InputLabel>
            <Select
              name="overtime_pay"
              value={formik.values.overtime_pay}
              size="small"
              label="OT Pay"
              onChange={formik.handleChange}
            >
              <MenuItem value="">-- Not required --</MenuItem>
              <MenuItem value={1}>1x</MenuItem>
              <MenuItem value={2}>2x</MenuItem>
              <MenuItem value={3}>3x</MenuItem>
              <MenuItem value={4}>4x</MenuItem>
            </Select>
          </FormControl>

          <FormControl className="flex-1">
            <InputLabel size="small">OT Type</InputLabel>
            <Select
              name="overtime_type"
              label="OT Type"
              value={formik.values.overtime_type}
              size="small"
              onChange={formik.handleChange}
            >
              <MenuItem value="">-- Not required --</MenuItem>
              <MenuItem value="DAILY">Daily</MenuItem>
              <MenuItem value="HOURLY">Hourly</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      {/* Submit Button */}
      <div className="flex justify-end mt-4">
        <Button type="submit" variant="contained" color="primary">
          {selectedShift ? "Update Shift" : "Add Shift"}
        </Button>
      </div>

      {/* Shift Details */}

      <div className="border-b-2 pb-2 mb-4">
        <Typography variant="h5" className="font-semibold">
          Added Shifts
        </Typography>
      </div>
      {/* Display Shift Details */}
      <div className="grid grid-cols-1 gap-2">
        {shiftData && shiftData.length > 0 ? (
          shiftData.map((shift) => (
            <MyCard
              key={shift.id}
              title={
                <div className="flex items-center justify-between">
                  <Typography variant="h6" className="font-semibold">
                    {shift.shift_name}
                  </Typography>
                  <MdModeEdit
                    className="text-xl cursor-pointer"
                    onClick={() => handleEditClick(shift)}
                  />
                </div>
              }
              width="full"
            >
              <div className="flex flex-row gap-x-20">
                {/* Shift Details */}
                <div className="flex flex-col">
                  <Typography variant="subtitle1" className="font-semibold">
                    Shift Details
                  </Typography>
                  <Typography variant="body2">
                    <strong>Start Time:</strong> {shift.start_time || "Not set"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>End Time:</strong> {shift.end_time || "Not set"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Buffer Time:</strong>{" "}
                    {shift.grace_time || "Not set"}
                  </Typography>
                </div>

                {/* Break Details */}
                {shift.break_purpose && (
                  <div className="flex flex-col">
                    <Typography variant="subtitle1" className="font-semibold">
                      Break Details
                    </Typography>
                    <Typography variant="body2">
                      <strong>Break Purpose:</strong> {shift.break_purpose}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Start Time:</strong>{" "}
                      {shift.break_start || "Not set"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>End Time:</strong> {shift.break_end || "Not set"}
                    </Typography>
                  </div>
                )}

                {/* Overtime Details */}
                {shift.overtime_hours && (
                  <div className="flex flex-col">
                    <Typography variant="subtitle1" className="font-semibold">
                      Overtime Details
                    </Typography>
                    <Typography variant="body2">
                      <strong>OT Hours:</strong>{" "}
                      {shift.overtime_hours || "Not set"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>OT Pay:</strong> {shift.overtime_pay || "Not set"}
                    </Typography>
                    <Typography variant="body2">
                      <strong>OT Type:</strong>{" "}
                      {SentenceCase(shift.overtime_type || "Not set")}
                    </Typography>
                  </div>
                )}
              </div>
            </MyCard>
          ))
        ) : (
          <Typography variant="body2">No shifts available</Typography>
        )}
      </div>
    </form>
  );
};

export default ShiftManager;
