import React, { useEffect } from "react";
import MyCard from "../../components/common/MyCard";
import HorizontalLinearStepper from "../../components/Stepper";
import Welcome from "../signin/Welcome";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import axiosInstance from "../../axios/axiosInstance";
import ErrorBoundary from "../../components/common/ErrorBoundary";
import Swal from "sweetalert2";
import { getErrorMessage } from "../../utils/getErrorMessage";

const Register = () => {
  const [isStepValid, setIsStepValid] = React.useState([false, false, false]);
  const [errorMessages, setErrorMessages] = React.useState(["", "", ""]);
  // const [myStep, setMyStep] = React.useState(0);
  const [currentStepPayload, setCurrentStepPayload] = React.useState({});
  const [isMobileVerified, setIsMobileVerified] = React.useState(false);
  const [isEmailVerified, setIsEmailVerified] = React.useState(false);
  const [formData, setFormData] = React.useState([]);
  const [requestSession, setRequestSession] = React.useState("");
  const [activeStep, setActiveStep] = React.useState(0);

  useEffect(() => {
    console.log("isEmailVerified: ", isEmailVerified);
    console.log("isMobileVerified: ", isMobileVerified);
    console.log("isStepValid: ", isStepValid);
  });

  const steps = [
    {
      label: "Get Started",
      content: (
        <StepOne
          validate={(isValid, errorMessage, formData) =>
            handleValidation(0, isValid, errorMessage, formData)
          }
          initVals={formData[0]}
          setIsMobileVerified={setIsMobileVerified}
          setIsEmailVerified={setIsEmailVerified}
          isMobileVerified={isMobileVerified}
          isEmailVerified={isEmailVerified}
        />
      ),
    },
    {
      label: "Business Information",
      content: (
        <StepTwo
          validate={(isValid, errorMessage, formData) =>
            handleValidation(1, isValid, errorMessage, formData)
          }
          initVals={formData[1]}
          contactNumber={formData[0]?.company_contact_number}
          companyEmail={formData[0]?.company_email}
        />
      ),
    },
    {
      label: "Personal Information",
      content: (
        <StepThree
          validate={(isValid, errorMessage, formData) =>
            handleValidation(2, isValid, errorMessage, formData)
          }
          initVals={formData[2]}
          contactNumber={formData[0]?.company_contact_number}
          companyEmail={formData[0]?.company_email}
        />
      ),
    },
  ];

  const handleValidation = (stepIndex, isValid, errorMessage, formData) => {
    console.log("isValid", isValid, formData);
    const updatedValidity = [...isStepValid];
    const updatedErrors = [...errorMessages];

    updatedValidity[stepIndex] = isValid;
    updatedErrors[stepIndex] = errorMessage;
    setIsStepValid(updatedValidity);
    setErrorMessages(updatedErrors);
    setCurrentStepPayload(formData);
  };

  // If user inputs data in each step save the data  in formData state to preserve and reset back
  const handlePreserveData = (activeStep, currentStepPayload) => {
    setFormData((formData) => ({
      ...formData,
      [activeStep]: currentStepPayload,
    }));
  };

  // handle saving data into the database
  const handleSaveData = async (url, payload, activeStep) => {
    try {
      const response = await axiosInstance.post(url, payload);
      if (response.data.status === 200 || response.data.status === 201) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep === 0)
          setRequestSession(response.data.data.request_session);
      } else {
        alert("Something went wrong", response.data.message);
        return false;
      }
    } catch (err) {
      const errormessage = getErrorMessage(err.response.data.error);
      Swal.fire({
        title: "error",
        text: errormessage,
        icon: "error",
        confirmButtonText: "OK",
      });
      return false;
    }
  };

  const handleNext = async (activeStep) => {
    // Save the current step data into the formdata
    handlePreserveData(activeStep, currentStepPayload);
    console.log(activeStep);

    if (!isStepValid[activeStep]) {
      await Swal.fire({
        title: "error",
        text: "Validation Failed..!!",
        icon: "error",
        confirmButtonText: "OK",
      });
      return false;
    }
    switch (activeStep) {
      case 0:
        // Perform AJAX operation for Step 1
        console.log("Submitting data for Step 1");
        await handleSaveData(
          "/company/register/start",
          currentStepPayload,
          activeStep
        );

        break;
      case 1:
        // Perform AJAX operation for Step 2
        console.log("Submitting data for Step 2");
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 2:
        // Perform AJAX operation for Step 3
        const finalPayload = {
          ...formData[1],
          ...currentStepPayload,
          request_session: requestSession,
        };
        await handleSaveData(
          "/company/register/complete",
          finalPayload,
          activeStep
        );
        break;
      default:
        break;
    }
  };

  const handleBack = (activeStep) => {
    handlePreserveData(activeStep, currentStepPayload);
  };

  return (
    <>
      <div className="flex flex-row min-h-screen">
        <div className="flex w-[50%] h-auto ">
          <Welcome />
        </div>
        <div className="flex flex-1 mx-4">
          <ErrorBoundary>
            <MyCard title="Register Your Organisation" width={1400}>
              <HorizontalLinearStepper
                steps={steps}
                onNext={handleNext}
                onBack={handleBack}
                nextDisabled={
                  isStepValid[activeStep] && isMobileVerified && isEmailVerified
                }
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            </MyCard>
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
};

export default Register;
