import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const RestrictionStatus = ({ isAllowed, title }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {isAllowed ? (
        <>
          <CheckCircleIcon style={{ color: "green", marginRight: 8 }} />
          <span>{title}</span>
        </>
      ) : (
        <>
          <CancelIcon style={{ color: "red", marginRight: 8 }} />
          <span>{title}</span>
        </>
      )}
    </div>
  );
};

export default RestrictionStatus;
