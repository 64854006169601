import React, { useState, useMemo, useEffect } from "react";
import { TextField, MenuItem } from "@mui/material";
import {
  FaUsers,
  FaMale,
  FaFemale,
  FaBirthdayCake,
  FaUserPlus,
  FaUserMinus,
} from "react-icons/fa";
import { MdCelebration } from "react-icons/md";
import { MdEventBusy } from "react-icons/md";
import MyCard from "../../components/common/MyCard";
import useFetch from "../../hooks/useFetch";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import axiosInstance from "../../axios/axiosInstance";
import { getErrorMessage } from "../../utils/getErrorMessage";

const Stats = () => {
  const deptUrl = useMemo(() => "/company/departments/list", []);
  const branchUrl = useMemo(() => "/company/branch/list", []);
  const desgnUrl = useMemo(() => "/company/designation/list", []);

  const params = useMemo(() => ({}), []);

  const [flattenedBranchList, setFlattenedBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [deptId, setDeptId] = useState(null);
  const [stats, setStats] = useState([]);

  const params1 = useMemo(() => ({ department_id: deptId }), [deptId]);

  const { data: departmentsList } = useFetch(deptUrl, params);
  const { data: branchesList } = useFetch(branchUrl, params);
  const { data: designationList } = useFetch(desgnUrl, params1);
  const flattenBranches = (branches, parent = "") => {
    let flatList = [];
    branches?.forEach((branch) => {
      const label = parent
        ? `${parent} -> ${branch.branch_name}`
        : branch.branch_name;

      flatList.push({
        id: branch.id,
        display_name: branch.branch_name,
        label: label,
        parent: parent,
        sub_branches: branch.sub_branches,
      });

      if (branch.sub_branches && branch.sub_branches.length > 0) {
        flatList = flatList.concat(flattenBranches(branch.sub_branches, label));
      }
    });

    return flatList;
  };

  useEffect(() => {
    if (Array.isArray(branchesList)) {
      const flatBranchData = flattenBranches(branchesList);
      setFlattenedBranchList(flatBranchData);
    }
  }, [branchesList]);
  const fetchStats = async (branchId, departmentId, designationId) => {
    try {
      const params = {};

      if (branchId) params.company_branch_id = branchId;
      if (departmentId) params.company_department_id = departmentId;
      if (designationId) params.company_designation_id = designationId;

      const response = await axiosInstance.get("/company/stats", { params });

      if (response.data.status === 200) {
        const fetchedStats = [
          {
            icon: <FaUsers />,
            label: "Total Employees",
            value: response.data.data.total_employees,
            color: "from-blue-400 to-blue-600",
          },
          {
            icon: <FaMale />,
            label: "Male Employees",
            value: response.data.data.male_employees,
            color: "from-green-400 to-green-600",
          },
          {
            icon: <FaFemale />,
            label: "Female Employees",
            value: response.data.data.female_employees,
            color: "from-pink-400 to-pink-600",
          },
          {
            icon: <MdEventBusy />,
            label: "Absent Today",
            value: response.data.data.absent_today,
            color: "from-red-400 to-red-600",
          },
          {
            icon: <FaBirthdayCake />,
            label: "Birthday Today",
            value: response.data.data.birthday_today,
            color: "from-yellow-400 to-yellow-600",
          },
          {
            icon: <MdCelebration />,
            label: "Anniversary Today",
            value: response.data.data.anniversary_today,
            color: "from-purple-400 to-purple-600",
          },
          {
            icon: <FaUserPlus />,
            label: "Employees Joined",
            value: response.data.data.employee_join,
            color: "from-teal-400 to-teal-600",
          },
          {
            icon: <FaUserMinus />,
            label: "Employees Exit",
            value: response.data.data.employee_exit || 0,
            color: "from-gray-400 to-gray-600",
          },
        ];

        setStats(fetchedStats);
        return true; // Successful fetch
      } else {
        return false; // Failure to load stats
      }
    } catch (err) {
      const dash = getErrorMessage(err.response?.data?.error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: dash || "An error occurred while fetching the stats.",
        confirmButtonText: "Ok",
      });
      return false; // Error while fetching
    }
  };

  const handleSearch = async () => {
    // Check if any filter is missing ONLY when search button is clicked
    if (
      selectedBranch === null &&
      selectedDepartment === null &&
      selectedDesignation === null
    ) {
      Swal.fire({
        icon: "warning",
        title: "Missing Filter",
        text: "Please select at least one filter option before searching.",
        confirmButtonText: "Ok",
      });
      return; // Don't proceed if all filters are missing
    }

    const success = await fetchStats(
      selectedBranch?.id,
      selectedDepartment?.id,
      selectedDesignation?.id
    );

    if (success) {
      // Swal.fire({
      //   icon: "success",
      //   title: "Success!",
      //   text: "Stats loaded successfully!",
      //   confirmButtonText: "Ok",
      // });
      return fetchStats();
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to load stats.",
        confirmButtonText: "Ok",
      });
    }
  };

  // Initially load the stats without any filters
  useEffect(() => {
    const fetchInitialStats = async () => {
      await fetchStats(); // Load stats without filters initially
    };

    fetchInitialStats(); // Initial load without filters
  }, []);

  return (
    <div className="px-10 py-5">
      <MyCard title={"Stats"} fullWidth>
        {/* Filters */}
        <div className="bg-gray-50 shadow-lg rounded-md p-6 mb-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <TextField
              select
              label="Branch"
              value={selectedBranch?.id || ""}
              onChange={(e) =>
                setSelectedBranch(
                  flattenedBranchList.find(
                    (branch) => branch.id === e.target.value
                  )
                )
              }
              fullWidth
              variant="outlined"
            >
              {flattenedBranchList.map((branch) => (
                <MenuItem key={branch.id} value={branch.id}>
                  {branch.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              label="Department"
              value={selectedDepartment?.id || ""}
              onChange={(e) => {
                const deptId = e.target.value;
                setSelectedDepartment(
                  departmentsList.find((dept) => dept.id === deptId)
                );
                setDeptId(deptId); // Set the department ID
              }}
              fullWidth
              variant="outlined"
            >
              {departmentsList?.map((dept) => (
                <MenuItem key={dept.id} value={dept.id}>
                  {dept.display_name}
                </MenuItem>
              ))}
            </TextField>

            <div className="flex items-center space-x-4 w-full">
              <TextField
                select
                label="Designation"
                value={selectedDesignation?.id || ""}
                onChange={(e) =>
                  setSelectedDesignation(
                    designationList.find((desn) => desn.id === e.target.value)
                  )
                }
                fullWidth
                variant="outlined"
              >
                {designationList?.map((desn) => (
                  <MenuItem key={desn.id} value={desn.id}>
                    {desn.display_name}
                  </MenuItem>
                ))}
              </TextField>

              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 px-3 pt-5">
          {stats.map((stat, index) => (
            <div
              key={index}
              className={`relative group p-6 rounded-lg shadow-lg bg-gradient-to-r ${stat.color} overflow-hidden hover:scale-105 transition-transform duration-300`}
            >
              <div className="flex items-center">
                <div className="w-14 h-14 rounded-full bg-white/20 flex items-center justify-center shadow-md">
                  <span className="text-4xl text-white">{stat.icon}</span>
                </div>

                <div className="ml-4">
                  <h3 className="text-lg font-semibold text-white mb-1">
                    {stat.label}
                  </h3>
                  <p className="text-xl font-bold text-white">{stat.value}</p>
                </div>
              </div>

              <div className="absolute inset-0 bg-black/10 opacity-0 group-hover:opacity-20 transition-opacity duration-300 rounded-lg"></div>
            </div>
          ))}
        </div>
      </MyCard>
    </div>
  );
};

export default Stats;
