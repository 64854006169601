import React, { useState, useMemo } from "react";
import HolidayManager from "./HolidayManager";
import Button from "@mui/material/Button";
import HolidayGroup from "./HolidayGroup";
import useFetch from "../../hooks/useFetch";
import { Typography } from "@mui/material";
import MyCard from "../../components/common/MyCard";
import { MdModeEdit } from "react-icons/md";

const HolidayConfiguration = () => {
  const [selectedHolidayGroup, setSelectedHolidayGroup] = useState(null); // Store current holiday for actions
  const [isHolidayManagerOpen, setIsHolidayPopupOpen] = useState(false);
  const [isHolidayGroupOpen, setIsHolidayGroupOpen] = useState(false);

  const holidayGroupUrl = useMemo(() => "/company/holiday-group", []);
  const params = useMemo(() => ({}), []);
  const { data: holidayGroupData } = useFetch(holidayGroupUrl, params); // Fetch holidays data

  const handleOpenPopup = (holiday = null) => {
    setSelectedHolidayGroup(holiday); // Set holiday for editing (or null for adding)
    setIsHolidayGroupOpen(true);
  };

  const handleClosePopup = () => {
    setIsHolidayGroupOpen(false);
    setSelectedHolidayGroup(null); // Clear the holiday when closing the manager
  };

  return (
    <div className="flex flex-col w-full p-5 m-2">
      <div className="flex justify-end px-6 pt-2 gap-x-5">
        {/* Add Holiday Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsHolidayPopupOpen(true)}
        >
          Add Holiday
        </Button>

        {/* Add Holiday Group Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenPopup()}
        >
          Add Holiday Group
        </Button>
      </div>

      {/* HolidayManager Modal */}
      {isHolidayManagerOpen && (
        <HolidayManager isModalOpen={setIsHolidayPopupOpen} />
      )}

      {/* HolidayGroup Modal */}
      {isHolidayGroupOpen && (
        <HolidayGroup
          handleClosePopup={handleClosePopup} // Close modal handler
          initialData={selectedHolidayGroup} // Pass fetched holidays
        />
      )}

      <MyCard title="Holiday Group List">
        <div>
          {/* Display Holiday Details */}
          <div className="grid grid-cols-1 gap-2">
            {holidayGroupData && holidayGroupData.length > 0 ? (
              holidayGroupData.map((item) => (
                <MyCard
                  key={item.id}
                  title={
                    <div className="flex items-center justify-between">
                      <Typography variant="h6" className="font-semibold">
                        {item.display_name || "Name not provided"}
                      </Typography>
                      <MdModeEdit
                        className="text-xl cursor-pointer"
                        onClick={() => handleOpenPopup(item)}
                      />
                    </div>
                  }
                  width="full"
                >
                  {" "}
                  <div>
                    <div className="grid grid-cols-1 gap-2">
                      {item.holidays_details &&
                      item.holidays_details.length > 0 ? (
                        item.holidays_details.map((holiday) => (
                          <div key={holiday.id}>
                            <Typography
                              variant="subtitle2"
                              className="font-medium"
                            >
                              {holiday.display_name || "NA"}
                            </Typography>
                            <Typography
                              variant="body2"
                              className="text-gray-600"
                            >
                              Holiday date: {holiday.holiday_date || "NA"}
                            </Typography>
                            <Typography
                              variant="body2"
                              className="text-gray-600"
                            >
                              Holiday Description: {holiday.description || "NA"}
                            </Typography>
                          </div>
                        ))
                      ) : (
                        <Typography variant="body2">
                          No holidays available
                        </Typography>
                      )}
                    </div>
                  </div>
                </MyCard>
              ))
            ) : (
              <Typography variant="body2">
                No holiday Groups available
              </Typography>
            )}
          </div>
        </div>
      </MyCard>
    </div>
  );
};

export default HolidayConfiguration;
