import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function HorizontalLinearStepper({
  steps,
  onNext,
  onBack,
  nextDisabled,
  activeStep,
  setActiveStep,
}) {
  // const [activeStep, setActiveStep] = React.useState(0);
  // const [skipped, setSkipped] = React.useState(new Set());
  const navigate = useNavigate();

  // const isStepOptional = (step) => step === 1;

  // const isStepSkipped = (step) => skipped.has(step);

  React.useEffect(() => {
    console.log("nextDisabled", nextDisabled);
  });

  const handleNext = async () => {
    if (onNext) {
      await onNext(activeStep);

      // let newSkipped = skipped;
      // if (isStepSkipped(activeStep)) {
      //   newSkipped = new Set(newSkipped.values());
      //   newSkipped.delete(activeStep);
      // }

      // setSkipped(newSkipped);
    }
  };

  const handleBack = async () => {
    if (onBack) {
      await onBack(activeStep);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  //   const handleReset = () => {
  //     setActiveStep(0);
  //   };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          //   if (isStepOptional(index)) {
          //     labelProps.optional = (
          //       <Typography variant="caption">Optional</Typography>
          //     );
          //   }
          //   if (isStepSkipped(index)) {
          //     stepProps.completed = false;
          //   }
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        Swal.fire({
          title: "Thank you for registering with Klockmate",
          text: "Your request has been sent to Klockmate admin to verify, Please wait for the approval.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => navigate("/"))
      ) : (
        // <React.Fragment>
        //   <div sx={{ mt: 10, mb: 1 }}>
        //     <Typography
        //       sx={{ mt: 10, mb: 1, fontWeight: "bold" }}
        //       color="Green"
        //       fontSize={25}
        //     >
        //       Thank you for registering with Klockmate.
        //     </Typography>
        //     <Typography sx={{ mt: 1, mb: 1 }} color="Green">
        //       Your request has been sent to Klockmate admin to verify, Please
        //       wait for the approval.
        //     </Typography>
        //   </div>

        //   <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        //     <Box sx={{ flex: "1 1 auto" }} />
        //     <Button onClick={() => navigate("/")}>Go to website</Button>
        //   </Box>
        // </React.Fragment>
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          <Box sx={{ display: "flex", flexDirection: "column", pt: 2 }}>
            {steps[activeStep].content} {/* Render step content */}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0 || activeStep === 1}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={!nextDisabled}
            >
              {activeStep === steps.length - 1
                ? "Start 14 Days Free Trial"
                : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
