import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  List,
  ListItem,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LineChart,
  Line,
  RadialBarChart,
  RadialBar,
} from "recharts";
import MyCard from "../../components/common/MyCard";

const Dashboard = () => {
  // Data
  const [todos, setTodos] = useState([
    { task: "Complete reports", completed: false },
    { task: "Team meeting at 3 PM", completed: false },
    { task: "Client feedback review", completed: false },
    { task: "Employee appraisals", completed: false },
  ]);

  const branchData = [
    { month: "Jan", BranchA: 90, BranchB: 70, BranchC: 20, BranchD: 10 },
    { month: "Feb", BranchA: 50, BranchB: 20, BranchC: 30, BranchD: 15 },
    { month: "Mar", BranchA: 80, BranchB: 25, BranchC: 25, BranchD: 10 },
    { month: "Apr", BranchA: 57, BranchB: 35, BranchC: 30, BranchD: 20 },
    { month: "May", BranchA: 90, BranchB: 70, BranchC: 20, BranchD: 10 },
    { month: "Jun", BranchA: 50, BranchB: 20, BranchC: 30, BranchD: 15 },
    { month: "Jul", BranchA: 80, BranchB: 25, BranchC: 25, BranchD: 10 },
    { month: "Aug", BranchA: 57, BranchB: 35, BranchC: 30, BranchD: 20 },
    { month: "Sep", BranchA: 50, BranchB: 68, BranchC: 30, BranchD: 15 },
    { month: "Oct", BranchA: 80, BranchB: 25, BranchC: 43, BranchD: 44 },
    { month: "Nov", BranchA: 57, BranchB: 35, BranchC: 30, BranchD: 20 },
    { month: "Dec", BranchA: 57, BranchB: 35, BranchC: 30, BranchD: 20 },
  ];

  const performanceData = [
    { month: "Jan", performance: 85 },
    { month: "Feb", performance: 92 },
    { month: "Mar", performance: 88 },
    { month: "Apr", performance: 75 },
    { month: "May", performance: 80 },
  ];

  const stackedBarData = [
    { month: "Jan", training: 10, attendance: 85, leave: 5 },
    { month: "Feb", training: 15, attendance: 80, leave: 5 },
    { month: "Mar", training: 12, attendance: 90, leave: 3 },
    { month: "Apr", training: 20, attendance: 75, leave: 5 },
  ];

  const satisfactionData = [
    { name: "Satisfaction", value: 75 },
    { name: "Engagement", value: 80 },
    { name: "Retention", value: 85 },
  ];

  const colors = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"];

  // To-Do Toggle
  const handleTodoToggle = (index) => {
    const updatedTodos = [...todos];
    updatedTodos[index].completed = !updatedTodos[index].completed;
    setTodos(updatedTodos);
  };

  return (
    <Box padding={3}>
      <Grid container spacing={3}>
        {/* Branch List Stacked Area Chart */}
        <Grid size={12}>
          <MyCard title="Branch Distribution">
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={branchData}>
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="BranchA"
                  stackId="1"
                  stroke="#FF6384"
                  fill="#FF6384"
                />
                <Area
                  type="monotone"
                  dataKey="BranchB"
                  stackId="1"
                  stroke="#36A2EB"
                  fill="#36A2EB"
                />
                <Area
                  type="monotone"
                  dataKey="BranchC"
                  stackId="1"
                  stroke="#FFCE56"
                  fill="#FFCE56"
                />
                <Area
                  type="monotone"
                  dataKey="BranchD"
                  stackId="1"
                  stroke="#4BC0C0"
                  fill="#4BC0C0"
                />
              </AreaChart>
            </ResponsiveContainer>
          </MyCard>
        </Grid>

        {/* Employee Performance Simple Bar Chart */}
        <Grid size={6}>
          <MyCard title="Employee Performance (Monthly)">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={performanceData}>
                <Tooltip />
                <Bar dataKey="performance" fill="#36A2EB" />
              </BarChart>
            </ResponsiveContainer>
          </MyCard>
        </Grid>

        {/* Stacked Bar Chart for HR Fields */}
        <Grid size={6}>
          <MyCard title="HR Metrics (Monthly)">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={stackedBarData}>
                <Tooltip />
                <Legend />
                <Bar dataKey="training" stackId="1" fill="#FF6384" />
                <Bar dataKey="attendance" stackId="1" fill="#36A2EB" />
                <Bar dataKey="leave" stackId="1" fill="#FFCE56" />
              </BarChart>
            </ResponsiveContainer>
          </MyCard>
        </Grid>

        {/* <div className="flex flex-row items-center ml-4">
        <StatCard title="Head Count" subTitle="100" />
        <StatCard title="Female Count" subTitle="50" />
        <StatCard title="Male Count" subTitle="50" />
        <StatCard title="Full Time" subTitle="87" />
        <StatCard title="Part Time" subTitle="13" />
      </div> */}

        <Grid item size={6}>
          <MyCard title="Employee Satisfaction">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Tooltip />
                <Legend />
                <Pie
                  data={satisfactionData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                >
                  {satisfactionData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </MyCard>
        </Grid>
        {/* To-Do List */}
        <Grid item size={6}>
          <MyCard title="To-Do List">
            <List>
              {todos.map((item, index) => (
                <ListItem key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.completed}
                        onChange={() => handleTodoToggle(index)}
                      />
                    }
                    label={
                      <span
                        style={{
                          textDecoration: item.completed
                            ? "line-through"
                            : "none",
                        }}
                      >
                        {item.task}
                      </span>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </MyCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
