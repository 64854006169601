export const SentenceCase = (input) => {
  if (Array.isArray(input)) {
    return input
      .filter((item) => typeof item === "string") // Ensure each item is a string
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
      .join(", ");
  }

  return typeof input === "string" && input.length > 0
    ? input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
    : "";
};

export const UpperCase = (input) => {
  if (Array.isArray(input)) {
    return input
      .filter((item) => typeof item === "string") // Ensure each item is a string
      .map((item) => item.toUpperCase())
      .join(", ");
  }
  return typeof input === "string" ? input.toUpperCase() : "";
};

export const LowerCase = (input) => {
  if (Array.isArray(input)) {
    return input
      .filter((item) => typeof item === "string") // Ensure each item is a string
      .map((item) => item.toLowerCase())
      .join(", ");
  }
  return typeof input === "string" ? input.toLowerCase() : "";
};
