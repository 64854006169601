import React, { Children } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledCard = styled(Card)(({ theme, width }) => ({
  boxShadow: theme.shadows[5], // Add shadow
  borderRadius: "8px", // Optional: customize border radius
  // margin: "10px", // Optional: margin around the card
  marginTop: "15px",
  marginBottom: "15px",
  width: width || "100%",
}));

const MyCard = ({ title, children, actions = "" }) => {
  return (
    <StyledCard>
      {title && (
        <>
          <CardHeader
            title={title}
            titleTypographyProps={{
              variant: "h6",
            }} // Customize header typography
            action={actions}
          />
          <Divider style={{ marginBottom: "3px" }} />
        </>
      )}
      <CardContent>{children}</CardContent>
    </StyledCard>
  );
};

export default MyCard;
