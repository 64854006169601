import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Swal from "sweetalert2";
import axiosInstance from "../../axios/axiosInstance";
import { getErrorMessage } from "../../utils/getErrorMessage";

const AddAgency = ({ isModalOpen, refreshAgencyList }) => {
  const [agency, setAgency] = useState(null);

  const handleAddAgency = async () => {
    try {
      const response = await axiosInstance.post(
        `/employee/employee-agency/add`,
        {
          display_name: agency,
        }
      );

      if (response.data.status === 200 || response.data.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Agency Added successfully!",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        }).then(() => isModalOpen(false));
        refreshAgencyList();
      }
    } catch (err) {
      console.log("Error: ", err);
      const dash = getErrorMessage(err.response?.data?.error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: dash,
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  };

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth={true}
      onClose={() => isModalOpen(false)}
    >
      <DialogTitle>Add Agency</DialogTitle>
      <div>
        <DialogContent>
          <div className="flex flex-col gap-4">
            <TextField
              label="Enter Agency Name"
              variant="outlined"
              fullWidth
              value={agency}
              onChange={(e) => setAgency(e.target.value)}
              type="text"
            />
            <div className="flex justify-end gap-4">
              <Button variant="outlined" onClick={() => isModalOpen(false)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddAgency}
              >
                Add Agency
              </Button>
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default AddAgency;
