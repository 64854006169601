import React from "react";
// import MyCard from "../../components/common/MyCard";
import { styled } from "@mui/material/styles";
import {
  TextField,
  MenuItem,
  Button,
  IconButton,
  Box,
  Stack,
} from "@mui/material";
import { Formik, Field, Form } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
// import Typography from "@mui/material/Typography";
// import MyCard from "../../../../components/common/MyCard";
import Grid from "@mui/material/Grid2";
import Fieldset from "../../../../components/common/Fieldset";
import InfoIcon from "@mui/icons-material/Info";
import Applicability from "./Applicability";
import Restrictions from "./Restrictions";
import Exceptions from "./Exceptions";
import Swal from "sweetalert2";
import {
  PERIOD_CHOICES,
  MONTH_CHOICES,
  TYPE_CHOICES,
  UNIT_CHOICES,
  CFE_UNIT_CHOICES,
  EFFECTIVE_PERIOD,
} from "../../../../utils/constants/dropdown";
import axiosInstance from "../../../../axios/axiosInstance";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import * as Yup from "yup";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const getOrdinalSuffix = (num) => {
  if (num % 10 === 1 && num % 100 !== 11) return `${num}st`;
  if (num % 10 === 2 && num % 100 !== 12) return `${num}nd`;
  if (num % 10 === 3 && num % 100 !== 13) return `${num}rd`;
  return `${num}th`;
};

const initialValues = {
  leave_name: "",
  leave_code: "",
  leave_type: "",
  unit: "",
  entitlement_effective_after: 0,
  entitlement_period: "",
  accrual_credit_days: 0,
  accrual_on_period: "",
  accrual_on_day: 1,
  accrual_of_month: "",
  reset_period: "",
  reset_on_day: 1,
  reset_of_month: "",
  carry_forward_days: 0,
  carry_forward_unit: "",
  encashment_days: 0,
  encashment_unit: "",
  leave_applicability: {
    applicable_gender: [],
    marital_status: [],
    applicable_employee_category: [],
  },
  leave_exception: {
    exception_gender: [],
    exception_marital_status: [],
    exception_employee_category: [],
  },
  leave_restriction: {
    allow_exceeding_entitlement: false,
    consider_weekends_holidays: false,
    clubbing_not_allowed: false,
    allow_past_dates: false,
    allow_past_date_day: 0,
    allow_future_dates: false,
    allow_future_date_days: 0,
    min_leaves_allowed_per_request: 0,
    max_leaves_allowed_per_request: 0,
    min_gap_between_requests: 0,
    total_approvals_required: 0,
  },
};

const AddLeave = ({ setShowModal, showModal, editVals = {} }) => {
  const initVals = Object.keys(editVals).length ? editVals : initialValues;
  console.log("initVals: ", initVals);
  const validationSchema = Yup.object().shape({
    leave_name: Yup.string().required("Leave Name is required"),
    leave_code: Yup.string().required("Leave Code is required"),
    leave_type: Yup.string().required("Leave Type is required"),
    unit: Yup.string().required("Unit is required"),
    // entitlement_effective_after: Yup.number()
    //   .min(0, "Must be greater than or equal to 0")
    //   .required("Effective After is required"),
    // accrual_credit_days: Yup.number()
    //   .min(0, 'Must be greater than or equal to 0')
    //   .required('Accrual Credit Days is required'),
    // Add more validations as needed for other fields
  });

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSave = async (formData) => {
    // save data to database
    try {
      let response = "";
      if ("id" in formData && formData.id) {
        response = await axiosInstance.put(
          "/leave/leave-configuration-update",
          formData
        );
      } else {
        response = await axiosInstance.post(
          "/leave/leave-configuration-add",
          formData
        );
      }

      if (response?.data?.status === 200 || response?.data?.status === 201) {
        Swal.fire({
          title: "success",
          text: response?.data?.message,
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      } else {
        Swal.fire({
          title: "error",
          text: "Something went wrong",
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }
    } catch (err) {
      console.log("Error: ", err);
      const dash = getErrorMessage(err.response.data.error);
      Swal.fire({
        title: "error",
        text: dash,
        icon: "error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
      });
    }
  };

  const handleSubmit = (values) => {
    console.log(values); // Send the values as your request payload
    handleSave(values);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showModal}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add / Edit Leave
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Formik
            initialValues={initVals}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, setFieldValue, errors, touched }) => (
              <Form>
                <Box
                  component="section"
                  sx={{ p: 2, border: "1px solid grey" }}
                >
                  <Grid container spacing={2} row>
                    <Grid item size={3}>
                      <Field
                        as={TextField}
                        name="leave_name"
                        label="Leave Name"
                        fullWidth
                        error={touched.leave_name && Boolean(errors.leave_name)}
                        helperText={touched.leave_name && errors.leave_name}
                      />
                    </Grid>
                    <Grid item size={3}>
                      <Field
                        as={TextField}
                        name="leave_code"
                        label="Leave Code"
                        fullWidth
                        error={touched.leave_code && Boolean(errors.leave_code)}
                        helperText={touched.leave_code && errors.leave_code}
                      />
                    </Grid>
                    <Grid item size={3}>
                      <Field
                        as={TextField}
                        name="leave_type"
                        label="Leave Type"
                        placeholder="Leave Type"
                        fullWidth
                        select
                        error={touched.leave_type && Boolean(errors.leave_type)}
                        helperText={touched.leave_type && errors.leave_type}
                      >
                        {TYPE_CHOICES.map((item, idx) => (
                          <MenuItem key={idx} value={item.id}>
                            {item.display_name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item size={3}>
                      <Field
                        as={TextField}
                        select
                        name="unit"
                        label="Unit"
                        fullWidth
                        error={touched.unit && Boolean(errors.unit)}
                        helperText={touched.unit && errors.unit}
                      >
                        {UNIT_CHOICES.map((item, idx) => (
                          <MenuItem key={idx} value={item.id}>
                            {item.display_name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  </Grid>
                </Box>

                <Fieldset
                  title={
                    <Stack direction="row" alignItems="center" gap={1}>
                      <InfoIcon />
                      Entitlement
                    </Stack>
                  }
                  borderWidth={2}
                  borderRadius={1}
                  sx={{
                    padding: 2,
                    "& legend": {
                      borderRadius: "4px",
                    },
                  }}
                >
                  <Grid container spacing={2} row>
                    <label>Effective After : </label>
                    <Grid item size={2}>
                      <Field
                        as={TextField}
                        name="entitlement_effective_after"
                        type="number"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item size={2}>
                      <Field
                        name="entitlement_period"
                        as={TextField}
                        size="small"
                        select
                        fullWidth
                      >
                        <MenuItem value="">-- Not required --</MenuItem>
                        {EFFECTIVE_PERIOD.map((item, idx) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.display_name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item size={4}>
                      <label>From Date of Joining</label>
                    </Grid>
                  </Grid>
                </Fieldset>

                <Fieldset
                  title={
                    <Stack direction="row" alignItems="center" gap={1}>
                      <InfoIcon />
                      Accrual
                    </Stack>
                  }
                  borderWidth={2}
                  borderRadius={1}
                  sx={{
                    padding: 2,
                    "& legend": {
                      borderRadius: "4px",
                    },
                  }}
                >
                  <Grid container spacing={2} row>
                    <label>Credit </label>
                    <Grid item size={2}>
                      <Field
                        as={TextField}
                        name="accrual_credit_days"
                        type="number"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <label>Days </label>
                    <Grid item size={2}>
                      <Field
                        name="accrual_on_period"
                        as={TextField}
                        size="small"
                        select
                        fullWidth
                      >
                        <MenuItem value="">-- Not required --</MenuItem>
                        {PERIOD_CHOICES.map((item, idx) => (
                          <MenuItem key={idx} value={item.id}>
                            {item.display_name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <label>On </label>
                    <Grid item size={2}>
                      <Field
                        as={TextField}
                        name="accrual_on_day"
                        type="number"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <label>st Of </label>
                    <Grid item size={3}>
                      <Field
                        name="accrual_of_month"
                        as={TextField}
                        size="small"
                        select
                        fullWidth
                      >
                        <MenuItem value="">-- Not required --</MenuItem>
                        {MONTH_CHOICES.map((item, idx) => (
                          <MenuItem key={idx} value={item.id}>
                            {item.display_name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  </Grid>
                </Fieldset>

                <Fieldset
                  title={
                    <Stack direction="row" alignItems="center" gap={1}>
                      <InfoIcon />
                      Reset Leaves
                    </Stack>
                  }
                  borderWidth={2}
                  borderRadius={1}
                  sx={{
                    padding: 2,
                    "& legend": {
                      borderRadius: "4px",
                    },
                  }}
                >
                  <Grid container spacing={2} row>
                    <label>Reset </label>
                    <Grid item size={2}>
                      <Field
                        name="reset_period"
                        as={TextField}
                        size="small"
                        select
                        fullWidth
                      >
                        <MenuItem value="">-- Not required --</MenuItem>
                        {PERIOD_CHOICES.map((item, idx) => (
                          <MenuItem key={idx} value={item.id}>
                            {item.display_name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <label>On </label>
                    <Grid item size={2}>
                      <Field
                        as={TextField}
                        name="reset_on_day"
                        type="number"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <label>st Of </label>
                    <Grid item size={3}>
                      <Field
                        name="reset_of_month"
                        as={TextField}
                        size="small"
                        select
                        fullWidth
                      >
                        <MenuItem value="">-- Not required --</MenuItem>
                        {MONTH_CHOICES.map((item, idx) => (
                          <MenuItem key={idx} value={item.id}>
                            {item.display_name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  </Grid>
                </Fieldset>

                <Fieldset
                  title={
                    <Stack direction="row" alignItems="center" gap={1}>
                      <InfoIcon />
                      Carry Forward
                    </Stack>
                  }
                  borderWidth={2}
                  borderRadius={1}
                  sx={{
                    padding: 2,
                    "& legend": {
                      borderRadius: "4px",
                    },
                  }}
                >
                  <Grid container spacing={2} row>
                    <label>Carry forward unused leaves </label>

                    <Grid item size={2}>
                      <Field
                        as={TextField}
                        name="carry_forward_days"
                        type="number"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item size={3}>
                      <Field
                        name="carry_forward_unit"
                        as={TextField}
                        size="small"
                        select
                        fullWidth
                      >
                        <MenuItem value="">-- Not required --</MenuItem>
                        {CFE_UNIT_CHOICES.map((item, idx) => (
                          <MenuItem key={idx} value={item.id}>
                            {item.display_name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  </Grid>
                </Fieldset>

                <Fieldset
                  title={
                    <Stack direction="row" alignItems="center" gap={1}>
                      <InfoIcon />
                      Leave Encash
                    </Stack>
                  }
                  borderWidth={2}
                  borderRadius={1}
                  sx={{
                    padding: 2,
                    "& legend": {
                      borderRadius: "4px",
                    },
                  }}
                >
                  <Grid container spacing={2} row>
                    <label>Encash unused leaves </label>

                    <Grid item size={2}>
                      <Field
                        as={TextField}
                        name="encashment_days"
                        type="number"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item size={3}>
                      <Field
                        name="encashment_unit"
                        as={TextField}
                        size="small"
                        select
                        fullWidth
                      >
                        <MenuItem value="">-- Not required --</MenuItem>
                        {CFE_UNIT_CHOICES.map((item, idx) => (
                          <MenuItem key={idx} value={item.id}>
                            {item.display_name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  </Grid>
                </Fieldset>

                {/* Leave Restrictions */}
                <Restrictions setFieldValue={setFieldValue} values={values} />

                <Grid container spacing={2} row>
                  <Grid item size={6}>
                    {/* Leave Applicability */}
                    <Applicability
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  </Grid>
                  <Grid item size={6}>
                    {/* Leave Exceptions */}
                    <Exceptions setFieldValue={setFieldValue} values={values} />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={2}
                >
                  {/* Submit Button */}
                  <Grid item sizes={12}>
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default AddLeave;
