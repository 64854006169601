import React, { useMemo, useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import useFetch from "../../hooks/useFetch";
import axiosInstance from "../../axios/axiosInstance";
import MyCard from "../../components/common/MyCard";
import Swal from "sweetalert2";
import { getErrorMessage } from "../../utils/getErrorMessage";

const HolidayGroup = ({ handleClosePopup, initialData }) => {
  const holidayGroupUrl = useMemo(() => "/company/holiday-group", []);
  const holidayUrl = useMemo(() => "/company/holiday-group-day", []);

  const params = useMemo(() => ({}), []);

  const { data: holidayData } = useFetch(holidayUrl, params);

  const holidayOptions = (holidayData || []).map((holiday) => ({
    id: holiday.id,
    display_name: holiday.display_name,
  }));

  const formik = useFormik({
    initialValues: {
      id: initialData?.id || null,
      display_name: initialData?.display_name || "",
      holidays:
        initialData?.holidays_details.map((holiday) => holiday.id) || [],
    },
    validationSchema: Yup.object({
      display_name: Yup.string().required("Holiday title is required"),
      holidays: Yup.array().min(1, "At least one holiday is required"),
    }),

    onSubmit: async (values) => {
      try {
        let response;
        if (initialData) {
          response = await axiosInstance.put("/company/holiday-group", values);
          if (response.data.status === 200 || response.data.status === 201) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Holiday Group updated successfully!",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            });
          }
        } else {
          response = await axiosInstance.post("/company/holiday-group", values);
          if (response.data.status === 200 || response.data.status === 201) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Holiday Group  added successfully!",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
            });
          }
        }

        formik.resetForm();
        handleClosePopup(); // Close the popup after success
      } catch (err) {
        const dash = getErrorMessage(err.response.data.error);
        Swal.fire({
          icon: "error",
          text: dash,
          title: "Error!",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
        });
      }
    },
  });
  return (
    <Dialog open={true} maxWidth="sm" fullWidth onClose={handleClosePopup}>
      <DialogTitle>
        {initialData ? "Update Holiday Group" : "Add Holiday Group"}
        <IconButton
          aria-label="close"
          onClick={handleClosePopup}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <TextField
              label="Holiday Group Title"
              name="display_name"
              value={formik.values.display_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              variant="outlined"
              size="small"
              error={
                formik.touched.display_name &&
                Boolean(formik.errors.display_name)
              }
              helperText={
                formik.touched.display_name && formik.errors.display_name
              }
            />
          </div>

          <div className="mb-4">
            <Autocomplete
              multiple
              options={holidayOptions}
              getOptionLabel={(option) => {
                // Check if option is an object or ID and find the shift name
                if (typeof option === "object") {
                  return option.display_name; // Option is a shift object
                } else {
                  const holiday = holidayOptions.find((s) => s.id === option);
                  return holiday ? holiday.display_name : ""; // If option is an ID
                }
              }}
              value={
                // Map Formik's array of IDs to shift objects
                holidayOptions.filter((holiday) =>
                  formik.values.holidays.includes(holiday.id)
                )
              }
              onChange={(event, newValue) => {
                // Extract and store only the IDs in Formik state
                const selectedIds = newValue.map((holiday) => holiday.id);
                formik.setFieldValue("holidays", selectedIds);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.id}
                    label={option.display_name} // Ensure shift name is displayed on the chip
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Add Holidays"
                  variant="outlined"
                  size="small"
                  error={formik.touched.holidays && !!formik.errors.holidays}
                  helperText={formik.touched.holidays && formik.errors.holidays}
                />
              )}
            />
          </div>
          {/* Submit and Cancel Buttons */}
          <div className="flex justify-end gap-4 mt-4">
            <Button
              onClick={handleClosePopup}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {initialData ? "Update Holiday Group" : "Add Holiday Group"}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default HolidayGroup;
