import React, { useState, useMemo } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import { MdAdd } from "react-icons/md";
import CloseIcon from "@mui/icons-material/Close";
import GroupView from "./GroupView";
import GroupForm from "./GroupForm";
import ShiftManager from "./ShiftManager";
import WeekoffManager from "./WeekoffManager";
import HolidayManager from "../../holiday/HolidayManager";
import useFetch from "../../../hooks/useFetch";

const AttnConfi = () => {
  const groupUrl = useMemo(() => "/general-config/group", []);
  const params = useMemo(() => ({}), []);

  const { data: groupData } = useFetch(groupUrl, params);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isShiftPopupOpen, setIsShiftPopupOpen] = useState(false);
  const [isWeekoffPopupOpen, setIsWeekoffPopupOpen] = useState(false);
  const [isHolidayPopupOpen, setIsHolidayPopupOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const handleOpenShiftPopup = () => {
    setIsShiftPopupOpen(true);
  };

  const handleCloseShiftPopup = () => {
    setIsShiftPopupOpen(false);
  };
  const handleOpenWeekoffPopup = () => {
    setIsWeekoffPopupOpen(true);
  };

  const handleCloseWeekoffPopup = () => {
    setIsWeekoffPopupOpen(false);
  };
  const handleOpenHolidayPopup = () => {
    setIsHolidayPopupOpen(true);
  };

  const handleCloseHolidayPopup = () => {
    setIsHolidayPopupOpen(false);
  };
  const handleOpenPopup = (group = null) => {
    setSelectedGroup(group); // Set group data if editing
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setSelectedGroup(null); // Reset data after closing
    setIsPopupOpen(false);
  };

  return (
    <div className="space-y-2 p-4">
      <div className="flex justify-end mr-5 gap-x-2">
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenShiftPopup}
        >
          Add Shift
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenWeekoffPopup}
        >
          Add Weekoff
        </Button>
        {/* <Button
          variant="contained"
          color="primary"
          onClick={handleOpenHolidayPopup}
        >
          Add Holiday
        </Button> */}
        <Button
          variant="contained"
          color="primary"
          // startIcon={<MdAdd />}
          onClick={() => handleOpenPopup()}
        >
          Add Group
        </Button>
      </div>

      <div className="flex flex-col space-y-4">
        <GroupView groupData={groupData} onEditGroup={handleOpenPopup} />
      </div>

      {/* Shift Manager Dialog */}
      <Dialog
        open={isShiftPopupOpen}
        onClose={handleCloseShiftPopup}
        aria-labelledby="shift-modal-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Add Shift
          <IconButton
            aria-label="close"
            onClick={handleCloseShiftPopup}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <ShiftManager />
        </DialogContent>
      </Dialog>

      {/* Weekoff Manager Dialog */}
      <Dialog
        open={isWeekoffPopupOpen}
        onClose={handleCloseWeekoffPopup}
        aria-labelledby="weekoff-modal-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="weekoff-dialog-title">
          Add Weekoff
          <IconButton
            aria-label="close"
            onClick={handleCloseWeekoffPopup}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <WeekoffManager />
        </DialogContent>
      </Dialog>

      {/* Group Form Dialog */}
      <Dialog
        open={isPopupOpen}
        onClose={handleClosePopup}
        aria-labelledby="modal-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {"Group Configuration"}
          <IconButton
            aria-label="close"
            onClick={handleClosePopup}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <GroupForm
            handleClosePopup={handleClosePopup}
            initialData={selectedGroup}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AttnConfi;
